import { useGlobals } from "context/globals-context";

const LisOfInsuranceRequests = () => {

    const { lineOfInsuranceEndPoints, jwt, fetchData } = useGlobals();

    const getLineOfInsuranceList = async () => {

        try {
            const data = await fetchData(lineOfInsuranceEndPoints.getLineOfInsuranceList, {
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                },
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }
    
    return {
        getLineOfInsuranceList,
    }
}

export default LisOfInsuranceRequests;