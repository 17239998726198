const NewUserPage = () => {
    return (
        <div className="bg-body border-top border-left border-right border-bottom p-3 m-5 p-5">
            <h1>Welcome</h1>
            <h2>Create your profile</h2>
            <div className="row my-3">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Address</label>
                    <input type="number" className="form-control no-spin-button"/>
                </div>
                <div className="col-lg-2 mb-3">
                    <label className="form-label">Postal Code</label>
                    <input type="text" className="form-control"/>
                </div>
                <div className="col-lg-2 mb-3">
                    <label className="form-label">Phone Number</label>
                    <input type="number" className="form-control"/>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-lg-2 mb-3">
                    <label className="form-label">Password</label>
                    <input type="password" className="form-control no-spin-button"/>
                </div>
                <div className="col-lg-2 mb-3">
                    <label className="form-label">Retype password</label>
                    <input type="password" className="form-control"/>
                </div>
            </div>

            <div className="row my-3">
                <div className="col-lg-2 mb-3 f-align-end">                        
                    <button className="btn btn-warning">Create profile</button>
                </div>
            </div>
        </div>
    )
};

export default NewUserPage;