import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter} from 'react-router-dom'
import App from './App';

import './global.scss';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

const root = document.getElementById('root')
const ReactRoot = ReactDOM.createRoot(root);

ReactRoot.render(
  <HashRouter>
    <App />
  </HashRouter>
);
