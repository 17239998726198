import React from "react";

const Addresses = ({addressesList, caseAddressTypeList, SetAddressesList}) => {

    const STATES = {
        '':'Select a state',
        'AL':'Alabama',
        'AK':'Alaska',
        'AZ':'Arizona',
        'AR':'Arkansas',
        'CA':'California',
        'CO':'Colorado',
        'CT':'Connecticut',
        'DE':'Delaware',
        'DC':'District of Columbia',
        'FL':'Florida',
        'GA':'Georgia',
        'HI':'Hawaii',
        'ID':'Idaho',
        'IL':'Illinois',
        'IN':'Indiana',
        'IA':'Iowa',
        'KS':'Kansas',
        'KY':'Kentucky',
        'LA':'Louisiana',
        'ME':'Maine',
        'MD':'Maryland',
        'MA':'Massachusetts',
        'MI':'Michigan',
        'MN':'Minnesota',
        'MS':'Mississippi',
        'MO':'Missouri',
        'MT':'Montana',
        'NE':'Nebraska',
        'NV':'Nevada',
        'NH':'New Hampshire',
        'NJ':'New Jersey',
        'NM':'New Mexico',
        'NY':'New York',
        'NC':'North Carolina',
        'ND':'North Dakota',
        'OH':'Ohio',
        'OK':'Oklahoma',
        'OR':'Oregon',
        'PA':'Pennsylvania',
        'RI':'Rhode Island',
        'SC':'South Carolina',
        'SD':'South Dakota',
        'TN':'Tennessee',
        'TX':'Texas',
        'UT':'Utah',
        'VT':'Vermont',
        'VA':'Virginia',
        'WA':'Washington',
        'WV':'West Virginia',
        'WI':'Wisconsin',
        'WY':'Wyoming',
    };
    
    const keys = Object.keys(STATES);

    const stateOptionsJSX = keys.map((key, index) => {
        return (
            <option key={index} value={key}>{STATES[key]}</option>
        )
    });
    
    const removeAddressBlock = (index) => {
        SetAddressesList(current => {
            current.splice(index, 1);
            return [...current];
        });
    }

    const updateAddressesValues = (e, index) => {
        const type  = e.currentTarget.dataset.type;
        const value = type === 'switch' ? e.currentTarget.checked : e.currentTarget.value;
        const identifier = e.currentTarget.dataset.identifier;
        
        SetAddressesList(current => {
            current[index][identifier] = value;
            return [...current];
        });
    };

    const addAnotherAddressField = () => {
        SetAddressesList(current => [...current, {
            "addressName": "",
            "addressTypeID": 1,
            "address1": "",
            "address2": "",
            "city": "",
            "stateID": "",
            "postalCode": "",
            "note": ""
        }])
    }

    return (
        <>
            {
                addressesList.map((addressData, index) => (
                    <React.Fragment key={index}>
                        <div className="row">
                            <div className="col-lg-4 mb-3">
                                <label className="form-label">Address name</label>
                                <input type="text" className="form-control" onChange={e => updateAddressesValues(e, index)} data-identifier="addressName"/>
                            </div>
                            <div className="col-lg-2 mb-3">
                                <label className="form-label">Address type</label>
                                <select className="form-select" data-identifier="addressTypeID" value={addressData.addressTypeID || ''} onChange={ e => updateAddressesValues(e, index)}>
                                    {
                                        caseAddressTypeList.map((data, index) => (
                                            <option key={index} value={data.caseAddressTypeID}>{data.caseAddressTypeName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* <div className="col-lg-2 mb-3">
                                <label className="form-label">Active</label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-identifier="active" data-type="switch" checked={addressData.active || false} onChange={e => updateAddressesValues(e, index)}/>
                                </div>
                            </div> */}
                            {
                                addressesList.length > 1 ? (
                                    <div className="col-lg-2 pt-2" style={{display: 'flex', alignSelf: 'baseline', justifyContent: 'flex-end'}}>
                                        <svg onClick={e => removeAddressBlock(index)} className="cursor--pointer bi bi-x-circle" mlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#db291d" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                        </svg>
                                    </div>
                                ) : ''
                            }
                            <div className="col-lg-8 mb-3">
                                <label className="form-label">Address line 1</label>
                                <input type="text" value={addressData.address1 || ''} className="form-control" onChange={e => updateAddressesValues(e, index)} data-identifier="address1"/>
                            </div>
                            <div className="col-lg-8 mb-3">
                                <label className="form-label">Address line 2</label>
                                <input type="text" value={addressData.address2 || ''} className="form-control" onChange={e => updateAddressesValues(e, index)} data-identifier="address2"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 mb-3">
                                <label htmlFor="inputEmail4" className="form-label">State</label>
                                <select className="form-select" value={addressData.stateID || ''} aria-label="Default select example" data-identifier="stateID" onChange={e => updateAddressesValues(e, index)}>
                                    {stateOptionsJSX}
                                </select>
                            </div>
                            <div className="col-lg-2 mb-3">
                                <label className="form-label">City</label>
                                <input type="text" value={addressData.city || ''} className="form-control" onChange={e => updateAddressesValues(e, index)} data-identifier="city" />
                            </div>
                            <div className="col-lg-1 mb-3">
                                <label className="form-label">Postal Code</label>
                                <input type="number" value={addressData.postalCode || ''} className="form-control" onChange={e => updateAddressesValues(e, index)} data-identifier="postalCode" />
                            </div>
                        </div>
                        <div className="row border-bottom pb-4 mb-4">
                            <div className="col-lg-8">
                                <label className="form-label">Note</label>
                                <textarea value={addressData.note || ''} onChange={e => updateAddressesValues(e, index)} data-identifier="note" className="form-control" rows="2"></textarea>
                            </div>
                        </div>
                        <div className="row mb-3 pb-4 border-bottom" style={{display: 'flex', justifyContent: 'center'}}>
                            <button type="button" className="btn btn-primary btn-md btn-block" onClick={e => addAnotherAddressField()} style={{width: 'fit-content'}}>Add another address field</button>
                        </div>
                    </ React.Fragment>
                ))
            }
        </>
    )
};

export default Addresses;
