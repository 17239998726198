import { useGlobals } from "context/globals-context";

const UserRequests = () => {

    const { userEndpoints, jwt, showToast, fetchData } = useGlobals();

    const getUserList = async (roleListRequest, page, pageSize) => {
        try {
            const data = await fetchData(userEndpoints.getUserList, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify({
                    page,
                    pageSize,
                    roleListRequest
                  })
            })
            if(!Array.isArray(data)) {
                return []
            };
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    const updateUser = async (userData) => {
        try {
            const data = await fetchData(userEndpoints.updateUser, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(userData)
            })
            showToast({
                type: 'success',
                text: 'User updated successfully'
            });
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    const addUser = async (payload) => {
        try {
            const data = await fetchData(userEndpoints.addUser, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(payload)
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    const getRolesList = async () => {
        try {
            const data = await fetchData(userEndpoints.getRoleList, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            return err
        }
    }

    const getPermissionList = async () => {
        try {
            const data = await fetchData(userEndpoints.getPermissionList, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            return err
        }
    }

    const resetPassword = async (data, successMessage, errorMessage) => {
        try {
            const response = await fetchData(userEndpoints.resetPassword, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            showToast({
                type: 'success',
                text: successMessage
            });
            return response;
        } catch (err) {
            console.log(err)
            showToast({
                type: 'danger',
                text: errorMessage
            });
            throw new Error(err.message);
        }
    }

    const forgotPassword = async (data, successMessage, errorMessage) => {
        try {
            const response = await fetchData(userEndpoints.forgotPassword, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(data)
            })
            showToast({
                type: 'success',
                text: successMessage
            });
            return response;
        } catch (err) {
            showToast({
                type: 'danger',
                text: errorMessage
            });
            throw new Error(err.message);
        }
    }

    const getResourceList = async (data, errorMessage = 'Error retrieving the resource list') => {
        try {
            const response = await fetchData(userEndpoints.getResourceList, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(data)
            });
            return response;
        } catch (err) {
            showToast({
                type: 'danger',
                text: errorMessage
            });
            throw new Error(err.message);
        }
    }

    return {
        getUserList,
        updateUser,
        addUser,
        getRolesList,
        getPermissionList,
        resetPassword,
        forgotPassword,
        getResourceList
    }
}

export default UserRequests;