
import { useEffect, useRef, useState } from 'react';
import { useGlobals } from 'context/globals-context';

import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import UserRequests from 'api/UserRequests';

import UseForm from '../form/UseForm';

import './UserModal.scss';

const UserModal = ({ modalRef, selectedUser, handleUpdatedUser, roleList, dashboardList, permissionList }) => {

    const { showToast } = useGlobals();
    const { updateUser } = UserRequests();
    const [ inputsValues, SetInputsValues] = useState({});
    const [ errorMessage, SetErrorMessage ] = useState();
    const [ loading, SetLoading ] = useState(false);

    const secondModal_ref = useRef();
    const secondModalClass_ref = useRef();

    const handleUpdate = async (e) => {
        e.preventDefault();

        // if(!inputsValues?.userRoleList?.length) {
        //     return SetErrorMessage('User roles required');
        // }

        if(inputsValues?.roleID === '') {
            showToast({
                type: 'warning',
                text: 'Please select a role'
            });
            SetErrorMessage('User role required')
            return;
        }

        if(!inputsValues?.userDashboardList?.length) {
            return SetErrorMessage('User dashboards required');
        }

        if(!inputsValues?.userPermissionList?.length) {
            return SetErrorMessage('User permissions required');
        }

        try {
            SetLoading(true);
            await updateUser(inputsValues);
            SetLoading(false);
            handleUpdatedUser(inputsValues);
            secondModalClass_ref.current.hide();
        } catch (err) {
            SetLoading(false);
            console.error(err.message);
            // console.error(JSON.parse(err.message));
            SetErrorMessage('Error');
            return showToast({
                type: 'danger',
                text: 'Error: User not updated'
            });
        }
    }

    useEffect(() => {
        if(!selectedUser) {
            setTimeout(() => {
                SetInputsValues({});
            }, 200);
            return;
        };
        SetInputsValues(selectedUser);
    }, [selectedUser])

    useEffect(() => {
        secondModalClass_ref.current = new bootstrapBundleMin.Modal(secondModal_ref.current, {
            keyboard: false
        });
    }, [])

    return (
        <div className="user-modal">
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalToggleLabel">User</h1>
                    <button type="button" className="btn-close" aria-label="Close" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"></button>
                </div>
                <UseForm inputsValues={inputsValues} roleList={roleList} dashboardList={dashboardList} permissionList={permissionList} readOnly={true} />
                <div className="modal-footer">
                    <button className="btn btn-warning" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Edit</button>
                </div>
                </div>
            </div>
            </div>
            <div className="modal fade" id="exampleModalToggle2" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1"  ref={secondModal_ref}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">Edit User</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <UseForm inputsValues={inputsValues} setInputsValues={SetInputsValues} roleList={roleList} dashboardList={dashboardList} permissionList={permissionList} />
                    {
                        errorMessage ? (
                            <p className="link-danger" style={{marginLeft: '80px'}}><strong>{errorMessage}</strong></p>
                        ) : ''
                    }
                    <div className="modal-footer">
                        {
                            loading && (
                                <div className="spinner--container">
                                    <div className="spinner-border text-light" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            )
                        }
                        <button className="btn btn-secondary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" disabled={loading}>Cancel</button>
                        <button className="btn btn-success" onClick={ e => handleUpdate(e)} disabled={loading}>Accept</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default UserModal;