import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useGlobals } from "context/globals-context";
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';
import ClientRequests from "api/ClientRequests";
import CaseRequests from "api/CaseRequests";
import RequesterRequests from "api/RequesterRequests";
import UserRequests from "api/UserRequests";
import OfficeRequests from "api/OfficeRequests";
import CaseManagerRequests from "api/CaseManagerPostRequests";
import ServicesRequests from "api/ServicesRequests";
import LisOfInsuranceRequests from "api/LineOfInsuranceRequests";
import FlagRequests from "api/FlagRequests";
import GeneralRequests from "api/GeneralRequests";

import DynamicModal from "components/dynamicModal/DynamicModal";
import CaseInfo from "./components/CaseInfo";
import Services from "./components/Services";
import SubjectInfo from "./components/SubjectInfo";
import SubjectDescription from "./components/SubjectDescription";
import Addresses from "./components/Addresses";
import CaseInstructions from "./components/CaseInstructions";
import Client from "./components/Client";
import NewClientModal from "components/clientsList/components/NewClientModal";

const NewCase = () => {

    const { showToast } = useGlobals();
    const navigate = useNavigate();

    const { getClientList } = ClientRequests();
    const { getRequesterList } = RequesterRequests();
    const { getCaseAddressTypeList } = CaseRequests();

    const { getOfficeList } = OfficeRequests();
    const { getCaseManagerList } = CaseManagerRequests();
    const { getServicesList, getServicesTypesList } = ServicesRequests();
    
    const { getRolesList, getUserList } = UserRequests();
    const { getLineOfInsuranceList } = LisOfInsuranceRequests();
    const { getFlagList } = FlagRequests();
    const { newCase } = GeneralRequests();

    const [ loading, SetLoading ] = useState(false);

    const [ clientList, SetClientList ] = useState([]);
    const [ clientQuery, SetClientQuery ] = useState('');
    const [ showClientDropdown, SetShowClientDropdown ] = useState(false);
    const [ selectedClientId, SetSelectedClientId ] = useState('');

    const [ clientAddressList , SetClientAddressList ] = useState([]);
    const [ selectedAddressId , SetSelectedAddressId ] = useState('');

    const [ requesterList, SetRequesterList ] = useState('');
    const [ requesterQuery, SetRequesterQuery ] = useState('');
    const [ showRequesterDropdown, SetShowRequesterDropdown ] = useState(false);
    const [ selectedRequesterId, SetSelectedRequesterId ] = useState();

    const [ clientNotes, SetClientNotes ] = useState('');
    const [ adminNotes, SetAdminNotes ] = useState('');
    
    const [ officeList, SetOfficeList ] = useState([]);
    const [ officeListQuery, SetOfficeListQuery ] = useState('');
    const [ showOfficeListDropdown, SetOfficeListDropdown ] = useState(false);
    const [ selectedOfficeId, SetOfficeId ] = useState('');

    const [ caseManagerList, SetCaseManagerList ] = useState([]);
    const [ selectedCaseManagerId, SetSelectedCaseManagerId ] = useState('');

    const [ serviceList, SetServiceList ] = useState([]);

    const [ caseAddressTypeList, SetCaseAddressTypeList ] = useState([]);
    
    const [ reloadDynamicModal, SetReloadDynamicModal ] = useState(true);

    const [ inputsValues, SetInputValues ] = useState({});

    const [ salesRepRoleID, SetSalesRepRoleID ] = useState();
    const [ salesReps, SetSalesReps ] = useState([]);
    const [ selectedSalesRepId, SetSelectedSalesRepId ] = useState();

    const [ lineOfInsuranceList, SetLineOfInsuranceList ] = useState([]);
    const [ selectedInsuranceId, SetSelectedInsuranceId ] = useState();
    
    const [ flagList, SetFlagList ] = useState([]);

    const [ servicesTypesList, SetServicesTypesList ] = useState([]);
    const [ selectedServiceTypeId, SetServiceTypeId ] = useState('');

    const [ caseInstructions, SetCaseInstructions ] = useState('');

    const [ addressesList, SetAddressesList ] = useState([{
        "addressName": "",
        "addressTypeID": 1,
        "address1": "",
        "address2": "",
        "city": "",
        "stateID": "",
        "postalCode": "",
        "note": "",
    }]);

    const [ newClientAdminNotes, SetNewClientAdminNotes ] = useState('');
    const [ newClientClientNotes, SetNewClientClientNotes ] = useState('');
    const [ newClientAddressList, SetNewClientAddressList ] = useState([
        {
            addressName: '',
            address1: '',
            address2: '',
            city: '',
            stateID: '',
            postalCode: '',
            phone: '',
            phoneExt: '',
            primary: true,
            active: true,
        }
    ]);
    const [ newClientInputValues, SetNewClientInputValues ] = useState({active: true});

    const newClientModal_ref = useRef();
    const newClientModalClass_ref = useRef();

    const clientHasBeenSelected_ref = useRef();

    const otherFieldsValues_ref = useRef({
        newCaseServiceList: [],
        newCaseFlagList: [],
    });

    const modalRef = useRef();
    const dynamicModal_ref = useRef();
    const dynamicModalOptions_ref = useRef();

    const handleNewClientInputChange = (e) => {
        const key = e.currentTarget.dataset.identifier;
        const value = e.currentTarget.checked || e.currentTarget.value;
        const type = e.currentTarget.dataset.type;

        if(type === 'switch') {
            if(value === 'on') newClientInputValues[key] = false;
            else newClientInputValues[key] = true;
        } else if(key === 'stateID') {
            newClientInputValues[key] = value;
        } else newClientInputValues[key] = value;

        SetNewClientInputValues({...newClientInputValues});
    }

    const updateValue = (e, type, index) => {
        const identifier = e.currentTarget.dataset.identifier;
        if(type === 'checkbox-flag') {
            const checked = e.currentTarget.checked;
            const flagID = e.currentTarget.dataset.identifier;

            if(checked) { // Add it
                otherFieldsValues_ref.current.newCaseFlagList.push({ flagID })
            } else { // Remove it
                const index = otherFieldsValues_ref.current.newCaseFlagList.findIndex(flagData => flagData.flatID === flagID);
                otherFieldsValues_ref.current.newCaseFlagList.splice(index, 1);
            }
        } else if(type === 'checkbox-service') {
            const checked = e.currentTarget.checked;
            const serviceID = e.currentTarget.dataset.identifier;

            if(checked) { // Add it
                otherFieldsValues_ref.current.newCaseServiceList.push({ serviceID})
            } else { // Remove it
                const index = otherFieldsValues_ref.current.newCaseServiceList.findIndex(service => service.serviceID === serviceID);
                otherFieldsValues_ref.current.newCaseServiceList.splice(index, 1);
            }
        } else if(type === 'checkbox') otherFieldsValues_ref.current[identifier] = e.currentTarget.checked;
        else if(type === 'newCaseAddressList') {
            if(!otherFieldsValues_ref.current.newCaseAddressList[index]) otherFieldsValues_ref.current.newCaseAddressList[index] = {};
            otherFieldsValues_ref.current.newCaseAddressList[index][identifier] = e.currentTarget.value;
        }
        else otherFieldsValues_ref.current[identifier] = e.currentTarget.value;
    }

    const handleAccept = async e => {
        e.preventDefault();

        try {
            const MANDATORY_FIELDS = {
                "clientID": 'Please select a client',
                "requesterID": 'Please select a requester',
                "clientAddressID": "Please select a client's address",
                "clientNotes": "Please fill out the client's notes",
                "clientAdminNotes": "Please fill out the admin's notes",
                "officeID": "Please select the case's office",
                "caseManagerID": "Please select a case manager",
                "serviceTypeID": "Please select the service type",
                "newCaseServiceList": "Please select the services that will be provided",
                "firstName": 'Please fill out the subject\'s first name',
                "lastName": "Please fill out the subject's last name",
                "ssn": "Please fill out the subject's SSN"
            };
            
            const filteredAddresses = addressesList.filter(address => address.addressName);
    
            const data = {
                newCaseRequest: true,
                clientID: selectedClientId,
                requesterID: selectedRequesterId,
                officeID: selectedOfficeId,
                caseManagerID: selectedCaseManagerId,
                salesRepID: selectedSalesRepId,
                lineOfInsuranceID: selectedInsuranceId,
                newCaseAddressList: filteredAddresses,
                serviceTypeID: selectedServiceTypeId,
                clientNotes,
                clientAdminNotes: adminNotes,
                clientAddressID: selectedAddressId,
                ...otherFieldsValues_ref.current
            }

            if(caseInstructions) data.caseInstructions = caseInstructions;
    
            let errorMessage = '';
            Object.keys(MANDATORY_FIELDS).some(identifier => {
                if(identifier === 'newCaseServiceList' && !data[identifier].length) return errorMessage = MANDATORY_FIELDS[identifier];
                else if(!data[identifier]) return errorMessage = MANDATORY_FIELDS[identifier];
                else return false;
            });
    
            if(errorMessage) return showToast({
                type: 'warning',
                text: errorMessage
            });
            SetLoading(true);
            const response = await newCase(data);
            SetLoading(false);

            showToast({
                type: 'success',
                text: 'Case added successfully'
            });
            navigate(`/case?id=${response.caseMasterID}`);
        } catch (err) {
            console.error(err);
            SetLoading(false);
            showToast({
                type: 'danger',
                text: 'There was an error when attempting to create the new case'
            });
        }
    }

    useEffect(() => {
        SetRequesterQuery('');
        SetSelectedRequesterId('');

        if(!clientHasBeenSelected_ref.current) {
            SetSelectedClientId('');
        } else {
            clientHasBeenSelected_ref.current = false;
        }

        const loadClientList = async () => {
            try {
                const clientListResponse = await getClientList(clientQuery);
                SetClientList(clientListResponse);
            } catch (err) {
                console.error(err);
            }
        }
        loadClientList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientQuery]);

    useEffect(() => {
        if(!selectedClientId) return;
        const loadRequesterList = async () => {
            try {
                const requesterListResponse = await getRequesterList(selectedClientId, requesterQuery);
                SetRequesterList(requesterListResponse);
            } catch (err) {
                console.error(err);
            }
        }
        loadRequesterList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedClientId, requesterQuery]);

    useEffect(() => {
        if(!selectedClientId) {
            SetClientAddressList([]);
            SetSelectedAddressId('');
        } else {
            const selectedClient = clientList.find(client => client.clientID === selectedClientId);
            const primaryAddressId = selectedClient?.clientAddressList?.find(address => address.primary)?.clientAddressID;
            SetClientAddressList(selectedClient.clientAddressList || []);
            if(primaryAddressId) SetSelectedAddressId(primaryAddressId);
            else SetSelectedAddressId('');
        };
        
    }, [selectedClientId, clientList])

    useEffect(() => {
        const loadBillToList = async () => {
            try {
                const officeListResponse = await getOfficeList(officeListQuery);
                SetOfficeList(officeListResponse);
            } catch (err) {
                console.error(err);
            }
        }
        loadBillToList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeListQuery]);

    useEffect(() => {
        if(!salesRepRoleID) return;

        const loadSalesReps = async () => {
            try {
                const response = await getUserList([
                    {
                        "roleID": salesRepRoleID
                    }
                ]);

                SetSalesReps(response);
                SetSelectedSalesRepId(response[0].userID);
            } catch (err) {
                console.error(err)
            }
        }

        loadSalesReps();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesRepRoleID]);

    useEffect(() => {
        const loadCaseManagerList = async () => {
            try {
                const response = await getCaseManagerList();

                SetCaseManagerList(response);
            } catch (err) {
                throw new Error(err.message);
            }
        };
        const loadServiceList = async () => {
            try {
                const response = await getServicesList();
                SetServiceList(response);
            } catch (err) {
                console.error(err);
            }
        };
        const loadCaseAddressTypeList = async () => {
            try {
                const response = await getCaseAddressTypeList();
                SetCaseAddressTypeList(response);
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'Error loading case address types list'
                })
                console.error(err)
            }
        };
        const loadUserRoleList = async () => {
            try {
                const response   = await getRolesList();
                const salesRepID = response.find(roleData => roleData.roleName === 'Sales Person')?.roleID;

                if(!salesRepID) return showToast({
                    type: 'danger',
                    text: 'Could not load sales rep ID',
                });

                SetSalesRepRoleID(salesRepID);
                
            } catch (err) {
                console.error(err)
                showToast({
                    type: 'danger',
                    text: 'Error loading user role list'
                })
            }
        };
        const loadLineOfInsuranceList = async () => {
            try {
                const response = await getLineOfInsuranceList();

                if(!response.length) return showToast({
                    type: 'Danger',
                    title: 'Error',
                    text: 'Could not load line of insurance list',
                });

                SetSelectedInsuranceId(response[0].lineOfInsuranceID);
                SetLineOfInsuranceList(response);
                
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'Error loading line of insurance list'
                })
            }
        };
        const loadFlagList = async () => {
            try {
                const response = await getFlagList();

                SetFlagList(response);
                
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'Error loading subject flag list'
                })
            }
        };

        dynamicModal_ref.current = new bootstrapBundleMin.Modal(modalRef.current, {
            keyboard: false
        });
        newClientModalClass_ref.current = new bootstrapBundleMin.Modal(newClientModal_ref.current, {
            keyboard: false
        });
        const loadServicesTypesList = async () => {
            try {
                const response = await getServicesTypesList();
                if(!response.length) throw new Error('Empty array response from getServicesTypesList');
                SetServicesTypesList(response);

            } catch (err) {
                console.error(err);
                showToast({
                    type: 'danger',
                    text: 'Error loading services types list'
                })
            }
        }

        loadFlagList();
        loadLineOfInsuranceList();
        loadCaseAddressTypeList();
        loadCaseManagerList();
        loadServiceList();
        loadUserRoleList();
        loadServicesTypesList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="new-case container-fluid">
            <div className="p-3 bg-body-tertiary rounded-3">
                <h3 className="display-6 pb-2">Create case</h3>
                <form className="row g-3" autoComplete="false">
                    <div className="my-3 bg-body p-3">
                        <ul className="nav nav-tabs">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id='client' data-bs-toggle="tab" data-bs-target="#client-tab-pane" type="button" role="tab" aria-controls="client-tab-pane" aria-selected="true">Client<span className="mandatory">*</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id='case-info' data-bs-toggle="tab" data-bs-target="#case-info-tab-pane" type="button" role="tab" aria-controls="case-info-tab-pane" aria-selected="true">Case Info<span className="mandatory">*</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id='services' data-bs-toggle="tab" data-bs-target="#services-tab-pane" type="button" role="tab" aria-controls="services-tab-pane" aria-selected="true">Services<span className="mandatory">*</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id='subject-info' data-bs-toggle="tab" data-bs-target="#subject-info-tab-pane" type="button" role="tab" aria-controls="subject-info-tab-pane" aria-selected="true">Subject Info<span className="mandatory">*</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id='subject-description' data-bs-toggle="tab" data-bs-target="#subject-description-tab-pane" type="button" role="tab" aria-controls="subject-description-tab-pane" aria-selected="true">Subject description</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id='addresses' data-bs-toggle="tab" data-bs-target="#addresses-tab-pane" type="button" role="tab" aria-controls="addresses-tab-pane" aria-selected="true">Addresses</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id='instructions-info' data-bs-toggle="tab" data-bs-target="#instructions-info-tab-pane" type="button" role="tab" aria-controls="instructions-info-tab-pane" aria-selected="true">Case instructions</button>
                            </li>
                        </ul>
                        <div className="tab-content my-3" id="myTabContent">
                            <div className="tab-pane fade show active" id="client-tab-pane" role="tabpanel" aria-labelledby="client-tab" tabIndex="0">
                                <Client
                                    SetShowClientDropdown={SetShowClientDropdown}
                                    SetClientQuery={SetClientQuery}
                                    SetSelectedClientId={SetSelectedClientId}
                                    clientQuery={clientQuery}
                                    clientList={clientList}
                                    showClientDropdown={showClientDropdown}
                                    SetShowRequesterDropdown={SetShowRequesterDropdown}
                                    SetRequesterQuery={SetRequesterQuery}
                                    SetSelectedRequesterId={SetSelectedRequesterId}
                                    requesterQuery={requesterQuery}
                                    selectedClientId={selectedClientId}
                                    requesterList={requesterList}
                                    showRequesterDropdown={showRequesterDropdown}
                                    SetClientList={SetClientList}
                                    SetInputValues={SetInputValues}
                                    dynamicModal_ref={dynamicModal_ref}
                                    dynamicModalOptions_ref={dynamicModalOptions_ref}
                                    SetRequesterList={SetRequesterList}
                                    SetReloadDynamicModal={SetReloadDynamicModal}
                                    clientNotes={clientNotes}
                                    SetClientNotes={SetClientNotes}
                                    adminNotes={adminNotes}
                                    SetAdminNotes={SetAdminNotes}
                                    clientAddressList={clientAddressList}
                                    setClientAddressList={SetClientAddressList}
                                    selectedAddressId={selectedAddressId}
                                    setSelectedAddressId={SetSelectedAddressId}
                                    clientHasBeenSelected_ref={clientHasBeenSelected_ref}
                                    newClientModalClass_ref={newClientModalClass_ref}
                                />
                            </div>
                            <div className="tab-pane fade show" id="case-info-tab-pane" role="tabpanel" aria-labelledby="case-info-tab" tabIndex="0">
                                <CaseInfo
                                    SetOfficeListDropdown={SetOfficeListDropdown}
                                    SetOfficeListQuery={SetOfficeListQuery}
                                    SetOfficeId={SetOfficeId}
                                    officeListQuery={officeListQuery}
                                    officeList={officeList}
                                    showOfficeListDropdown={showOfficeListDropdown}
                                    selectedCaseManagerId={selectedCaseManagerId}
                                    SetSelectedCaseManagerId={SetSelectedCaseManagerId}
                                    caseManagerList={caseManagerList}
                                    SetSelectedSalesRepId={SetSelectedSalesRepId}
                                    salesReps={salesReps}
                                    updateValue={updateValue}
                                    SetSelectedInsuranceId={SetSelectedInsuranceId}
                                    selectedInsuranceId={selectedInsuranceId}
                                    lineOfInsuranceList={lineOfInsuranceList}
                                    flagList={flagList}
                                />
                            </div>
                            <div className="tab-pane fade show" id="services-tab-pane" role="tabpanel" aria-labelledby="services-tab" tabIndex="0">
                                <Services
                                    selectedServiceTypeId={selectedServiceTypeId}
                                    SetServiceTypeId={SetServiceTypeId}
                                    servicesTypesList={servicesTypesList}
                                    serviceList={serviceList}
                                    updateValue={updateValue}
                                />
                            </div>
                            <div className="tab-pane fade" id="subject-info-tab-pane" role="tabpanel" aria-labelledby="subject-info-tab" tabIndex="0">
                                <SubjectInfo updateValue={updateValue} />
                            </div>
                            <div className="tab-pane fade" id="subject-description-tab-pane" role="tabpanel" aria-labelledby="subject-description-tab" tabIndex="0">
                                <SubjectDescription updateValue={updateValue} />
                            </div>
                            <div className="tab-pane fade" id="addresses-tab-pane" role="tabpanel" aria-labelledby="addresses-tab" tabIndex="0">
                                <Addresses addressesList={addressesList} SetAddressesList={SetAddressesList} caseAddressTypeList={caseAddressTypeList} />
                            </div>
                            
                            <div className="tab-pane fade" id="instructions-info-tab-pane" role="tabpanel" aria-labelledby="instructions-info-tab" tabIndex="0">
                                <CaseInstructions caseInstructions={caseInstructions} SetCaseInstructions={SetCaseInstructions} />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-end my-3">
                        <div className="col-2">
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary" onClick={e => handleAccept(e)} disabled={loading}>Create case</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <DynamicModal modalRef={modalRef} options_ref={dynamicModalOptions_ref} reloadDynamicModal={reloadDynamicModal} inputsValues={inputsValues} setInputsValues={SetInputValues} />
            <NewClientModal
                newClientModal_ref={newClientModal_ref}
                newClientModalClass_ref={newClientModalClass_ref}
                setClientsList={SetClientList}
                handleInputChange={handleNewClientInputChange}
                adminNotes={newClientAdminNotes}
                setAdminNotes={SetNewClientAdminNotes}
                clientNotes={newClientClientNotes}
                setClientNotes={SetNewClientClientNotes}
                inputsValues={newClientInputValues}
                setInputsValues={SetNewClientInputValues}
                addressesList={newClientAddressList}
                setAddressesList={SetNewClientAddressList}
                setSelectedClientId={SetSelectedClientId}
                setClientQuery={SetClientQuery}
                clientHasBeenSelected_ref={clientHasBeenSelected_ref}
            />
        </div>
    )
}

export default NewCase;