import React, { createContext, useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';
import endpoints from './endpoints';
import useNetworkInterceptor from "api/useNetworkInterceptor";

const GlobalsContext = createContext();

export function RedirectProvider(props) {
    const [jwt, SetJwt] = useState(null);
    const [ myData, SetMyData ] = useState(localStorage.getItem('myData') ? JSON.parse(localStorage.getItem('myData')) : null);
    const [ redirectURL, SetRedirectURL ] = useState();
    const [ theme, SetTheme ] = useState(localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : 'light');
    const [ globalToastOptions, SetGlobalToastOptions ] = useState({
        type: 'success',
        text: ''
    });
    const globalModalRef = useRef();
    const globalModalBootstrapClassRef = useRef();
    const globalToastRef = useRef();
    const storedJwt = localStorage.getItem('storedJWT') || sessionStorage.getItem('storedJWT');
    const navigate = useNavigate();
    const CURRENT_URL = useLocation().pathname;
    const { 
        SERVER_ADDRESS,
        userEndpoints,
        caseListEndpoints,
        clientEndpoints,
        requesterEndpoints,
        insuredEndpoints,
        carrierEndpoints,
        billToEndpoints,
        adjusterEndpoints,
        officeEndpoints,
        servicesEndpoints,
        subjectAddress,
        lineOfInsuranceEndPoints,
        flagEndPoints,
        resourceTypeEndpoints,
        templateEndpoints,
        dashboardEndpoints,
        templatesEndpoints,
    } = endpoints;

    const networkInterceptor = useNetworkInterceptor();

    const fetchData = async (url, options, type) => {
        try {
            return await networkInterceptor(url, options, type)
        } catch (err) {
            if(err.message === 'invalid token' || err.message === 'jwt malformed') {
                redirect()
                throw new Error('invalid token')
            } else {
                throw new Error(err.message);
            }
        }
    }

    const setToken = async (token, rememberMe) => {
        if(rememberMe) localStorage.setItem('storedJWT', JSON.stringify(token));
        else if(rememberMe === false) sessionStorage.setItem('storedJWT', JSON.stringify(token));
        SetJwt(token)

        return true;
    };

    const redirect = () => {
        globalModalBootstrapClassRef.current = new bootstrapBundleMin.Modal(globalModalRef.current, {
            keyboard: false,
            backdrop: 'static'
        });
        globalModalBootstrapClassRef.current.show();
    }

    const deleteToken = () => {
        localStorage.removeItem('storedJWT');
        sessionStorage.clear();
        SetJwt(null);
        removeMyData();
        navigate("/");
    };

    const toggleTheme = () => {
        console.log(theme)
        localStorage.setItem('theme', JSON.stringify(theme === 'dark' ? 'light' : 'dark'));
        SetTheme(theme === 'dark' ? 'light' : 'dark');
    }

    const resetGlobalModal = () => {
        deleteToken();
        globalModalBootstrapClassRef.current.hide();
    }

    const showToast = options => {
        SetGlobalToastOptions(options);
        const toastBootstrap = bootstrapBundleMin.Toast.getOrCreateInstance(globalToastRef.current);
        setTimeout(() => toastBootstrap.show(), 100);
    }

    const updateMyData = data => {
        localStorage.setItem('myData', JSON.stringify(data));
        SetMyData(data);
    }

    const removeMyData = () => {
        localStorage.removeItem('myData');
        SetMyData(null);
    }

    const navigateToDefaultDashboard = (allowRefresh) => {
        const defaultDashboard = myData.userDashboardList.find(dashboardItem => dashboardItem.dashboardID === myData.dashboardID)?.dashboard?.dashboardName;
        
        if(!allowRefresh && CURRENT_URL === `/dashboard-${defaultDashboard}`) return;
        if(defaultDashboard) navigate(`/dashboard-${defaultDashboard}`);
        else {
            navigate(`/dashboard-${myData.userDashboardList[0].dashboard.dashboardName}`)
        }
    }

    if(!jwt && storedJwt) SetJwt(JSON.parse(storedJwt));

    useEffect(() => {
        if(!jwt) return;
        if(CURRENT_URL === '/' && myData) {
            if(redirectURL) {
                navigate(redirectURL);
                SetRedirectURL(null);
                return
            };
            navigateToDefaultDashboard();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt, myData]);

    // const value = useMemo(() => {
        const value = {
            jwt,
            SERVER_ADDRESS,
            userEndpoints,
            caseListEndpoints,
            clientEndpoints,
            setToken,
            deleteToken,
            redirect,
            resetGlobalModal,
            globalModalRef,
            globalToastOptions,
            globalToastRef,
            showToast,
            fetchData,
            requesterEndpoints,
            insuredEndpoints,
            carrierEndpoints,
            billToEndpoints,
            adjusterEndpoints,
            officeEndpoints,
            servicesEndpoints,
            subjectAddress,
            lineOfInsuranceEndPoints,
            flagEndPoints,
            resourceTypeEndpoints,
            templateEndpoints,
            dashboardEndpoints,
            myData,
            updateMyData,
            redirectURL,
            SetRedirectURL,
            navigateToDefaultDashboard,
            theme,
            toggleTheme,
            templatesEndpoints,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [jwt, globalToastOptions])

    return <GlobalsContext.Provider value={value} {...props} />;
}

export function useGlobals() {
    const context = useContext(GlobalsContext);
    if(!context) {
        throw new Error('Redirect context must be inside of RedirectContext provider');
    }
    return context
}