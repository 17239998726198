import { useEffect, useMemo, useState } from "react";
import { useGlobals } from "context/globals-context";

import './DynamicModal.scss';
import useValidationFunctions from "helpers/useValidationFunctions";

const DynamicModal = ({modalRef, options_ref, reloadDynamicModal, inputsValues, setInputsValues}) => {

    const NUMBER_OF_COLUMNS = 3;

    const { showToast } = useGlobals();
    const { isValidJSON } = useValidationFunctions();

    const [ errorMessage, SetErrorMessage ] = useState();
    const [ loading, SetLoading ] = useState(false);
    
    const [ modalHeader, SetModalHeader ] = useState('');
    const [ fields, SetFields ] = useState([]);

    const fieldsJSX = useMemo(() => {
        if(!fields.length) return '';
        const JSX = []
        const numberOfRows = Math.ceil( fields.length / NUMBER_OF_COLUMNS);
        let j = {counter: 0};
        
        for(let i = 0; i < numberOfRows; i++) {
            JSX.push(
                <div className='row g-3 mb-4' key={`row-${i}`}>
                    {
                        Array.from({length: NUMBER_OF_COLUMNS}).map((_, index) => {
                            j.counter += 1;
                            const field = fields[j.counter - 1];
                            if(!field) return ''
                            return (
                                <div className="col-lg-4" key={j.counter - 1}>
                                    <label className="form-label">{field.label}{field.mandatory ? <span className="mandatory">*</span> : ''}</label>
                                    <input type={field.type} className="form-control" onChange={e => handleInputChange(e)} value={inputsValues[field.fieldName] || ''} required={field.mandatory || false} id={field.fieldName} />
                                </div>
                            )
                        })
                    }
                </div>
            )
        }

        return JSX

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, inputsValues]);

    const handleInputChange = (e) => {
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;

        inputsValues[key] = value;
        
        setInputsValues({...inputsValues});
        SetErrorMessage('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const request = options_ref.current.requestFunction;
        const handleResponse = options_ref.current.handleResponseFunction;
        const successMessage = options_ref.current.successMessage;
        const errorMessage = options_ref.current.errorMessage;
        const extraFields = options_ref.current.extraFields;

        try {
            SetLoading(true);
            const response = await request(extraFields ? {...extraFields, ...inputsValues} : inputsValues);
            SetLoading(false);
            handleResponse(response)
            showToast({
                type: 'success',
                text: successMessage || 'Success'
            });
        } catch (err) {
            SetLoading(false);
            if(isValidJSON(err.message)) {
                const errorMessage = JSON.parse(err.message).message;
                console.log(errorMessage)
                if(errorMessage.includes('is already in use')) SetErrorMessage(errorMessage);
            } else SetErrorMessage(err.message);
            showToast({
                type: 'danger',
                text: errorMessage || 'Error'
            });
        }
    }

    useEffect(() => {
        if(!options_ref.current) return;

        SetModalHeader(options_ref.current.modalHeader);
        SetFields(options_ref.current.fields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadDynamicModal])

    useEffect(() => {

    }, [])

    return (
        <div className="dynamic-modal">
            <div className="modal fade" id="newUserModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modalRef}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">{modalHeader}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <br />
                        <div className="modal-body">
                            
                            {fieldsJSX}
                                    
                            <div className="modal-footer mt-5">
                                {
                                    errorMessage ? (
                                        <p className="link-danger"><strong>{errorMessage}</strong></p>
                                        // <p className="link-danger"><strong>{errorMessage}</strong></p>
                                    ) : ''
                                }
                                {
                                    loading && (
                                        <div className="spinner--container">
                                            <div className="spinner-border text-light" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    )
                                }
                                <button className="btn btn-secondary" data-bs-target="#newUserModalToggle" data-bs-toggle="modal" disabled={loading}>Cancel</button>
                                <button className='btn btn-success' type="submit" disabled={loading}>Accept</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DynamicModal;