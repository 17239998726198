import { useEffect, useState } from "react";
import { useGlobals } from "context/globals-context";
import CaseRequests from "api/CaseRequests";

const StatusesFilterModal = ({modal, statusFilterSelections, setCaseStatusListFilter, handleInputChange, clearStatusesFilter}) => {

    const { showToast } = useGlobals();

    const { getCaseStatusList } = CaseRequests();

    const [ flagList, SetStatusList ] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {

            // const response = await addUser(inputsValues);
            // console.log(response)
        } catch (err) {
            showToast({
                type: 'danger',
                text: 'There was an error while loading the flag list'
            });
            console.error(err)
        }
    }

    useEffect(() => {
        const loadCaseStatuses = async () => {
            const response = await getCaseStatusList();
            SetStatusList(response);
        }

        loadCaseStatuses();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="new-user-modal">
            <div className="modal fade" id="filterByStatusModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modal}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Filter by case status</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex no-shrink mb-3">
                                <div className="list-group wrap-elements">
                                    {
                                        flagList.map(({caseStatusID, caseStatusName}, index) => (
                                            <label key={index} className="list-group-item d-flex gap-2 border small-border-radius">
                                                <input className="form-check-input flex-shrink-0" onChange={e => handleInputChange(caseStatusID, e.currentTarget.checked)} type="checkbox" value={caseStatusID} checked={statusFilterSelections.find(obj => obj.caseStatusID === caseStatusID) || false} />
                                                <span>{caseStatusName}</span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                                    
                            <div className="modal-footer">
                                <button className="btn btn-secondary" data-bs-target="#filterByStatusModal" data-bs-toggle="modal" onClick={e => clearStatusesFilter()}>Deselect all</button>
                                <button className="btn btn-success" data-bs-target="#filterByStatusModal" type="submit" data-bs-toggle="modal" onClick={e => setCaseStatusListFilter(statusFilterSelections)}>Accept</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StatusesFilterModal;