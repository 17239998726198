import { useState } from "react";

const UseForm = ({inputsValues, setInputsValues, roleList, dashboardList, permissionList, readOnly = false}) => {
    const STATES = {
        '':'',
        'AL':'Alabama',
        'AK':'Alaska',
        'AZ':'Arizona',
        'AR':'Arkansas',
        'CA':'California',
        'CO':'Colorado',
        'CT':'Connecticut',
        'DE':'Delaware',
        'DC':'District of Columbia',
        'FL':'Florida',
        'GA':'Georgia',
        'HI':'Hawaii',
        'ID':'Idaho',
        'IL':'Illinois',
        'IN':'Indiana',
        'IA':'Iowa',
        'KS':'Kansas',
        'KY':'Kentucky',
        'LA':'Louisiana',
        'ME':'Maine',
        'MD':'Maryland',
        'MA':'Massachusetts',
        'MI':'Michigan',
        'MN':'Minnesota',
        'MS':'Mississippi',
        'MO':'Missouri',
        'MT':'Montana',
        'NE':'Nebraska',
        'NV':'Nevada',
        'NH':'New Hampshire',
        'NJ':'New Jersey',
        'NM':'New Mexico',
        'NY':'New York',
        'NC':'North Carolina',
        'ND':'North Dakota',
        'OH':'Ohio',
        'OK':'Oklahoma',
        'OR':'Oregon',
        'PA':'Pennsylvania',
        'RI':'Rhode Island',
        'SC':'South Carolina',
        'SD':'South Dakota',
        'TN':'Tennessee',
        'TX':'Texas',
        'UT':'Utah',
        'VT':'Vermont',
        'VA':'Virginia',
        'WA':'Washington',
        'WV':'West Virginia',
        'WI':'Wisconsin',
        'WY':'Wyoming',
    };

    const [ selectedTab, SetSelectedTab ] = useState('user-info');

    const keys = Object.keys(STATES);

    const stateOptionsJSX = keys.map((key, index) => {
        return (
            <option key={index} value={key}>{STATES[key]}</option>
        )
    });

    const handleInputChange = (e) => {
        const identifier = e.currentTarget.dataset.identifier;
        const value = e.currentTarget.value;
        const format = e.currentTarget.dataset.format;

        if(identifier === 'stateID') {
            inputsValues[identifier] = value
        } else if(identifier === 'userDashboardList') {
            const checked = e.currentTarget.checked;
            if(checked) addValue();
            else removeValue();
            updateDefaultDashboard();

            function addValue () {
                if(!inputsValues.userDashboardList) inputsValues.userDashboardList = [];
                const found = inputsValues.userDashboardList.find(dashboardObj => dashboardObj.dashboardID === value);
                if(!found) inputsValues.userDashboardList.push({dashboardID: Number(value)});
            };

            function removeValue () {
                const index = inputsValues.userDashboardList.findIndex(dashboardObj => dashboardObj.dashboardID === Number(value));
                inputsValues.userDashboardList.splice(index, 1);
            };

            function updateDefaultDashboard () {
                const availableDashboards = inputsValues[identifier];
                const defaultDashboard = inputsValues.dashboardID;
                
                if(!availableDashboards.length) delete inputsValues.dashboardID;
                else {
                    const defaultDashboardExists = availableDashboards.find(dashboard => dashboard.dashboardID === defaultDashboard);
                    if(!defaultDashboardExists) inputsValues.dashboardID = availableDashboards[0].dashboardID;
                    else inputsValues.dashboardID = defaultDashboardExists.dashboardID;
                }
            }
        } else if(identifier === 'userPermissionList') {
            const checked = e.currentTarget.checked;
            if(checked) addValue()
            else removeValue();

            function addValue () {
                if(!inputsValues.userPermissionList) inputsValues.userPermissionList = [];
                const found = inputsValues.userPermissionList.find(permissionObj => permissionObj.permissionID === value);
                if(!found) inputsValues.userPermissionList.push({permissionID: Number(value)});
            }

            function removeValue () {
                const index = inputsValues.userPermissionList.findIndex(permissionObj => permissionObj.permissionID === Number(value));
                inputsValues.userPermissionList.splice(index, 1);
            }
        } else {
            if(format === 'number') inputsValues[identifier] = Number(value);
            else inputsValues[identifier] = value;
        }

        setInputsValues({...inputsValues});
    }

    return (
        // <div className="">
            <div className="modal-body" style={{minHeight: '526px'}}>
                <ul className="nav nav-tabs">
                    <li className="nav-item" role="presentation">
                        <button onClick={e => SetSelectedTab(e.currentTarget.id)} className="nav-link active" id='user-info' data-bs-toggle="tab" data-bs-target="#user-info-tab-pane" type="button" role="tab" aria-controls="user-info-tab-pane" aria-selected="true">User Info { !readOnly && <span className="mandatory">*</span> }</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={e => SetSelectedTab(e.currentTarget.id)} className="nav-link" id='dashboards' data-bs-toggle="tab" data-bs-target="#dashboards-tab-pane" type="button" role="tab" aria-controls="dashboards-tab-pane" aria-selected="true">Dashboards { !readOnly && <span className="mandatory">*</span> }</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={e => SetSelectedTab(e.currentTarget.id)} className="nav-link" id='permissions' data-bs-toggle="tab" data-bs-target="#permissions-tab-pane" type="button" role="tab" aria-controls="permissions-tab-pane" aria-selected="true">Permissions { !readOnly && <span className="mandatory">*</span> }</button>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade show ${selectedTab === 'user-info' ? 'active' : ''}`} id="user-info-tab-pane" role="tabpanel" aria-labelledby="user-info-tab" tabIndex="0">
                        <div className='row g-3 pt-3 mb-3'>
                            {
                                readOnly ? (
                                    <div className="col-lg-4">
                                        <p className="close-distance"><small>First name</small></p>
                                        <p className="lead">{inputsValues.firstName || 'Not set' || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-4">
                                        <label className="form-label">First name<span className="mandatory">*</span></label>
                                        <input type="text" className="form-control" data-identifier="firstName" onChange={e => handleInputChange(e)} value={inputsValues.firstName || ''} required />
                                    </div>
                                )
                            }
                            {
                                readOnly ? (
                                    <div className="col-lg-4">
                                        <p className="close-distance"><small>Last name</small></p>
                                        <p className="lead">{inputsValues.lastName || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-4">
                                        <label className="form-label">Last name<span className="mandatory">*</span></label>
                                        <input type="text" className="form-control" data-identifier="lastName" onChange={e => handleInputChange(e)} value={inputsValues.lastName || ''} required />
                                    </div>
                                )
                            }
                            {
                                readOnly ? (
                                    <div className="col-lg-4">
                                        <p className="close-distance"><small>Phone number</small></p>
                                        <p className="lead">{inputsValues.phone || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-4">
                                        <label className="form-label">Phone number</label>
                                        <input data-identifier="phone" type="tel" className="form-control" onChange={e => handleInputChange(e)} value={inputsValues.phone || ''} />
                                    </div>
                                )
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <label className="form-label">Role<span className="mandatory">*</span></label>
                                {
                                    readOnly ? (
                                        <label className="list-group-item d-flex gap-2">
                                            <span>{roleList.find(({roleID}) => roleID?.toString() === inputsValues?.roleID?.toString())?.roleName || 'None assigned'}</span>
                                        </label>
                                    ) : (
                                        <select className="form-select" data-identifier="roleID" value={inputsValues?.roleID || ''} onChange={e => handleInputChange(e)}>
                                            <option value="">Please select one</option>
                                            {
                                                roleList.map((role, index) => (
                                                    <option key={index} value={role.roleID}>{role.roleName}</option>
                                                ))
                                            }
                                        </select>
                                    )
                                }
                            </div>
                            </div>
                        <div className="row mb-3">
                            {
                                readOnly ? (
                                    <div className="col-lg-8 mb-3">
                                        <p className="close-distance"><small>Email</small></p>
                                        <p className="lead">{inputsValues.email || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-8 mb-3">
                                        <label className="form-label">Email</label>
                                        <input type="email" className="form-control" data-identifier="email" onChange={e => handleInputChange(e)} value={inputsValues.email || ''} required />
                                    </div>
                                )
                            }
                            {
                                readOnly ? (
                                    <div className="col-lg-8 mb-3">
                                        <p className="close-distance"><small>Address 1</small></p>
                                        <p className="lead">{inputsValues.address1 || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-8 mb-3">
                                        <label className="form-label">Address 1</label>
                                        <input type="text" className="form-control" data-identifier="address1" onChange={e => handleInputChange(e)} value={inputsValues.address1 || ''} />
                                    </div>
                                )
                            }
                            {
                                readOnly ? (
                                    <div className="col-lg-8 mb-3">
                                        <p className="close-distance"><small>Address 2</small></p>
                                        <p className="lead">{inputsValues.address2 || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-8 mb-3">
                                        <label className="form-label">Address 2</label>
                                        <input type="text" className="form-control" data-identifier="address2" onChange={e => handleInputChange(e)} value={inputsValues.address2 || ''} />
                                    </div>
                                )
                            }

                        </div>
                        <div className="row">
                            
                            {
                                readOnly ? (
                                    <div className="col-lg-4">
                                        <p className="close-distance"><small>City</small></p>
                                        <p className="lead">{inputsValues.city || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-4">
                                        <label className="form-label">City</label>
                                        <input type="text" className="form-control" data-identifier="city" onChange={e => handleInputChange(e)} value={inputsValues.city || ''} />
                                    </div>
                                )
                            }
                            {
                                readOnly ? (
                                    <div className="col-lg-4">
                                        <p className="close-distance"><small>State</small></p>
                                        <p className="lead">{inputsValues.stateID || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-4">
                                        <label htmlFor="inputEmail4" className="form-label">State</label>
                                        <select className="form-select" aria-label="Default select example" data-identifier="stateID" onChange={e => handleInputChange(e)} value={inputsValues.stateID || ''}>
                                            {stateOptionsJSX}
                                        </select>
                                    </div>
                                )
                            }
                            {
                                readOnly ? (
                                    <div className="col-lg-4">
                                        <p className="close-distance"><small>Postal Code</small></p>
                                        <p className="lead">{inputsValues.postalCode || 'Not set'}</p>
                                    </div>
                                ) : (
                                    <div className="col-lg-4">
                                        <label className="form-label">Postal Code</label>
                                        <input type="text" className="form-control" data-identifier="postalCode" onChange={e => handleInputChange(e)} value={inputsValues.postalCode || ''} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={`tab-pane fade show ${selectedTab === 'dashboards' ? 'active' : ''}`} id="dashboards-tab-pane" role="tabpanel" aria-labelledby="dashboards-tab" tabIndex="0">
                        <div className="row mt-3">
                            <div className="col-3 underline"><u>Dashboard { !readOnly && <span className="mandatory">*</span> }</u></div>
                            <div className="col-4 underline"><u>Default { !readOnly && <span className="mandatory">*</span> }</u></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 px-4 pt-3">
                                {
                                    !inputsValues?.userDashboardList?.length && readOnly ? 'None assigned' :
                                    dashboardList.map(({dashboardID, dashboardName}, index) => {
                                        const isSelected = inputsValues?.userDashboardList?.find(permissionObj => permissionObj.dashboardID === dashboardID);
                                        if(!readOnly || isSelected) return (
                                            <div className="row" key={index}>
                                                <label  className="list-group-item d-flex gap-2 mb-2">
                                                    {!readOnly && <input className="form-check-input flex-shrink-0" data-identifier='userDashboardList' onChange={e => handleInputChange(e)}  type="checkbox" value={dashboardID} checked={isSelected ? true : false} data-format="number" />}
                                                    <span>{dashboardName}</span>
                                                </label>
                                            </div>
                                        );
                                        else return '';
                                    })
                                }
                            </div>
                            <div className="col-lg-6 pt-3">
                                {/* <label  className="list-group-item d-flex gap-2">Default Dashboard</label> */}
                                {
                                    readOnly ? dashboardList.find(obj => obj.dashboardID === inputsValues.dashboardID)?.dashboardName || 'None' : (
                                        <select className="form-control" value={inputsValues.dashboardID} data-identifier='dashboardID' onChange={e => handleInputChange(e)} data-format="number">
                                            {
                                                inputsValues.userDashboardList?.map(({dashboardID}, index) => {
                                                    const dashboardName = dashboardList.find(obj => obj.dashboardID === dashboardID).dashboardName
                                                    return (
                                                        <option key={index} value={dashboardID}>{dashboardName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                    <div className={`tab-pane fade show ${selectedTab === 'permissions' ? 'active' : ''}`} id="permissions-tab-pane" role="tabpanel" aria-labelledby="permissions-tab" tabIndex="0">
                        <div className="row ">
                            <div className="col-lg-6 px-5 pt-3">
                                {
                                    !inputsValues?.userPermissionList?.length && readOnly ? 'None assigned' :
                                    permissionList.map(({permissionID, permissionName}, index) => {
                                        const isSelected = inputsValues?.userPermissionList?.find(permissionObj => permissionObj.permissionID === permissionID);
                                        if(!readOnly || isSelected) return (
                                            <div className="row mt-2" key={index}>
                                                <label className="list-group-item d-flex gap-2">
                                                    {!readOnly && <input className="form-check-input flex-shrink-0" data-identifier='userPermissionList' onChange={e => handleInputChange(e)}  type="checkbox" value={permissionID} checked={isSelected ? true : false} />}
                                                    <span>{permissionName}</span>
                                                </label>
                                            </div>
                                        );
                                        else return '';
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        // </div>
    )
};

export default UseForm;
