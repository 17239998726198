import { useEffect, useState } from "react";


const Pagination = ({currentPage, SetCurrentPage, resultsPerPage, setResultsPerPage, RESULTS_PER_PAGE_OPTIONS, totalRowCount}) => {

    const [ maxPages, SetMaxPages ] = useState(1);

    useEffect(() => {
        if(!totalRowCount || !resultsPerPage) return;
        SetMaxPages(Math.ceil(totalRowCount / resultsPerPage));
    }, [totalRowCount, resultsPerPage])

    
    return (
        <nav aria-label="Page navigation" className='pagination__wrapper'>
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={e => currentPage === 1 ? null : SetCurrentPage(current => --current)}>
                    <button className="page-link user-select--none">Previous</button>
                </li>
                {
                    Array.from({length: maxPages}).map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}><button className="page-link user-select--none" onClick={e => SetCurrentPage(index + 1)}>{index + 1}</button></li>
                    ))
                }
                <li className={`page-item ${currentPage === maxPages ? 'disabled' : ''}`} onClick={e => currentPage === maxPages ? null : SetCurrentPage(current => ++current)}>
                    <button className="page-link user-select--none">Next</button>
                </li>
            </ul>
            <div className="col-lg-2" style={{alignSelf: 'self-end'}}>
                <select id="results-per-page" className="form-select" aria-label="Results per page" value={resultsPerPage} onChange={e => setResultsPerPage(Number(e.currentTarget.value))}>
                    {
                        RESULTS_PER_PAGE_OPTIONS.map((number, index) => <option key={index} value={number}>{number} results per page</option>)
                    }
                </select>
            </div>
        </nav>
    )
};

export default Pagination;
