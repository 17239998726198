import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import UserRequests from "api/UserRequests";

const ResetPassword = () => {

    const MIN_CHARACTERS = 8;

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [ verificationCheck, SetVerificationCheck ] = useState({
        lengthCheck: false,
        capitalLetterCheck: false,
        numberCheck: false,
        specialCharacterCheck: false,
        matchingPasswordCheck: false,
    });

    const { resetPassword } = UserRequests();

    const [ newPassword, SetNewPassword ] = useState('');
    const [ confirmationPassword, SetConfirmPassword ] = useState('');
    const [ errorMessage, SetErrorMessage ] = useState('');
    const [ loading, SetLoading ] = useState(false);

    const warningIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffda6a" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
        </svg>
    );

    const checkIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
        </svg>
    );

    const disableAcceptButton = !(
        verificationCheck.lengthCheck &&
        verificationCheck.capitalLetterCheck &&
        verificationCheck.numberCheck &&
        verificationCheck.specialCharacterCheck &&
        verificationCheck.matchingPasswordCheck
    )

    const handleSubmit = async () => {
        try {
            const data = {
                password: newPassword,
                confirmPassword: confirmationPassword,
                token: token,
            }
            SetLoading(true);
            await resetPassword(data, 'Password updated correctly', 'There was an error while updating the password');
            SetLoading(false);
            navigate('/#');
        } catch (err) {
            console.error(err);
            SetLoading(false);
            SetErrorMessage('Error');
        }
    }

    useEffect(() => {
        if(newPassword.length >= MIN_CHARACTERS) SetVerificationCheck(current => ({...current, lengthCheck: true}));
        else SetVerificationCheck(current => ({...current, lengthCheck: false}));

        if(newPassword.split('').some(letter => letter === letter.toUpperCase() && isNaN(letter))) SetVerificationCheck(current => ({...current, capitalLetterCheck: true}));
        else SetVerificationCheck(current => ({...current, capitalLetterCheck: false}));

        if(hasNumber(newPassword)) SetVerificationCheck(current => ({...current, numberCheck: true}));
        else SetVerificationCheck(current => ({...current, numberCheck: false}));

        if(hasSpecialCharacter(newPassword)) SetVerificationCheck(current => ({...current, specialCharacterCheck: true}));
        else SetVerificationCheck(current => ({...current, specialCharacterCheck: false}));

        if(newPassword === confirmationPassword && newPassword !== '') SetVerificationCheck(current => ({...current, matchingPasswordCheck: true}));
        else SetVerificationCheck(current => ({...current, matchingPasswordCheck: false}));

        function hasNumber(str) {
            return /\d/.test(str)
        };
        function hasSpecialCharacter(str) {
            // Regular expression to match any character that is not alphanumeric or whitespace
            const regex = /[^\w\s]/;

            // Regular expression to match any character that is not an alphabetical letter or whitespace
            // const regex = /[^a-zA-Z\s]/;
        
            // Test if the string contains any special character
            return regex.test(str);
        }
    }, [newPassword, confirmationPassword]);
    
    return (
        <>
            <div className="container mt-5">
                
                <div className="p-3 bg-body-tertiary rounded-3">
                    {
                        !token ? <h2 className="text-danger-emphasis mt-3">Bad link!</h2>
                        : (
                            <>
                                <h2 className="mb-3">Reset password</h2>
                                <div className="wrapper" style={{minHeight: '168px', display: 'flex', alignItems: 'center'}}>
                                    {
                                        !disableAcceptButton ? (
                                            <div className="alert alert-success" role="alert" style={{flexBasis: '100%'}}>
                                                Password passes all filters
                                            </div>
                                        ) : (
                                            <div>
                                                <p className="mb-2">Must contain at least</p>
                                                <ul>
                                                    <li>{verificationCheck.lengthCheck ?  checkIcon : warningIcon} {MIN_CHARACTERS} characters</li>
                                                    <li>{verificationCheck.capitalLetterCheck ?  checkIcon : warningIcon} 1 capital letter</li>
                                                    <li>{verificationCheck.numberCheck ?  checkIcon : warningIcon} 1 number</li>
                                                    <li>{verificationCheck.specialCharacterCheck ?  checkIcon : warningIcon} 1 special character</li>
                                                    <li>{verificationCheck.matchingPasswordCheck ?  checkIcon : warningIcon} Password must match</li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                </div>
                                
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label">New password<span className="mandatory">*</span></label>
                                        <input type="password" value={newPassword} onKeyDown={e => e.key === 'Enter' ? handleSubmit() : null} onChange={e => SetNewPassword(e.currentTarget.value)} className="form-control" />
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label">Retype password<span className="mandatory">*</span></label>
                                        <input type="password" value={confirmationPassword} onKeyDown={e => e.key === 'Enter' ? handleSubmit() : null} onChange={e => SetConfirmPassword(e.currentTarget.value)} className="form-control" />
                                    </div>
                                </div>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">{errorMessage}</div>
                                )}
                                <div className="row mt-3">
                                    <div className="col-lg-2 d-grid">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={disableAcceptButton || loading}>Accept</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                    
            </div>
        </>
    )
};
export default ResetPassword;
