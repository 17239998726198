import useFormattingFunctions from "components/useFormattingFunctions/useFormattingFunctions";

const SubjectInfo = ({selectedCase}) => {

    const { formatDate } = useFormattingFunctions();
    
    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            {/* <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Related Case Number</label>
                    <input type="number" className="form-control no-spin-button" id="related-case-number"/>
                </div>
                <div className="col-lg-4 mb-3" style={{alignSelf: 'self-end'}}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="fib" id="flexCheckChecked" defaultChecked />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            Unrelated Subject
                        </label>
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-lg-4">
                    {/* <label className="form-label">First name</label>
                    <input type="text" className="form-control" id="first-name"/> */}
                    <small>First name</small>
                    <p className="lead">{selectedCase.subjectFirstName || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Last name</label>
                    <input type="text" className="form-control" id="last-name"/> */}
                    <small>First name</small>
                    <p className="lead">{selectedCase.subjectLastName || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">DOB</label>
                    <input type="date" className="form-control" id="dob"/> */}
                    <small>DOB</small>
                    <p className="lead">{selectedCase.dob ? formatDate(selectedCase.dob, 'date-written-out') : ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">SSN</label>
                    <input type="number" className="form-control no-spin-button" id="ssn"/> */}
                    <small>SSN</small>
                    <p className="lead">{selectedCase.ssn || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Occupation</label>
                    <input type="text" className="form-control" id="occupation"/> */}
                    <small>Occupation</small>
                    <p className="lead">{selectedCase.occupation || ''}</p>
                </div>
            </div>

            <hr className="mb-4" />

            <div className="row">
                <div className="col-lg-4">
                    {/* <label className="form-label">License Number</label>
                    <input type="number" className="form-control no-spin-button" id="license-number"/> */}
                    <small>License number</small>
                    <p className="lead">{selectedCase.driverLicenseNumber || ''}</p>
                </div>
                <div className="col-lg-4">
                    <small>License expires</small>
                    <p className="lead">{selectedCase.driverLicenseExpires ? formatDate(selectedCase.driverLicenseExpires, 'date-written-out') : ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label htmlFor="client" className="form-label">License State</label>
                    <select className="form-select" id="client">
                        <option value="">Select one</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>License state</small>
                    <p className="lead">{selectedCase.driverLicenseState || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label htmlFor="client" className="form-label">License Status</label>
                    <select className="form-select" id="client">
                        <option value="">Select one</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>License state</small>
                    <p className="lead">{selectedCase.driverLicenseStatus || ''}</p>
                </div>
                <div className="col-lg-4">
                    <small>License type</small>
                    <p className="lead">{selectedCase.driverLicenseType || ''}</p>
                </div>
            </div>
            
            <div className="row">
                <div className="col-lg-8">
                    {/* <label className="form-label">Injury</label>
                    <textarea className="form-control" rows="2"></textarea> */}
                    <small>Injury</small>
                    <p className="lead">{selectedCase.injury || ''}</p>
                </div>
            </div>
        </div>
    )
}

export default SubjectInfo;