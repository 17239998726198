import TextEditor from "components/textEditor/TextEditor";
import { useEffect, useState } from "react";

import TemplateRequests from "api/TemplateRequests";

const CaseInstructions = ({caseInstructions, SetCaseInstructions}) => {

    const { getTemplateList, getTemplateTypeList } = TemplateRequests();

    const [ templateTypeList, SetTemplateTypeList ] = useState([]);
    const [ selectedTemplateTypeId, SetSelectedTemplateTypeId ] = useState();

    const [ templateList, SetTemplateList ] = useState([]);
    const [ selectedTemplateId, SetSelectedTemplateId ] = useState();

    useEffect(() => {
        SetSelectedTemplateId('');
    }, [selectedTemplateTypeId])

    useEffect(() => {

        if(!selectedTemplateId) return;

        const selectedTemplate = templateList.find(template => template.templateID === selectedTemplateId);

        SetCaseInstructions(selectedTemplate.templateBody);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplateId, templateList])

    useEffect(() => {
        const loadTemplateList = async () => {
            try {
                const response = await getTemplateList();
                if(!response.length) {
                    console.error('No template list found');
                    return;
                };
                SetTemplateList(response);
            } catch (err) {
                console.error(err)
            }
        }
        const loadTemplateTypeList = async () => {
            try {
                const response = await getTemplateTypeList();
                if(!response.length) {
                    console.error('No template list found');
                    return;
                };
                SetTemplateTypeList(response);
            } catch (err) {
                console.error(err)
            }
        }

        loadTemplateList();
        loadTemplateTypeList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="row mb-3">
                <div className={`col-lg-4`}>
                    <label className="form-label">Template type</label>
                    <select value={selectedTemplateTypeId} className="form-select" id="client" onChange={e => SetSelectedTemplateTypeId(Number(e.currentTarget.value))}>
                        <option value=''>No template</option>
                        {templateTypeList.map((data, index) => (
                            <option key={index} value={data.templateTypeID}>{data.templateTypeName}</option>
                        ))}
                    </select>
                </div>
                <div className={`col-lg-4`}>
                    <label className="form-label">Template</label>
                    <select value={selectedTemplateId} className="form-select" id="client" onChange={e => SetSelectedTemplateId(Number(e.currentTarget.value))}>
                        <option value=''>No template</option>
                        {templateList.map((data, index) => (
                            <option key={index} value={data.templateID}>{data.templateName}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 mb-3">
                    <label className="form-label">Instructions</label>
                    <TextEditor value={caseInstructions} setValue={SetCaseInstructions} />
                </div>
            </div>
        </>
    )
};

export default CaseInstructions;
