import { useGlobals } from 'context/globals-context';
import { useState, useEffect, useRef } from 'react';
import RequesterRequests from 'api/RequesterRequests';
import ClientRequests from "api/ClientRequests";

import Table from 'components/table/Table';
import RequesterModal from './requesterModal/RequesterModal';
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';
import NewRequesterModal from './newRequesterModal/NewRequesterModal';

const RequesterList = () => {

    const { jwt, redirect, showToast } = useGlobals();
    const { getRequesterList } = RequesterRequests();
    const { getClientList } = ClientRequests();

    const [ requesterList, SetRequesterList ] = useState([]);
    // const [ selectedRoleId, SetSelectedRoleId] = useState(1);
    const [ loading, SetLoading ] = useState(true);
    const [ selectedRequester, SetSelectedRequester ] = useState();
    const [ clientFilter, SetClientFilter ] = useState('');
    const [ clientList, SetClientList ] = useState([]);
    const [ selectedClientId, SetSelectedClientID ] = useState('');
    const [ inputsValues, SetInputsValues] = useState({});

    const modal_ref = useRef();
    const modalClass_ref = useRef();
    const newRequesterModal_ref = useRef();
    const newRequesterModalClass_ref = useRef();
    
    const tableStructure = [
        {
            header: 'Name',
            propertyNames: ['firstName', 'lastName'],
        },
        {
            header: 'Client name',
            propertyNames: ['clientName'],
        },
        {
            header: 'e-mail',
            propertyName: 'email',
        },
        {
            header: 'Address',
            propertyName: 'address1',
        },
        {
            header: 'Phone',
            propertyName: 'phone',
        },
    ]
    const handleUserClick = (e) => {
        const selectedUserId = e.currentTarget.dataset.rowId;
        SetSelectedRequester(requesterList.find(currentUser => currentUser.userID ===  selectedUserId));
        modalClass_ref.current.show()
    };

    const handleUpdatedUser = (newFields) => {
        const userId = newFields.userID;
        SetRequesterList(currentUsersList => {
            const index = currentUsersList.findIndex(current => current.userID === userId);
            currentUsersList[index] = {...currentUsersList[index], ...newFields};
            return [...currentUsersList]
        })
    }

    useEffect(() => {
        
        const loadRequesters = async () => {
            try {
                const response = await getRequesterList(clientFilter, '');
                console.log(response)
                SetRequesterList(response);
                SetLoading(false);
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'There was an error loading the user list'
                })
                SetLoading(false);
            }
        }

        loadRequesters();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientFilter])

    useEffect(() => {
        if(!jwt) return redirect();
        modalClass_ref.current = new bootstrapBundleMin.Modal(modal_ref.current, {
            keyboard: false
        });
        newRequesterModalClass_ref.current = new bootstrapBundleMin.Modal(newRequesterModal_ref.current, {
            keyboard: false
        });

        const loadClientList = async () => {
            try {
                const response = await getClientList();
                console.log(response)
                SetClientList(response)
                if(!response.length) throw new Error('No clients retrieved from server');
                SetSelectedClientID(response[0].clientID);
                SetLoading(false);
            } catch (err) {
                SetLoading(false);
                showToast({
                    type: 'danger',
                    text: 'There was an error while loading the client\'s list'
                })
                console.error(err);
            }
        }

        loadClientList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="requester-list">
            <div className="container">
                <div className='row g-3' style={{justifyContent: 'space-between'}}>
                    <div className="col-lg-2" style={{alignSelf: 'self-end'}}>
                        <label className="form-label">Client</label>
                        <select className="form-select" aria-label="Default select example" value={clientFilter} onChange={e => SetClientFilter(e.currentTarget.value)} >
                            <option value="">All</option>
                            {
                                clientList.map((client, index) => <option key={index} value={client.clientID}>{client.clientName}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-lg-3" style={{justifyContent: 'flex-end', display: 'flex', alignSelf: 'self-end'}}>                        
                        <button className="btn btn-warning" data-bs-target="#newUserModalToggle" data-bs-toggle="modal" onClick={() => SetInputsValues({})}>New Requester</button>
                    </div>
                </div>
                
                <div className='row g-3'>
                    <div className="col-lg-12">
                        <Table loading={loading} tableStructure={tableStructure} data={requesterList} dataRowId={'userID'} onRowClick={handleUserClick} />
                    </div>
                </div>
            </div>

            <RequesterModal modal_ref={modal_ref} userData={selectedRequester} modalClass_ref={modalClass_ref} onUpdatedUser={handleUpdatedUser} selectedRequester={selectedRequester} />
            <NewRequesterModal
                newRequesterModal_ref={newRequesterModal_ref}
                newRequesterModalClass_ref={newRequesterModalClass_ref}
                setRequesterList={SetRequesterList}
                clientList={clientList}
                selectedClientId={selectedClientId}
                setSelectedClientID={SetSelectedClientID}
                inputsValues={inputsValues}
                setInputsValues={SetInputsValues}
            />
        </section>
    )
}

export default RequesterList;