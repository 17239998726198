import { useGlobals } from 'context/globals-context';
import { useState, useEffect, useRef, } from 'react';
import { useNavigate } from 'react-router-dom';

import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import ResourceTypeRequests from 'api/ResourceTypeRequests';
import CaseRequests from 'api/CaseRequests';
import UserRequests from 'api/UserRequests';

const useDashboardLogic = ({filtersList = {}}) => {

    const navigate = useNavigate();

    const { getCaseListPost } = CaseRequests();
    const { getResourceTypeList } = ResourceTypeRequests();
    const { getUserList } = UserRequests();


    const { jwt, redirect, showToast } = useGlobals();
    
    const [ caseList, SetCaseList ] = useState([]);
    const [ tableLoading, SetTableLoading ] = useState(true);
    const [ resourcesList, SetResourcesList ] = useState([]);
    const [ selectedResourceId, SetSelectedResourceId ] = useState('');
    const [ caseManagersList, SetCaseManagersList ] = useState([]);
    const [ selectedCaseManagerId, SetSelectedCaseManagerId ] = useState('');
    
    const [ caseStatusListFilter, SetCaseStatusListFilter ] = useState(JSON.parse(localStorage.getItem('filterByStatus')) || []);
    const [ statusFilterSelections, SetStatusFilterSelections ] = useState(JSON.parse(localStorage.getItem('filterByStatus')) || []);
    
    const [ flagsFilterArray, SetFlagsFilterArray ] = useState(JSON.parse(localStorage.getItem('filterByFlag')) || []);
    const [ flagsFilterSelections, SetFlagsFilterSelections ] = useState(JSON.parse(localStorage.getItem('filterByFlag')) || []);

    const [ serviceFilterArray, SetServiceFilterArray ] = useState(JSON.parse(localStorage.getItem('filterByService')) || []);
    const [ serviceFilterSelections, SetServiceFilterSelections ] = useState(JSON.parse(localStorage.getItem('filterByService')) || []);

    //Navigation states

    const RESULTS_PER_PAGE_OPTIONS = [ 10, 20, 40];
    const [ resultsPerPage, SetResultsPerPage ] = useState(RESULTS_PER_PAGE_OPTIONS[0]);
    const [ currentPage, SetCurrentPage ] = useState(1);
    const [ totalRowCount, SetTotalRowCount ] = useState(RESULTS_PER_PAGE_OPTIONS[0]);

    const serviceFilter_ref = useRef();
    const serviceFilterClass_ref = useRef();
    const flagFilter_ref = useRef();
    const flagFilterClass_ref = useRef();
    const caseStatusFilter_ref = useRef();
    const caseStatusFilterClass_ref = useRef();

    const handleCaseSelect = (e) => {
        navigate(`/case?id=${e.currentTarget.dataset.rowId}`);
    };

    const handleServiceFilter = (serviceID, checkedValue) => {
        SetServiceFilterSelections(current => {
            if(!checkedValue) {
                const index = current.findIndex(obj => obj.serviceID === serviceID);
                current.splice(index, 1);

                localStorage.setItem('filterByService', JSON.stringify([...current]));
                return [...current];
            };

            localStorage.setItem('filterByService', JSON.stringify([...current, {serviceID}]));
            return [...current, {serviceID}];
        });
    };

    const clearServiceFilter = () => {
        localStorage.removeItem('filterByService');
        SetServiceFilterArray([]);
        SetServiceFilterSelections([]);
    }


    const handleFlagFilter = (flagID, checkedValue) => {
        SetFlagsFilterSelections(current => {
            if(!checkedValue) {
                const index = current.findIndex(obj => obj.flagID === flagID);
                current.splice(index, 1);

                localStorage.setItem('filterByFlag', JSON.stringify([...current]));
                return [...current];
            };

            localStorage.setItem('filterByFlag', JSON.stringify([...current, {flagID}]));
            return [...current, {flagID}];
        });
    };

    const clearFlagsFilter = () => {
        localStorage.removeItem('filterByFlag');
        SetFlagsFilterArray([]);
        SetFlagsFilterSelections([]);
    }

    const handleStatusFilter = (caseStatusID, checkedValue) => {
        SetStatusFilterSelections(current => {
            if(!checkedValue) {
                const index = current.findIndex(obj => obj.caseStatusID === caseStatusID);
                current.splice(index, 1);
                
                localStorage.setItem('filterByStatus', JSON.stringify([...current]));
                return [...current];
            };

            localStorage.setItem('filterByStatus', JSON.stringify([...current, {caseStatusID}]));
            return [...current, {caseStatusID}];
        });
    };

    const clearStatusesFilter = () => {
        localStorage.removeItem('filterByStatus');
        SetCaseStatusListFilter([]);
        SetStatusFilterSelections([]);
    };

    useEffect(() => {
        if(!jwt) return redirect();
        (async () => {
            SetCaseList([]);
            SetTableLoading(true);
            try {
                const options = {}
                if(serviceFilterArray.length) options.caseServiceList = serviceFilterArray;
                if(flagsFilterArray.length) options.caseFlagList = flagsFilterArray;
                if(caseStatusListFilter.length) options.caseStatusList = caseStatusListFilter;
                if(selectedResourceId) options.resourceTypeID = selectedResourceId;
                if(selectedCaseManagerId) options.caseManagerID = selectedCaseManagerId;
                options.page = currentPage;
                options.pageSize = resultsPerPage;

                const response = await getCaseListPost(options);
                const totalRowCount = response[0].totalRowCount || 0;

                SetTotalRowCount(totalRowCount);
                SetCaseList(response);
                SetTableLoading(false);
            } catch (err) {
                SetTableLoading(false)
                showToast({
                    type: 'danger',
                    text: 'There was an error while loading the case list'
                })
                console.log(err.message)
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagsFilterArray, caseStatusListFilter, selectedResourceId, selectedCaseManagerId, serviceFilterArray, resultsPerPage, currentPage]);

    useEffect(() => {

        const loadResourceTypeList = async () => {
            try {
                const response = await getResourceTypeList();
                SetResourcesList(response);
            } catch (err) {
                console.error(err);
            }
        };

        const loadCaseManagers = async () => {
            try {
                const response = await getUserList([
                    {
                      "roleID": 2
                    }
                  ]);
                SetCaseManagersList(response);
            } catch (err) {
                console.error(err);
            }
        };

        if(filtersList.caseManagers) loadCaseManagers();
        if(filtersList.resourceType) loadResourceTypeList();

        if(filtersList.services) serviceFilterClass_ref.current = new bootstrapBundleMin.Modal(serviceFilter_ref.current, {
            // keyboard: false
        });
        if(filtersList.flags) flagFilterClass_ref.current = new bootstrapBundleMin.Modal(flagFilter_ref.current, {
            // keyboard: false
        });
        if(filtersList.statuses) caseStatusFilterClass_ref.current = new bootstrapBundleMin.Modal(caseStatusFilter_ref.current, {
            // keyboard: false
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        caseList,
        tableLoading,
        resourcesList,
        selectedResourceId,
        SetSelectedResourceId,
        caseManagersList,
        selectedCaseManagerId,
        SetSelectedCaseManagerId,
        caseStatusListFilter,
        statusFilterSelections,
        SetStatusFilterSelections,
        flagsFilterArray,
        flagsFilterSelections,
        SetFlagsFilterSelections,
        serviceFilterArray,
        serviceFilterSelections,
        SetServiceFilterSelections,
        resultsPerPage,
        SetResultsPerPage,
        RESULTS_PER_PAGE_OPTIONS,
        currentPage,
        SetCurrentPage,
        serviceFilter_ref,
        flagFilter_ref,
        caseStatusFilter_ref,
        serviceFilterClass_ref,
        flagFilterClass_ref,
        caseStatusFilterClass_ref,
        handleCaseSelect,
        handleServiceFilter,
        clearServiceFilter,
        handleFlagFilter,
        clearFlagsFilter,
        handleStatusFilter,
        clearStatusesFilter,
        SetServiceFilterArray,
        SetCaseStatusListFilter,
        SetFlagsFilterArray,
        totalRowCount,
    }
};

export default useDashboardLogic;