import { useState, useEffect } from "react";

import useFormattingFunctions from "components/useFormattingFunctions/useFormattingFunctions";
import CaseRequests from "api/CaseRequests";

import Table from "components/table/Table";

const Resources = ({caseMasterID, selectedTab, resourcesList, setResourcesList}) => {

    const TABLE_STRUCTURE = [
        {
            header: 'Date',
            propertyName: 'createdDateTime',
            formatting: 'formatDate',
            formattingProps: 'date-written-out'
        },
        {
            header: 'Description',
            propertyName: 'caseActivityDescription',
        },
    ]

    const { getCaseResourceList } = CaseRequests();
    const { sortByDate } = useFormattingFunctions();

    const [ loading, SetLoading ] = useState(true);
    

    useEffect(() => {
        if(selectedTab !== 'resources') return;

        const getData = async () => {
            try {
                const response = await getCaseResourceList(caseMasterID);
                const sortedList = sortByDate(response, 'createdDateTime');
                SetLoading(false);
                // response.forEach(obj => obj.createdDateTime = formatDate(obj.createdDateTime, 'include-time'));
                setResourcesList(sortedList);

                
            } catch (err) {
                console.log(err.message)
                SetLoading(false);
            }
        }
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, caseMasterID])

    return (
        <div className="media overflow-y-scroll h-500">
            <Table tableStructure={TABLE_STRUCTURE} data={resourcesList} loading={loading} />
        </div>
    )
}

export default Resources;