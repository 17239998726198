import { useState, useEffect } from "react";

import Table from "components/table/Table";
import useFormattingFunctions from "components/useFormattingFunctions/useFormattingFunctions";

import CaseRequests from "api/CaseRequests";

const CaseActivity = ({caseMasterID, selectedTab}) => {

    const TABLE_STRUCTURE = [
        {
            header: 'Date',
            propertyName: 'createdDateTime',
        },
        {
            header: 'Description',
            propertyName: 'caseActivityDescription',
        },
    ]

    const { getCaseActivityList } = CaseRequests();
    const { formatDate } = useFormattingFunctions();

    const [ loading, SetLoading ] = useState(true);
    const [ caseMediaList, SetCaseMediaList ] = useState([]);

    useEffect(() => {
        if(selectedTab !== 'case-activity') return;

        const getData = async () => {
            try {
                const response = await getCaseActivityList(caseMasterID);
                SetLoading(false);
                response.forEach(obj => obj.createdDateTime = formatDate(obj.createdDateTime, 'include-time'));
                SetCaseMediaList(response);
            } catch (err) {
                console.log(err.message)
                SetLoading(false);
            }
        }
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, caseMasterID])

    return (
        <div className="media overflow-y-scroll h-500">
            <Table tableStructure={TABLE_STRUCTURE} data={caseMediaList} loading={loading} dataRowId='caseReportVideoLink' />
        </div>
    )
}

export default CaseActivity;