const ContactInfo = () => {

    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            <div className="row">
                <div className="col-lg-8 mb-3">
                    <label className="form-label">Employer info</label>
                    <textarea className="form-control" rows="4"></textarea>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <label className="form-label">Insured info</label>
                    <textarea className="form-control" rows="4"></textarea>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;