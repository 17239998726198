import { useEffect, useRef, useState } from "react";
import { useGlobals } from "context/globals-context";

import UserRequests from "api/UserRequests";

import UseForm from "../form/UseForm";

const NewUserModal = ({newUserModalRef, newUserModalClassRef, setUsersList, roleList, dashboardList, permissionList}) => {

    const { showToast } = useGlobals();
    const { addUser } = UserRequests();

    const [ inputsValues, SetInputsValues] = useState({});
    const [ errorMessage, SetErrorMessage ] = useState();
    const [ loading, SetLoading ] = useState(false); 

    const retypedPasswordRef = useRef();

    const checkMandatoryFields = e => {
        e.preventDefault();

        const userInfoMandatoryFields = [
            'firstName',
            'lastName',
            'roleID',
            'dashboardID',
            'email'
        ]

        if(!userInfoMandatoryFields.every(mandatoryField => inputsValues[mandatoryField] || inputsValues[mandatoryField] === 0)) {
            return SetErrorMessage('Please fill all mandatory fields (*)');   
        }

        if(!inputsValues?.userDashboardList?.length) {
            return SetErrorMessage('User dashboard required');
        }

        if(!inputsValues?.userPermissionList?.length) {
            return SetErrorMessage('User permission required');
        };

        handleSubmit();
    }

    const handleSubmit = async (e) => {
        
        try {
            SetLoading(true);
            const response = await addUser(inputsValues);
            setUsersList(current => [response, ...current]);
            newUserModalClassRef.current.hide();
            SetInputsValues({});
            SetLoading(false);

            showToast({
                type: 'success',
                text: 'New user added successfully'
            });
        } catch (err) {
            SetLoading(false);
            console.error(err.message)
            if(err.message.includes('is already in use')) {
                const json = JSON.parse(err.message);
                return showToast({
                    type: 'danger',
                    text: json.message
                });
            }
            showToast({
                type: 'danger',
                text: 'There was a problem while adding the new user'
            });
        }
    };

    useEffect(() => SetErrorMessage(''), [inputsValues])

    return (
        <div className="new-user-modal">
            <div className="modal fade" id="newUserModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={newUserModalRef}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Invite new user</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <UseForm
                            inputsValues={inputsValues}
                            setInputsValues={SetInputsValues}
                            roleList={roleList}
                            dashboardList={dashboardList}
                            permissionList={permissionList}
                            retypedPasswordRef={retypedPasswordRef}
                            errorMessage={errorMessage}
                            loading={loading}
                        />
                        <div className="modal-footer">
                            {
                                errorMessage ? (
                                    <p className="link-danger"><strong>{errorMessage}</strong></p>
                                ) : ''
                            }
                            {
                                loading && (
                                    <div className="spinner--container">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )
                            }
                            <button className="btn btn-secondary" data-bs-target="#newUserModalToggle" data-bs-toggle="modal" onClick={e => e.preventDefault()} disabled={loading}>Cancel</button>
                            <button className="btn btn-success" type="submit" disabled={loading} onClick={e => checkMandatoryFields(e)}>Accept</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewUserModal;