// const SERVER_ADDRESS = process.env.NODE_ENV === 'development' ? 'https://localhost:7264' : 'https://investigate.guru';
const SERVER_ADDRESS = 'https://investigate.guru';

const userEndpoints = {
    login: `${SERVER_ADDRESS}/user/authenticate`,
    refreshToken: `${SERVER_ADDRESS}/user/refresh-token`,
    getUserList: `${SERVER_ADDRESS}/user/getuserlist`,
    updateUser: `${SERVER_ADDRESS}/user/updateuser`,
    getRoleList: `${SERVER_ADDRESS}/user/getrolelist`,
    addUser: `${SERVER_ADDRESS}/user/adduser`,
    getPermissionList: `${SERVER_ADDRESS}/user/getpermissionlist`,
    resetPassword: `${SERVER_ADDRESS}/user/reset-password`,
    forgotPassword: `${SERVER_ADDRESS}/user/forgot-password`,
    getResourceList: `${SERVER_ADDRESS}/user/getresourcelist`
};

const lineOfInsuranceEndPoints = {
    getLineOfInsuranceList: `${SERVER_ADDRESS}/lineofinsurance/getlineofinsurancelist`,
}
const flagEndPoints = {
    getFlagList: `${SERVER_ADDRESS}/flag/getflaglist`,
}

const caseListEndpoints = {
    newCase: `${SERVER_ADDRESS}/case/newcase`,
    getCaseList: `${SERVER_ADDRESS}/case/getcaselist`,
    getMediaList: `${SERVER_ADDRESS}/case/getcasemedialist`,
    getCaseActivityList: `${SERVER_ADDRESS}/case/getcaseactivitylist`,
    getCase: `${SERVER_ADDRESS}/case`,
    getCaseStatusList: `${SERVER_ADDRESS}/case/getcasestatuslist`,
    updateCaseStatus: `${SERVER_ADDRESS}/case/updatecasestatus`,
    addCaseResource: `${SERVER_ADDRESS}/case/addcaseresource`,
    getCaseResourceList: `${SERVER_ADDRESS}/case/getcaseresourcelist`,
    getCaseAddressTypeList: `${SERVER_ADDRESS}/case/getcaseaddresstypelist`,
    uploadCaseMedia: `${SERVER_ADDRESS}/case/uploadcasemedia`,
    downloadCaseMedia: `${SERVER_ADDRESS}/case/downloadcasemedia`,
};

const resourceTypeEndpoints = {
    getResourceTypeList: `${SERVER_ADDRESS}/resourcetype/getresourcetypelist`,
}

const templateEndpoints = {
    getTemplateList: `${SERVER_ADDRESS}/template/gettemplatelist`,
    getTemplateTypeList: `${SERVER_ADDRESS}/template/gettemplatetypelist`,
    addTemplate: `${SERVER_ADDRESS}/template/addtemplate`,
    updateTemplate: `${SERVER_ADDRESS}/template/updatetemplate`,
}

const clientEndpoints = {
    addClient: `${SERVER_ADDRESS}/client/addclient`,
    getClientList: `${SERVER_ADDRESS}/client/getclientlist`,
    updateClient: `${SERVER_ADDRESS}/client/updateclient`,
}

const requesterEndpoints = {
    addRequester: `${SERVER_ADDRESS}/requester/addrequester`,
    getRequesterList: `${SERVER_ADDRESS}/requester/getrequesterlist`
}

const insuredEndpoints = {
    addInsured: `${SERVER_ADDRESS}/insured/addinsured`,
    getInsuredList: `${SERVER_ADDRESS}/insured/getinsuredlist`
}

const carrierEndpoints = {
    addCarrier: `${SERVER_ADDRESS}/carrier/addcarrier`,
    getCarrierList: `${SERVER_ADDRESS}/carrier/getcarrierlist`
}

const billToEndpoints = {
    addBillTo: `${SERVER_ADDRESS}/billto/addbillto`,
    getBillToList: `${SERVER_ADDRESS}/billto/getbilltolist`
}

const adjusterEndpoints = {
    addAdjuster: `${SERVER_ADDRESS}/adjuster/addAdjuster`,
    getAdjusterList: `${SERVER_ADDRESS}/adjuster/getadjusterlist`
}

const officeEndpoints = {
    getOfficeList: `${SERVER_ADDRESS}/office/getofficelist`,
}

const servicesEndpoints = {
    getServicesList: `${SERVER_ADDRESS}/service`,
    getServicesTypesList: `${SERVER_ADDRESS}/service/getservicetypelist`
}

const subjectAddress = {
    getSubjectAddressTypeList: `${SERVER_ADDRESS}/subjectaddress/getsubjectaddresstypelist`
}

const dashboardEndpoints = {
    getDashboardList: `${SERVER_ADDRESS}/dashboard/getdashboardlist`,

}

const exports = {
    SERVER_ADDRESS,
    userEndpoints,
    caseListEndpoints,
    clientEndpoints,
    requesterEndpoints,
    insuredEndpoints,
    carrierEndpoints,
    billToEndpoints,
    adjusterEndpoints,
    officeEndpoints,
    servicesEndpoints,
    subjectAddress,
    lineOfInsuranceEndPoints,
    flagEndPoints,
    resourceTypeEndpoints,
    templateEndpoints,
    dashboardEndpoints,
    
}

export default exports;