import { useGlobals } from "context/globals-context";

const FlagRequests = () => {

    const { flagEndPoints, jwt, fetchData } = useGlobals();

    const getFlagList = async () => {
        try {

            const data = await fetchData(flagEndPoints.getFlagList, {
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                }
            });

            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }
    
    return {
        getFlagList,
    }
}

export default FlagRequests;