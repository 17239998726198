import { useState, useEffect, useRef } from 'react';
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import { useGlobals } from 'context/globals-context';

import TemplateRequests from 'api/TemplateRequests';

import Table from 'components/table/Table';
import TemplateModal from './components/templateModal';

const TemplateList = () => {

    const { getTemplateList, getTemplateTypeList } = TemplateRequests();

    const { jwt, redirect } = useGlobals();

    const [ templatesList, SetTemplatesList ] = useState([]);
    const [ templatesTypeList, SetTemplatesTypeList ] = useState([]);
    const [ selectedTemplate, SetSelectedTemplate ] = useState();
    
    const [ loading, SetLoading ] = useState(true);

    const modal_ref = useRef();
    const bootstrapModalClass_ref = useRef();
    
    const tableStructure = [
        {
            header: 'Template name',
            propertyName: 'templateName',
        },
        {
            header: 'Subject',
            propertyName: 'templateSubject',
        },
        {
            header: 'Content',
            propertyName: 'templateBody',
        },
    ];

    const handleUserClick = (e) => {
        const selectedTemplateId = Number(e.currentTarget.dataset.rowId);
        SetSelectedTemplate(templatesList.find(currentUser => currentUser.templateID === selectedTemplateId));
        bootstrapModalClass_ref.current.show();
    };

    useEffect(() => {
        if(!jwt) return redirect();

        const loadTemplateList = async () => {
            try {
                SetLoading(true);
                const response = await getTemplateList();
                SetLoading(false);
                SetTemplatesList(response);
            } catch (err) {
                SetLoading(false);
                console.error(err);
            }
        };
        const loadTemplatesTypeList = async () => {
            try {
                // SetLoading(true);
                const response = await getTemplateTypeList();
                // SetLoading(false);
                SetTemplatesTypeList(response);
            } catch (err) {
                // SetLoading(false);
                console.error(err);
            }
        };

        loadTemplatesTypeList();
        loadTemplateList();

        bootstrapModalClass_ref.current = new bootstrapBundleMin.Modal(modal_ref.current, {
            keyboard: false
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="requester-list">
            <div className="container">
                <div className='row g-3' style={{justifyContent: 'flex-end'}}>
                    <div className="col-lg-3" style={{justifyContent: 'flex-end', display: 'flex'}}>                        
                        <button className="btn btn-warning" data-bs-target="#newClientModalToggle" data-bs-toggle="modal" onClick={() => {
                            SetSelectedTemplate(null);
                            bootstrapModalClass_ref.current.show();
                        }}>New template</button>
                    </div>
                </div>
                
                <div className='row g-3'>
                    <div className="col-lg-12">
                        <Table loading={loading} tableStructure={tableStructure} data={templatesList} dataRowId={'templateID'} onRowClick={handleUserClick} />
                    </div>
                </div>
            </div>

            <TemplateModal
                bootstrapModalClass_ref={bootstrapModalClass_ref}
                modal_ref={modal_ref}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={SetSelectedTemplate}
                setTemplatesList={SetTemplatesList}
                templatesTypeList={templatesTypeList}
            />
        </section>
    )
}

export default TemplateList;