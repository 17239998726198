const useValidationFunctions = () => {
    function isValidJSON(text) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }
    
    return {
        isValidJSON
    }
};

export default useValidationFunctions;
