import { useEffect, useRef, useState } from "react";

const VideoModal = ({modalRef, videoData}) => {

    const { caseMediaName } = videoData;

    const [ videoSrc, SetVideoSrc ] = useState();

    const videoRef = useRef();

    const generateVideoLink = (caseReportVideoLink) => {
        const jwt = JSON.parse(localStorage.getItem('storedJWT') || sessionStorage.getItem('storedJWT'));
        const link = `${caseReportVideoLink}&jwt=${jwt}`;
        return link
    }

    useEffect(() => {
        if(!videoData.caseReportVideoLink) return;
        SetVideoSrc(generateVideoLink(videoData.caseReportVideoLink));

    }, [videoData]);

    useEffect(() => {
        if(!videoSrc) return;
        videoRef.current.load();
    }, [videoSrc])

    return (
        <div className="user-modal">
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalToggleLabel">{caseMediaName}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body center-elements">
                        <video controls ref={videoRef} className="medium">
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoModal;