import { useEffect, useState } from "react";
import { useGlobals } from "context/globals-context";
import FlagRequests from "api/FlagRequests";

const FlagsFilterModal = ({modal, flagsFilterSelections, setFlagsFilterArray, handleInputChange, clearFlagsFilter}) => {

    const { showToast } = useGlobals();

    const { getFlagList } = FlagRequests();

    const [ flagList, SetFlagList ] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {

            // const response = await addUser(inputsValues);
            // console.log(response)
        } catch (err) {
            showToast({
                type: 'danger',
                text: 'There was an error while loading the flag list'
            });
            console.error(err)
        }
    }

    useEffect(() => {
        const loadFlagList = async () => {
            const response = await getFlagList();
            SetFlagList(response);
        }

        loadFlagList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="new-user-modal">
            <div className="modal fade" id="filterByFlagModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modal}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Filter by flags</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex no-shrink mb-3">
                                <div className="list-group wrap-elements">
                                    {
                                        flagList.map(({flagID, flagName}, index) => (
                                            <label key={index} className="list-group-item d-flex gap-2 border small-border-radius">
                                                <input className="form-check-input flex-shrink-0" onChange={e => handleInputChange(flagID, e.currentTarget.checked)}  type="checkbox" value={flagID} checked={flagsFilterSelections.find(obj => obj.flagID === flagID) || false} />
                                                <span>{flagName}</span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                                    
                            <div className="modal-footer">
                                <button className="btn btn-secondary" data-bs-target="#filterByFlagModal" data-bs-toggle="modal" onClick={e => clearFlagsFilter()}>Deselect all</button>
                                <button className="btn btn-success" data-bs-target="#filterByFlagModal" type="submit" data-bs-toggle="modal" onClick={e => setFlagsFilterArray(flagsFilterSelections)}>Accept</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FlagsFilterModal;