import React from "react";

const Toast = ({options, toastRef}) => {

    return (
        <div className="toast-container p-3 bottom-0 end-0" id="toastPlacement" style={{position: 'fixed', bottom: '20px', right: '30px'}}>
            <div className={`toast align-items-center text-bg-${options.type} border-0`} role="alert" aria-live="assertive" aria-atomic="true" ref={toastRef}>
                <div className="d-flex">
                    <div className="toast-body">
                        {options.text}
                    </div>
                    <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    )
}

export default Toast;