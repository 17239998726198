const SubjectDescription = ({selectedCase}) => {

    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            <div className="row">
                <div className="col-lg-4 align-self-bottom">
                    {/* <label className="form-label mb-3">Gender</label>
                    <div className="col">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="male" />
                            <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="female" />
                            <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="unknown" />
                            <label className="form-check-label" htmlFor="inlineRadio3">Unknown</label>
                        </div>
                    </div> */}
                    <small>Gender</small>
                    <p className="lead">{selectedCase.gender || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Ethnicity</label>
                    <select className="form-select" id="client">
                        <option value="">Select one</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>Ethnicity</small>
                    <p className="lead">{selectedCase.ethnicity || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Hair color</label>
                    <select className="form-select" id="hair-color">
                        <option value="">Select one</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>Hair color</small>
                    <p className="lead">{selectedCase.hairColor || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Hair length</label>
                    <select className="form-select" id="hair-length">
                        <option value="">Select one</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>Hair length</small>
                    <p className="lead">{selectedCase.hairLength || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Facial hair</label>
                    <select className="form-select" id="facial-hair">
                        <option value="">Select one</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>Facial hair</small>
                    <p className="lead">{selectedCase.facialHair || ''}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    {/* <label className="form-label">Height</label>        
                    <div className="form-floating">
                        <input type="number" className="form-control no-spin-button" id="feet" />
                        <label htmlFor="feet">Feet</label>
                    </div> */}
                    <small>Height feet</small>
                    <p className="lead">{`${selectedCase.heightFeet}ft` || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <div className="form-floating">
                        <input type="number" className="form-control no-spin-button" id="inches" />
                        <label htmlFor="inches">Inches</label>
                    </div> */}
                    <small>Height inches</small>
                    <p className="lead">{`${selectedCase.heightInches}"` || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Weight</label>        
                    <div className="form-floating">
                        <input type="number" className="form-control no-spin-button" id="pounds" />
                        <label htmlFor="pounds">Lbs.</label>
                    </div> */}
                    <small>Weight</small>
                    <p className="lead">{`${selectedCase.weight}lbs` || ''}</p>
                </div>
            </div>
        </div>
    )
}

export default SubjectDescription;