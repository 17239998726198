import { useState } from "react";

import ClientRequests from "api/ClientRequests";

import Addresses from "./Addresses";
import ClientInfo from "./ClientInfo";
import Notes from "./Notes";

const ViewAndEditClientModal = ({
    modalRef,
    secondModal_ref,
    handleInputChange,
    inputsValues,
    adminNotes,
    setAdminNotes,
    clientNotes,
    setClientNotes,
    addressesList,
    setAddressesList,
    stage,
    setStage,
    setClientsList,
    editModalClass_ref
}) => {

    const [ loading, SetLoading ] = useState();

    const { updateClient } = ClientRequests();
    
    const handleUpdatedClient = async e => {
        e.preventDefault();
        const data = {...inputsValues, clientNotes, clientAdminNotes: adminNotes, clientAddressList: addressesList};
        
        SetLoading(true);
        const response = await updateClient(data).catch(err => {
            SetLoading(false);
            throw new Error(err.message);
        });
        console.log(response)
        SetLoading(false);
        setClientsList(currentUsersList => {
            const clientId = inputsValues.clientID;
            const index = currentUsersList.findIndex(current => current.userID === clientId);
            currentUsersList[index] = response;
            return [...currentUsersList]
        });
        editModalClass_ref.current.hide();
    };
    
    return (
        <div className="">
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">View client</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{minHeight: '588px'}}>
                            <ul className="nav nav-tabs mb-3">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id='client-info-2' data-bs-toggle="tab" data-bs-target="#client-info-2-tab-pane" type="button" role="tab" aria-controls="client-info-2-tab-pane" aria-selected="true">Client Info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='notes-2' data-bs-toggle="tab" data-bs-target="#notes-2-tab-pane" type="button" role="tab" aria-controls="notes-2-tab-pane" aria-selected="true">Notes</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='addresses-2' data-bs-toggle="tab" data-bs-target="#addresses-2-tab-pane" type="button" role="tab" aria-controls="addresses-2-tab-pane" aria-selected="true">Addresses</button>
                                </li>
                            </ul>
                            <div className="tab-content mb-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="client-info-2-tab-pane" role="tabpanel" aria-labelledby="client-info-2-tab" tabIndex="0">
                                    <ClientInfo handleInputChange={handleInputChange} inputsValues={inputsValues} stage={stage} />
                                </div>
                                <div className="tab-pane fade show" id="notes-2-tab-pane" role="tabpanel" aria-labelledby="notes-2-tab" tabIndex="0">
                                    <Notes adminNotes={adminNotes} setAdminNotes={setAdminNotes} clientNotes={clientNotes} setClientNotes={setClientNotes} stage={stage} />
                                </div>
                                <div className="tab-pane btn-group fade show" id="addresses-2-tab-pane" role="tabpanel" aria-labelledby="addresses-2" tabIndex="0">
                                    <Addresses addressesList={addressesList} setAddressesList={setAddressesList} stage={stage} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-warning" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" onClick={e => setStage('editing')}>Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalToggle2" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1"  ref={secondModal_ref}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Edit client</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{minHeight: '588px'}}>
                            <ul className="nav nav-tabs mb-3">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id='client-info-3' data-bs-toggle="tab" data-bs-target="#client-info-3-tab-pane" type="button" role="tab" aria-controls="client-info-3-tab-pane" aria-selected="true">Client Info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='notes-3' data-bs-toggle="tab" data-bs-target="#notes-3-tab-pane" type="button" role="tab" aria-controls="notes-3-tab-pane" aria-selected="true">Notes</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='addresses-3' data-bs-toggle="tab" data-bs-target="#addresses-3-tab-pane" type="button" role="tab" aria-controls="addresses-3-tab-pane" aria-selected="true">Addresses</button>
                                </li>
                            </ul>
                            <div className="tab-content mb-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="client-info-3-tab-pane" role="tabpanel" aria-labelledby="client-info-3-tab" tabIndex="0">
                                    <ClientInfo handleInputChange={handleInputChange} inputsValues={inputsValues} stage={stage} />
                                </div>
                                <div className="tab-pane fade show" id="notes-3-tab-pane" role="tabpanel" aria-labelledby="notes-3-tab" tabIndex="0">
                                    <Notes adminNotes={adminNotes} setAdminNotes={setAdminNotes} clientNotes={clientNotes} setClientNotes={setClientNotes} stage={stage} />
                                </div>
                                <div className="tab-pane btn-group fade show" id="addresses-3-tab-pane" role="tabpanel" aria-labelledby="addresses-3" tabIndex="0">
                                    <Addresses addressesList={addressesList} setAddressesList={setAddressesList} stage={stage} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                loading && (
                                    <div className="spinner--container">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )
                            }
                            <button className="btn btn-secondary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" disabled={loading}>Cancel</button>
                            <button className="btn btn-success" onClick={ e => handleUpdatedClient(e)} disabled={loading}>Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ViewAndEditClientModal;
