import ServiceFilterModal from './components/serviceFilterModal/ServiceFilterModal';
import FlagsFilterModal from './components/flagsFilterModal/FlagsFilterModal';
import StatusesFilterModal from './components/statusesFilterModal/StatusesFilterModal';
import useDashboardLogic from 'helpers/useDashBoardLogic';

import Pagination from 'components/pagination/Pagination';
import Table from 'components/table/Table';


const Dashboard = () => {

    const FILTERS_LIST = {
        caseManagers: true,
        resourceType: true,
        services: true,
        flags: true,
        statuses: true,
    }

    const {
        selectedCaseManagerId,
        SetSelectedCaseManagerId,
        caseManagersList,
        selectedResourceId,
        SetSelectedResourceId,
        resourcesList,
        caseList,
        tableLoading,
        handleCaseSelect,
        currentPage,
        SetCurrentPage,
        resultsPerPage,
        SetResultsPerPage,
        RESULTS_PER_PAGE_OPTIONS,
        serviceFilter_ref,
        serviceFilterSelections,
        SetServiceFilterArray,
        serviceFilterArray,
        handleServiceFilter,
        clearServiceFilter,
        flagFilter_ref,
        flagsFilterSelections,
        SetFlagsFilterArray,
        flagsFilterArray,
        handleFlagFilter,
        clearFlagsFilter,
        caseStatusFilter_ref,
        statusFilterSelections,
        SetCaseStatusListFilter,
        handleStatusFilter,
        clearStatusesFilter,
        totalRowCount
    } = useDashboardLogic({filtersList: FILTERS_LIST});

    const TABLE_STRUCTURE = [
        {
            header: 'Case No.',
            propertyName: 'caseNumber',
        },
        {
            header: 'Case Status',
            propertyName: 'caseStatusName',
        },
        {
            header: 'Case manager',
            propertyNames: ['caseManagerFirstName', 'caseManagerLastName'],
        },
        {
            header: 'Age',
            propertyName: 'caseAge',
        },
        {
            header: 'Subject Name',
            propertyNames: ['subjectFirstName', 'subjectLastName'],
        },
        {
            header: 'Client',
            propertyName: 'clientName',
        },
        {
            header: 'Services',
            propertyName: 'caseServiceList',
            nestedProperties: ['serviceName'],
            isArray: true,
            joinBy: ', '
        },
        {
            header: 'Flags',
            propertyName: 'caseFlagList',
            nestedProperties: ['flagName'],
            isArray: true,
            isReact: true,
            classNames: ['flag'],
            style: {backgroundColor: ['flagColor']}
        },
        {
            header: 'Due Date',
            propertyName: 'dueDate',
            formatting: 'formatDate',
            formattingProps: 'date-written-out'
        },
    ];

    return (
            <div className="container-fluid">
                <div className='row g-2 mb-2'>
                    <div className="col-lg-2" style={{alignSelf: 'self-end'}}>
                        <label className="form-label">Case manager</label>
                        <select className="form-select" aria-label="Default select example" value={selectedCaseManagerId} onChange={e => SetSelectedCaseManagerId(e.currentTarget.value)}>
                            <option value="">All</option>
                            {
                                caseManagersList.map((caseManager, index) => <option key={index} value={caseManager.userID}>{`${caseManager.firstName} ${caseManager.lastName}`}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <label className="form-label">Resources</label>
                        <select className="form-select" aria-label="Default select example" value={selectedResourceId} onChange={e => SetSelectedResourceId(e.currentTarget.value)}>
                            <option value="">All</option>
                            {
                                resourcesList.map((resource, index) => <option key={index} value={resource.resourceTypeID}>{resource.resourceTypeName}</option>)
                            }
                        </select>
                    </div>
                    <div className="d-grid col-lg-2">
                        <button className="btn btn-primary" type="button" style={{alignSelf: 'flex-end'}} data-bs-target="#filterByServiceModal" data-bs-toggle="modal">Filter by Service</button>
                    </div>
                    <div className="d-grid col-lg-2">
                        <button className="btn btn-primary" type="button" style={{alignSelf: 'flex-end'}} data-bs-target="#filterByFlagModal" data-bs-toggle="modal">Filter by flags</button>
                    </div>
                    <div className="d-grid col-lg-2">
                        <button className="btn btn-primary" type="button" style={{alignSelf: 'flex-end'}} data-bs-target="#filterByStatusModal" data-bs-toggle="modal">Filter by status</button>
                    </div>
                </div>
                
                <div className='row g-3'>
                    <div className="col-lg-12">
                        <Table tableStructure={TABLE_STRUCTURE} data={caseList} loading={tableLoading} dataRowId='caseMasterID' onRowClick={handleCaseSelect} />
                    </div>
                </div>

                <Pagination currentPage={currentPage} SetCurrentPage={SetCurrentPage} resultsPerPage={resultsPerPage} setResultsPerPage={SetResultsPerPage} RESULTS_PER_PAGE_OPTIONS={RESULTS_PER_PAGE_OPTIONS} totalRowCount={totalRowCount} />

                <ServiceFilterModal modal={serviceFilter_ref} serviceFilterSelections={serviceFilterSelections} setServiceFilterArray={SetServiceFilterArray} serviceFilterArray={serviceFilterArray} handleInputChange={handleServiceFilter} clearServiceFilter={clearServiceFilter} />
                <FlagsFilterModal modal={flagFilter_ref} flagsFilterSelections={flagsFilterSelections} setFlagsFilterArray={SetFlagsFilterArray} flagsFilterArray={flagsFilterArray} handleInputChange={handleFlagFilter} clearFlagsFilter={clearFlagsFilter} />
                <StatusesFilterModal modal={caseStatusFilter_ref} statusFilterSelections={statusFilterSelections} setCaseStatusListFilter={SetCaseStatusListFilter} handleInputChange={handleStatusFilter} clearStatusesFilter={clearStatusesFilter} />
            </div>
    )
}

export default Dashboard;