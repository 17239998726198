import { useRef, useState, useEffect,  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './Login.scss';

import GeneralRequests from 'api/GeneralRequests';
import UserRequests from 'api/UserRequests';

import { useGlobals } from 'context/globals-context';

const Login = () => {

    const { loginAPI } = GeneralRequests()
    const { forgotPassword } = UserRequests();

    const { setToken, updateMyData, showToast } = useGlobals();
    const [ errorMessage, SetErrorMessage ] = useState('');
    const [ loading, SetLoading ] = useState(false);
    const [ recoveryEmail, SetRecoveryEmail ] = useState('');
    const [ recoveryEmailSent, SetRecoveryEmailSent ] = useState(false);

    const [ forgotPasswordStage, SetForgotPasswordStage ] = useState(false);

    const usernameRef = useRef();
    const passwordRef = useRef();
    const rememberMeRef = useRef();

    const location = useLocation();
    const navigate = useNavigate();

    const handleKeyDown = (e) => e.key === 'Enter' ? handleLogin(e) : '';

    const handleLogin = async (e) => {
        e.preventDefault();
        if(loading) return;
        SetLoading(true)
        try {
            if(!usernameRef.current.value || !passwordRef.current.value) {
                SetLoading(false)
                return SetErrorMessage('Please fill out all fields')
            }

            const response = await loginAPI(
                usernameRef.current.value,
                passwordRef.current.value,
                // rememberMeRef.current.checked
            );

            updateMyData(response);
            if( response?.token) {
                setToken(response.token, rememberMeRef.current.checked) //This function includes the redirect to "/dashboard"
            } else {
                SetLoading(false)
                return SetErrorMessage("Error")
            }
        } catch (err) {
            SetLoading(false)
            const error = JSON.parse(err.message);
            console.log(error.message)
            if(error.message === 'Username or password is incorrect') return SetErrorMessage("Username or password is incorrect")
            if(error.message.includes('Connection Timeout Expired')) return SetErrorMessage("Connection timed out")
            else SetErrorMessage("Network error")
        }
    };

    const handleForgotPassword = async () => {
        try {
            if(!recoveryEmail) return showToast({
                type: 'danger',
                text: 'You must type in your email'
            });
            SetLoading(true);
            await forgotPassword({email: recoveryEmail}, 'Email sent successfully', 'There was an error while trying to send you a recovery email');
            SetLoading(false);
            SetRecoveryEmail('');
            SetRecoveryEmailSent(true);
        } catch (err) {
            SetLoading(false);
            console.log(err);
        }
    }

    useEffect(() => {
        if(
            (location.pathname === '/' && localStorage.getItem('storedJWT'))
            ||
            (location.pathname === '/' && sessionStorage.getItem('storedJWT'))
        ) navigate('/dashboard');
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
            <section className="center-flex vh-100">
                <form className="container-sm container-small">
                    {
                        !forgotPasswordStage ? (
                            <>
                            <h4 className='mb-3'>Login</h4>
                            <div className="mb-3">
                                <label className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" ref={usernameRef} onKeyDown={e => handleKeyDown(e)}  />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" ref={passwordRef} onKeyDown={e => handleKeyDown(e)} autoComplete='true' />
                            </div>
                            <div className="row px-3">
                                <div className="col-6 mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" ref={rememberMeRef} />
                                    <label className="form-check-label" >Remember me</label>
                                </div>
                                <div className="col-6 mb-3">
                                    <label className="form-check-label link-primary cursor--pointer" onClick={e => {
                                        SetForgotPasswordStage(true);
                                        SetRecoveryEmailSent(false);
                                    }}>Forgot password</label>
                                </div>
                            </div>
                            <p className='text-danger'>{errorMessage}</p>
                            
                            <div style={{display: 'flex', columnGap: 20}}>
                                <button type="submit" className="btn btn-primary" onClick={e => handleLogin(e)} disabled={loading}>Login</button>
                                {loading && <div className="spinner--container">
                                    <div className="spinner-border text-light" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>}
                            </div>
                            </>
                        ) : <>
                            <div className="mb-3">
                                <h4 className='mb-3'>Forgot password</h4>
                                <label className="form-label">Email address</label>
                                <input type="email" className="form-control mb-2" aria-describedby="emailHelp" value={recoveryEmail} onChange={e => SetRecoveryEmail(e.currentTarget.value)}  />
                                
                                {recoveryEmailSent && <p className='text-success'>Recovery message sent!</p>}
                            </div>
                            <div className="row">
                                <div className="col-9">
                                    <button type="submit" className="btn btn-primary" onClick={e => handleForgotPassword(e)} disabled={loading}>Send recovery email</button>
                                </div>
                                <div className="col-3 mt-2">
                                    <label className="link-primary cursor--pointer" onClick={e => SetForgotPasswordStage(false)}>Go back</label>
                                </div>
                            </div>
                        </>
                    }
                </form>
            </section>
    )
}

export default Login;