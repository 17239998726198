import { useGlobals } from "context/globals-context";

const ServicesRequests = () => {

    const { servicesEndpoints, jwt, fetchData } = useGlobals();

    const getServicesList = async () => {

        try {
            const data = await fetchData(servicesEndpoints.getServicesList, {
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                },
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getServicesTypesList = async () => {

        try {
            const data = await fetchData(servicesEndpoints.getServicesTypesList, {
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                },
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }
    
    return {
        getServicesList,
        getServicesTypesList,
        
    }
}

export default ServicesRequests;