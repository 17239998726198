const PhoneNumbers = () => {

    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            <h4 className="mb-3">Primary Phone</h4>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Number</label>
                    <input type="number" className="form-control" id="phone"/>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Listed to</label>
                    <input type="text" className="form-control" id="listed-to"/>
                </div>
            </div>
            <div className="border-bottom mt-3 mb-4"></div>
            <h4 className="mb-3">Secondary Phone</h4>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Number</label>
                    <input type="text" className="form-control" id="secondary-phone"/>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Listed to</label>
                    <input type="text" className="form-control" id="secondary-listed-to"/>
                </div>
            </div>
        </div>
    )
}

export default PhoneNumbers;