import { useGlobals } from "context/globals-context";

const ResourceTypeRequests = () => {

    const { fetchData, resourceTypeEndpoints, jwt } = useGlobals();

    const getResourceTypeList = async (caseMasterID) => {
        try {
            const data = await fetchData(resourceTypeEndpoints.getResourceTypeList, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }
    
    return {
        getResourceTypeList,

    }
};

export default ResourceTypeRequests;
