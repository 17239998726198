import { useEffect, useState } from "react";
import { useGlobals } from "context/globals-context";

import ResourceTypeRequests from "api/ResourceTypeRequests";
import UserRequests from "api/UserRequests";
import TemplateRequests from "api/TemplateRequests";
import CaseRequests from "api/CaseRequests";

import TextEditor from "components/textEditor/TextEditor";

import "./NewResourceModal.scss";

const NewResourceModal = ({modal_ref, caseMasterID, bottomTabsBootstrapClasses_ref, setResourcesList}) => {


    const { showToast } = useGlobals();
    const { getResourceTypeList } = ResourceTypeRequests();
    const { getResourceList } = UserRequests();
    const { getTemplateList } = TemplateRequests();
    const { addCaseResource } = CaseRequests();

    const [ loading, SetLoading ] = useState(false);

    const [ resourcesTypesList, SetResourcesTypesList ] = useState([]);
    const [ selectedRoleId, SetSelectedRoleId ] = useState('');

    const [ resourcesPersonnelList, SetResourcesPersonnelList ] = useState([]);
    const [ selectedResourceId, SetSelectedResourceId ] = useState('');
    
    const [ date, SetDate ] = useState('');
    const [ subject, SetSubject ] = useState('');

    const [ templatesList, SetTemplatesList ] = useState([]);
    const [ selectedTemplateId, SetSelectedTemplateId ] = useState('');
    const [ instructions, SetInstructions ] = useState('');


    const handleSubmit = async e => {
        e.preventDefault();
        e.stopPropagation();

        try {
            SetLoading(true);
            if(!caseMasterID || !selectedResourceId) {
                showToast({
                    type: 'danger',
                    text: 'Resource ID must be selected'
                });
                SetLoading(false);
                return;
            };

            const data = {
                caseMasterID,
                resourceID: selectedResourceId,
                serviceID: 0,
            };

            
            if(!subject || !instructions) {
                showToast({
                    type: 'danger',
                    text: 'Missing required fields - Make sure you fill out the subject and instructions'
                });
                SetLoading(false);
                return;
            };
            
            data.emailSubject = subject;
            data.instructions = instructions;
            

            const response = await addCaseResource(data);
            
            setResourcesList(current => [...current, response]);
            bottomTabsBootstrapClasses_ref.current['resources'].hide();
            resetFields();
            showToast({
                type: 'success',
                text: 'Resource added successfully'
            });
            SetLoading(false);
        } catch (err) {
            SetLoading(false);
            showToast({
                type: 'danger',
                text: 'There was an error while trying to add the resource'
            });
            console.error(err);
        }
    }

    const resetFields = (e) => {
        if(e) e.preventDefault();
        setTimeout(() => { // Allow for modal to hide before clearing input fields
            SetSelectedRoleId('');
            SetSelectedResourceId('');
            SetDate('');
            SetSelectedTemplateId('');
            SetInstructions('');
            SetSubject('');
        }, 200);
    };

    useEffect(() => {
        SetSelectedResourceId('');
        if(!selectedRoleId) {
            SetResourcesPersonnelList([]);
            return
        };

        const loadResources = async () => {
            try {
                const response = await getResourceList({
                        "resourceTypeID": Number(selectedRoleId),
                        "active": true,
                        // "orderby": "string",
                        // "orderbyDirection": "string",
                        // "page": 0,
                        // "pageSize": 0
                    }
                );

                if(!response.length) throw new Error('No resources found for that user role ID');
                SetResourcesPersonnelList(response);
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'There was an error loading the resource options'
                })
                console.error(err);
            }
        }

        loadResources();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRoleId]);

    useEffect(() => {
        if(!selectedTemplateId) return SetInstructions('');

        const selectedTemplate = templatesList.find(template => template.templateID === selectedTemplateId);

        SetSubject(selectedTemplate.templateSubject || '');
        SetInstructions(selectedTemplate.templateBody);
        
    }, [selectedTemplateId, templatesList])

    useEffect(() => {
        const loadResourceTypeList = async () => {
            try {
                const response = await getResourceTypeList();
                console.log(response)
                if(!response.length) throw new Error('No resource type list found');
                SetResourcesTypesList(response);
            } catch (err) {
                // showToast({
                //     type: 'danger',
                //     text: 'There was an error loading the resource type list'
                // })
                console.error(err)
            }
        };

        const loadTemplateTypeList = async () => {
            try {
                const response = await getTemplateList();
                if(!response.length) {
                    console.error('No template list found');
                    return;
                };
                SetTemplatesList(response);
            } catch (err) {
                console.error(err)
            }
        }

        loadTemplateTypeList();
        loadResourceTypeList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="new-resource-modal">
            <div className="modal fade" id="newUserModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modal_ref}>
                {/* <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}> */}
                <form className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Assign new resource</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2">
                                <div className="col-lg-4">
                                    <label className="form-label">Resource type</label>
                                    <select value={selectedRoleId} className="form-select" onChange={e => SetSelectedRoleId(e.currentTarget.value)}>
                                        <option value=''>Please make a selection</option>
                                        {resourcesTypesList.map((data, index) => (
                                            <option key={index} value={data.resourceTypeID}>{data.resourceTypeName}</option>
                                        ))}
                                    </select>
                                </div>
                                
                                <div className="col-lg-8">
                                    <label className="form-label">Resource</label>
                                    <select value={selectedResourceId} className="form-select" onChange={e => SetSelectedResourceId(e.currentTarget.value)}>
                                        <option value=''>Please make a selection</option>
                                        {resourcesPersonnelList.map((data, index) => (
                                            <option key={index} value={data.userID}>{`${data.firstName} ${data.lastName}`} - {data.roleName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="row mb-2 pb-3 border-bottom">
                                <div className="col-lg-4">
                                    <label className="form-label">Date</label>
                                    <input value={date} type="date" onChange={e => SetDate(e.currentTarget.value)} className="form-control" placeholder="Date" />
                                </div>
                            </div>

                            <div className={`row mb-2`}>
                                <div className={`col-lg-6`}>
                                    <label className="form-label">Template</label>
                                    <select value={selectedTemplateId} className="form-select" id="client" onChange={e => SetSelectedTemplateId(Number(e.currentTarget.value))}>
                                        <option value=''>No template</option>
                                        {templatesList.map((data, index) => (
                                            <option key={index} value={data.templateID}>{data.templateName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label className="form-label">Subject</label>
                                    <input value={subject} type="text" className="form-control" onChange={e => SetSubject(e.currentTarget.value)} />
                                </div>

                                <div className="col-lg-12">
                                    <label className="form-label">Instructions</label>
                                    <TextEditor value={instructions} setValue={SetInstructions} />
                                </div>
                            </div>
                                    
                            <div className="modal-footer mt-5">
                                <button className="btn btn-secondary" data-bs-target="#newUserModalToggle" data-bs-toggle="modal" onClick={e => resetFields(e)}>Cancel</button>
                                <button className='btn btn-success' disabled={loading} onClick={e => handleSubmit(e)}>Accept</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default NewResourceModal;
