const Services = ({
    selectedServiceTypeId,
    SetServiceTypeId,
    servicesTypesList,
    serviceList,
    updateValue,
}) => {
    return (
        <>
            <div className="col-lg-4">
                <label className="form-label">Service type</label>
                <select value={selectedServiceTypeId} className="form-select" id="client" onChange={e => SetServiceTypeId(e.currentTarget.value)}>
                    <option value=''>Select one</option>
                    {servicesTypesList.map((data, index) => (
                        <option key={index} value={data.serviceTypeID}>{data.serviceTypeName}</option>
                    ))}
                </select>
                
            </div>
            <div className="row my-4">
                    {
                        serviceList.map((service, key) => (
                            <div className="col-lg-3" key={key}>
                                <div className="form-check">
                                    <input className="form-check-input" onChange={e => updateValue(e, 'checkbox-service')} data-identifier={service.serviceID} type="checkbox" id={`service-${service.serviceID}`} />
                                    <label className="form-check-label" htmlFor={`service-${service.serviceID}`}>
                                        {service.serviceName}
                                    </label>
                                </div>
                            </div>
                        ))
                    }
            </div>
        </>
    )
};

export default Services;
