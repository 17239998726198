import { useEffect, useState } from "react";

import TextEditor from "components/textEditor/TextEditor";

import TemplateRequests from "api/TemplateRequests";

const TemplateModal = ({modal_ref, bootstrapModalClass_ref, setTemplatesList, selectedTemplate, setSelectedTemplate, templatesTypeList}) => {

    const areWeEditing = selectedTemplate;
    
    const { addTemplate, updateTemplate } = TemplateRequests();

    const [ inputsValues, SetInputsValues] = useState(selectedTemplate || {});
    const [ body, SetBody ] = useState(selectedTemplate?.body || '');
    const [ loading, SetLoading ] = useState(false);

    const handleInputChange = (e) => {
        const key = e.currentTarget.dataset.identifier;
        const value = e.currentTarget.value;
        const format = e.currentTarget.dataset.format;

        if(format === 'number') inputsValues[key] = Number(value);
        else inputsValues[key] = value;
        SetInputsValues({...inputsValues});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            SetLoading(true);
            let response;
            if(!areWeEditing) response = await addTemplate({...inputsValues, templateBody: body}, 'New template created', 'Something went wrong');
            else response = await updateTemplate({...inputsValues, templateBody: body, templateID: selectedTemplate.templateID}, 'New template created', 'Something went wrong');

            if(!areWeEditing) addTemplate_frontEnd();
            else updateTemplate_frontEnd();

            clearForm();

            function addTemplate_frontEnd() { setTemplatesList(current => [response, ...current]) };
            function updateTemplate_frontEnd () {
                const templateID = selectedTemplate.templateID;
                setTemplatesList(currentTemplateList => {
                    const index = currentTemplateList.findIndex(current => current.templateID === templateID);
                    currentTemplateList[index] = response;
                    return [...currentTemplateList];
                });
            }
            
            function clearForm() {
                SetInputsValues({});
                bootstrapModalClass_ref.current.hide();
                setSelectedTemplate(null);
                SetLoading(false);
            }
        } catch (err) {
            SetLoading(false);
        };
    };

    useEffect(() => {
        if(!templatesTypeList.length) return;

        SetInputsValues(current => ({...current, templateTypeID: templatesTypeList[0].templateTypeID}))

    }, [templatesTypeList]);

    useEffect(() => {
        if(!selectedTemplate) return;

        const { templateName, templateTypeID, templateSubject, templateBody } = selectedTemplate;
        SetInputsValues({
            templateName,
            templateTypeID,
            templateSubject
        })
        SetBody(templateBody);
    }, [selectedTemplate]);

    return (
        <div className="new-client-modal">
            <div className="modal fade" id="newClientModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modal_ref}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">{!areWeEditing ? 'Create template' : 'Edit template' }</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row g-3 mb-3'>
                                <div className="col-lg-8">
                                    <label className="form-label">Template type</label>
                                    <select className="form-control" value={inputsValues.templateTypeID} data-identifier='templateTypeID' onChange={e => handleInputChange(e)} data-format='number'>
                                        {
                                            templatesTypeList.map((typeList, index) => (
                                                <option key={index} value={typeList.templateTypeID}>{typeList.templateTypeName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-lg-8">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" data-identifier="templateName" onChange={e => handleInputChange(e)} value={inputsValues.templateName || ''} />
                                </div>
                                <div className="col-lg-8">
                                    <label className="form-label">Subject</label>
                                    <input type="text" className="form-control" data-identifier="templateSubject" onChange={e => handleInputChange(e)} value={inputsValues.templateSubject || ''} />
                                </div>
                                <div className="col-lg-12">
                                    <label className="form-label">Body</label>
                                    <TextEditor value={body} setValue={SetBody} />
                                </div>
                            </div>
                        </div>
                                
                        <div className="modal-footer">
                            {
                                loading && (
                                    <div className="spinner--container">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )
                            }
                            <button className="btn btn-secondary" data-bs-target="#newClientModalToggle" data-bs-toggle="modal" disabled={loading} onClick={e => e.preventDefault()}>Cancel</button>
                            <button className="btn btn-success" type="submit" disabled={loading}>Accept</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default TemplateModal;