import ClientRequests from "api/ClientRequests";
import RequesterRequests from "api/RequesterRequests";

import { useGlobals } from "context/globals-context";

const CaseInfo = ({
    SetOfficeListDropdown,
    SetOfficeListQuery,
    SetOfficeId,
    officeListQuery,
    officeList,
    showOfficeListDropdown,
    selectedCaseManagerId,
    SetSelectedCaseManagerId,
    caseManagerList,
    SetSelectedSalesRepId,
    salesReps,
    updateValue,
    SetSelectedInsuranceId,
    selectedInsuranceId,
    lineOfInsuranceList,
    flagList,
}) => {

    return (
        <>
            <div className="row mb-2">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Office<span className="mandatory">*</span></label>
                    <div className="dropdown__wrapper">
                        <input onFocus={e => SetOfficeListDropdown(true)} onBlur={e => {
                                SetOfficeListDropdown(false);
                                if(e.relatedTarget?.dataset?.value) {
                                    SetOfficeListQuery(e.relatedTarget.innerText);
                                    SetOfficeId(e.relatedTarget.dataset.value);
                                };
                            }}
                            value={officeListQuery}
                            onChange={e => SetOfficeListQuery(e.currentTarget.value)}
                            type="text"
                            className="form-control"
                            id="requester"
                            autoComplete="off"
                            required
                        />
                            {officeList.length
                                ? <div className={`dropdown-menu ${showOfficeListDropdown ? '' : 'hidden'}`}>
                                    {
                                        officeList.map((office, index) => (
                                            <span key={index} tabIndex={0} className="dropdown-item" data-value={office.officeID}>{`${office.officeName}`}</span>
                                        ))
                                    }
                                </div> : ''
                            }
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Case manager<span className="mandatory">*</span></label>
                    <select value={selectedCaseManagerId} className="form-select" id="client" onChange={e => SetSelectedCaseManagerId(e.currentTarget.value)}>
                        <option value={''}></option>
                        {caseManagerList.map((data, index) => (
                            <option key={index} value={data.userID}>{`${data.firstName} ${data.lastName}`}</option>
                        ))}
                    </select>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Sales rep</label>
                    <select className="form-select" id="sales-rep" onChange={e => SetSelectedSalesRepId(e.currentTarget.value)}>
                        {salesReps.map((salesRepData, index) => (
                            <option key={index} value={salesRepData.userID}>{`${salesRepData.firstName} ${salesRepData.lastName}`}</option>
                        ))}
                    </select>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Due date</label>
                    <input type="date" onChange={e => updateValue(e)} data-identifier="dueDate" className="form-control" placeholder="Date" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Injury date</label>
                    <input type="date" onChange={e => updateValue(e)} data-identifier="injuryDate" className="form-control" placeholder="Date" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Claim number</label>
                    <input type="number" onChange={e => updateValue(e)} data-identifier="claimNumber" className="form-control no-spin-button" placeholder="" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Insurance</label>
                    <select className="form-select" onChange={e => SetSelectedInsuranceId(e.currentTarget.value)} value={selectedInsuranceId}>
                        {lineOfInsuranceList.map((lineOfInsuranceData, index) => (
                            <option key={index} value={lineOfInsuranceData.lineOfInsuranceID}>{lineOfInsuranceData.lineOfInsuranceName}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="row">
                    <label className="form-label">Flags list</label>
                </div>
                <div className="row mb-4">
                        {
                            flagList.map((flagData, key) => (
                                <div className="col-lg-3" key={key}>
                                    <div className="form-check">
                                        <input className="form-check-input" onChange={e => updateValue(e, 'checkbox-flag')} data-identifier={flagData.flagID} type="checkbox" id={`flag-${flagData.flagID}`} />
                                        <label className="form-check-label" htmlFor={`flag-${flagData.flagID}`}>
                                            {flagData.flagName}
                                        </label>
                                    </div>
                                </div>
                            ))
                        }
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8 mb-3">
                    <label className="form-label">Injury</label>
                    <textarea onChange={e => updateValue(e)} data-identifier="injury" className="form-control" rows="2"></textarea>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8 mb-3">
                    <label className="form-label">Insured</label>
                    <input onChange={e => updateValue(e)} data-identifier="insured" className="form-control"></input>
                </div>
            </div>
        </>
    )
};

export default CaseInfo;
