import React from "react";

import './Modal.scss';

const Modal = ({SetShowModal, pWidth, onClose, close = 'Close', text, modalRef, title = '', onAccept, acceptText = 'Accept', onAcceptProps, noX}) => {

    return (
        <div className="modal global-modal" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    {!noX && <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>}
                </div>
                <div className="modal-body">
                    <p>{text}</p>
                </div>
                <div className="modal-footer">
                    {onClose 
                        ? <button type="button" className="btn btn-secondary" onClick={e =>onClose()} >{close}</button>
                        : <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{close}</button>
                    }
                    { onAccept 
                        ? <button className="btn btn-primary" data-bs-dismiss="modal" onClick={e => {
                            onAccept(onAcceptProps);
                            SetShowModal(false);
                        }}>{acceptText}</button>
                        : ''
                    }
                </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;