import { useGlobals } from "context/globals-context";

const OfficeRequests = () => {

    const { officeEndpoints, jwt, fetchData } = useGlobals();

    const getOfficeList = async (officeQuery) => {

        try {
            const data = await fetchData(officeEndpoints.getOfficeList, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "officeSearch": officeQuery,
                    "onlyActive": true,
                    "orderby": "",
                    "orderbyDirection": "",
                    "page": 1,
                    "pageSize": 6
                  })
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }
    
    return {
        getOfficeList,
    }
}

export default OfficeRequests;