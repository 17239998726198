import { useGlobals } from "context/globals-context";

const RequesterRequests = () => {

    const { requesterEndpoints, jwt, fetchData } = useGlobals();

    const addRequester = async (data) => {
        try {
            const response = await fetchData(requesterEndpoints.addRequester, {
                method: "POST",
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            return response;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getRequesterList = async (selectedClientId, requesterQuery) => {
        try {

            const payload = {
                "onlyActive": true,
                "page": 1,
                "pageSize": 99
            };

            if(selectedClientId) payload.clientID = selectedClientId;
            if(requesterQuery) payload.requesterSearch = requesterQuery;
            
            const data = await fetchData(requesterEndpoints.getRequesterList, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }
    
    return {
        getRequesterList,
        addRequester,

    }
}

export default RequesterRequests;