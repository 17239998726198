import RequesterRequests from "api/RequesterRequests";
import TextEditor from "components/textEditor/TextEditor";

import { useGlobals } from "context/globals-context";

const Client = ({
    SetShowClientDropdown,
    SetClientQuery,
    SetSelectedClientId,
    clientQuery,
    clientList,
    showClientDropdown,
    SetShowRequesterDropdown,
    SetRequesterQuery,
    SetSelectedRequesterId,
    requesterQuery,
    selectedClientId,
    requesterList,
    showRequesterDropdown,
    SetClientList,
    SetInputValues,
    dynamicModal_ref,
    dynamicModalOptions_ref,
    SetRequesterList,
    SetReloadDynamicModal,
    clientNotes,
    SetClientNotes,
    adminNotes,
    SetAdminNotes,
    clientAddressList,
    setSelectedAddressId,
    clientHasBeenSelected_ref,
    selectedAddressId,
    newClientModalClass_ref,

}) => {

    const { addRequester } = RequesterRequests();

    const { showToast } = useGlobals();

    const primaryAddressSelected = clientAddressList?.find(address => address.clientAddressID === selectedAddressId)?.primary;

    const handleSelectedClient = e => {
        SetShowClientDropdown(false);
        if(e.relatedTarget?.dataset?.value) {
            const clientId = e.relatedTarget.dataset.value;
            clientHasBeenSelected_ref.current = true;

            SetClientQuery(e.relatedTarget.innerText);
            SetSelectedClientId(clientId);
            const selectedClient = clientList.find(client => client.clientID === clientId);
            SetClientNotes(selectedClient.clientNotes || '');
            SetAdminNotes(selectedClient.clientAdminNotes || '');
        } else SetClientQuery('');
        
    }

    const handleNewRequester = (newRequesterData) => {
        const simplifiedData = {
            active: newRequesterData.active,
            address1: newRequesterData.address1,
            address2: newRequesterData.address2,
            city: newRequesterData.city,
            clientID: newRequesterData.clientID,
            clientName: null,
            email: newRequesterData.email,
            firstName: newRequesterData.firstName,
            lastName: newRequesterData.lastName,
            phone: newRequesterData.phone,
            postalCode: newRequesterData.postalCode,
            requesterID: newRequesterData.userID,
            stateID: newRequesterData.stateID
        }
        SetRequesterList(current => [...current, simplifiedData]);
        SetRequesterQuery(`${simplifiedData.firstName} ${simplifiedData.lastName}`);
        SetSelectedRequesterId(simplifiedData.requesterID);
        SetInputValues({});
        dynamicModal_ref.current.hide();
    };

    const handleNewRequesterModal = () => {

        if(!selectedClientId) return showToast({
            type: 'warning',
            text: 'Please select a client first'
        });

        const FIELDS = [
            {
                label: "First name",
                type: "text",
                fieldName: "firstName",
                mandatory: true
            },
            {
                label: "Last name",
                type: "text",
                fieldName: "lastName",
                mandatory: true
            },
            {
                label: "Email",
                type: "email",
                fieldName: "email",
                mandatory: true
            },
            {
                label: "Phone number",
                type: "number",
                fieldName: "phone",
                mandatory: false
            },
            {
                label: "Phone extension",
                type: "text",
                fieldName: "phoneExt",
                mandatory: false
            },
            {
                label: "Address",
                type: "number",
                fieldName: "address1",
                mandatory: false
            },
        ]

        const options = {
            modalHeader: 'Add new requester',
            fields: FIELDS,
            requestFunction: addRequester,
            handleResponseFunction: handleNewRequester,
            successMessage: 'New requester added successfully',
            errorMessage: 'Error while trying to add a new requester',
            extraFields: {
                clientID: selectedClientId
            }
        }

        dynamicModalOptions_ref.current = options;
        SetReloadDynamicModal(current => !current);
        dynamicModal_ref.current.show();
    };

    const handleNewClientModal = () => {
        newClientModalClass_ref.current.show();
    }

    return (
        <>
            <div className="row mb-2">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Client<span className="mandatory">*</span></label>
                    <div className="dropdown__wrapper">
                        <input onFocus={e => SetShowClientDropdown(true)} onBlur={handleSelectedClient}
                            value={clientQuery}
                            onChange={e => SetClientQuery(e.currentTarget.value)}
                            type="text"
                            className="form-control"
                            id="requester" autoComplete='off' required />
                            {clientList.length
                                ? <div className={`dropdown-menu ${showClientDropdown ? '' : 'hidden'}`}>
                                    {
                                        clientList.map((client, index) => (
                                            <span key={index} tabIndex={0} className="dropdown-item" data-value={client.clientID}>{`${client.clientName}`}</span>
                                        ))
                                    }
                                </div> : ''
                            }
                    </div>
                </div>
                <div className="col-lg-1 mb-3" style={{alignSelf: 'self-end'}} onClick={e => handleNewClientModal()}>
                    <button type="button" className="btn btn-secondary p-2" style={{height: 38, width: 38, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <i className="bi bi-plus-circle" style={{display: 'flex'}}></i>
                    </button>
                </div>
                <div className="col-lg-4">
                    <label className="form-label">Requester<span className="mandatory">*</span></label>
                    <div className="dropdown__wrapper">
                        <input onFocus={e => SetShowRequesterDropdown(true)} onBlur={e => {
                                SetShowRequesterDropdown(false);
                                if(e.relatedTarget?.dataset?.value) {
                                    SetRequesterQuery(e.relatedTarget.innerText);
                                    SetSelectedRequesterId(e.relatedTarget.dataset.value)
                                } else SetRequesterQuery('');
                            }}
                            value={!selectedClientId ? 'Select a client first' : requesterQuery}
                            onChange={e => SetRequesterQuery(e.currentTarget.value)}
                            type="text"
                            className="form-control"
                            id="requester"
                            readOnly={selectedClientId ? false : true}
                            autoComplete="off"
                            required
                        />
                        {requesterList.length
                            ? <div className={`dropdown-menu ${showRequesterDropdown ? '' : 'hidden'}`}>
                                {
                                    requesterList.map((requester, index) => (
                                        <span key={index} tabIndex={0} className="dropdown-item" data-value={requester.requesterID}>{`${requester.firstName} ${requester.lastName}`}</span>
                                    ))
                                }
                            </div> : <div className={`dropdown-menu ${showRequesterDropdown ? '' : 'hidden'}`} style={{paddingLeft: '10px'}}>
                                Client has no requesters
                            </div>
                        }
                    </div>
                </div>
                <div className="col-lg-1 mb-3" style={{alignSelf: 'self-end'}}>
                    <button type="button" className="btn btn-secondary p-2" style={{height: 38, width: 38, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={e => handleNewRequesterModal(e)}>
                        <i className="bi bi-plus-circle" style={{display: 'flex'}}></i>
                    </button>
                </div>
                <div className="col-lg-6">
                    <label className="form-label">Client address<span className="mandatory">*</span></label>
                        <div style={{display: 'flex', gap: '20px'}}>
                            <select className='form-select' value={selectedAddressId} onChange={e => setSelectedAddressId(e.currentTarget.value)}>
                                {
                                    !selectedClientId ? (
                                        <option value="">Select a client first</option>
                                    ) : !clientAddressList.length ? (
                                        <option value="">Client has no address</option>
                                    ) : (
                                        <>
                                            <option value="">Select an address</option>
                                            {clientAddressList.map((client, index) => (
                                                <option key={index} value={client.clientAddressID}>{client.address1} - {client.address2}</option>
                                            ))}
                                        </>
                                    )
                                }
                            </select>
                            {
                                primaryAddressSelected ? (
                                    <div className="alert alert-light" role="alert" style={{display: 'block', width: '172px', padding: '5px 10px', margin: 0}}>
                                        Primary address
                                    </div>
                                ) : <div style={{display: 'block', width: '172px', padding: '10px', margin: 0}}>
                                    </div>
                            }
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-lg-10 mb-3">
                    <label className="form-label">Client notes<span className="mandatory">*</span></label>
                    <TextEditor value={clientNotes} setValue={SetClientNotes} readOnly={!selectedClientId} />
                </div>
                <div className="col-lg-10 mb-3">
                    <label className="form-label">Admin notes<span className="mandatory">*</span></label>
                    <TextEditor value={adminNotes} setValue={SetAdminNotes} readOnly={!selectedClientId} />
                </div>
            </div>
        </>
    )
};

export default Client;
