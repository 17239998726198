const SubjectInfo = ({updateValue}) => {
    return (
        <>
            <div className="row border-bottom mb-4 pb-3">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">First name<span className="mandatory">*</span></label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="firstName" required />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Middle name</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="middleName" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Last name<span className="mandatory">*</span></label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="lastName" required />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Email</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="email" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">DOB</label>
                    <input type="date" className="form-control" onChange={e => updateValue(e)} data-identifier="dob"/>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">SSN<span className="mandatory">*</span></label>
                    <input type="number" className="form-control no-spin-button" onChange={e => updateValue(e)} data-identifier="ssn"/>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Home phone</label>
                    <input type="number" className="form-control no-spin-button" onChange={e => updateValue(e)} data-identifier="phoneHome"/>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Mobile phone</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="phoneMobile"/>
                </div>
            </div>
            <div className="row border-bottom pb-3">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Occupation</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="occupation"/>
                </div>
            </div>
        </>
    )
};

export default SubjectInfo;
