import { useState, useEffect } from "react";

import { useGlobals } from "context/globals-context";

import useFormattingFunctions from "components/useFormattingFunctions/useFormattingFunctions";

import './Table.scss';

const Table = ({tableStructure, loading, data, onRowClick, dataRowId}) => {
    
    const { formatDate } = useFormattingFunctions();

    const { theme } = useGlobals();
    
    const [ tableHeaders, SetTableHeaders ] = useState([]);
    const [ tableRows, SetTableRows ] = useState([]);

    const generateHeaders = (tableStructure) => {
        const headers = tableStructure.map(obj => obj.header)
        return headers;
    }
    const generateRows = (tableStructure) => {
        const rows = data.map(currentIterationObj => {
            return tableStructure.map(structureObj => {
                if(structureObj.propertyNames) {
                    return structureObj.propertyNames.map(property => currentIterationObj[property]).join(structureObj.joinBy || ' ');
                } else if(structureObj.nestedProperties) {
                    let textValuesArr;
                    if(structureObj.isArray) {
                        textValuesArr = currentIterationObj[structureObj.propertyName]
                            ?.map(current => structureObj.nestedProperties
                                .reduce((acc, key) => acc[key], current)
                            ) || [];
                        if(structureObj.isReact) {
                            if(structureObj.style) {
                                const JSX = textValuesArr.map((textValue, index) => {
                                    const styles = {};
                                    const stylesKeys = Object.keys(structureObj.style);
                                    stylesKeys.forEach((style) => {
                                        if(Array.isArray(structureObj.style[style])) { // Value is nested
                                            const styleValue = structureObj.style[style].reduce((acc, key) => acc[key], currentIterationObj[structureObj.propertyName][index]);
                                            if(style === 'backgroundColor') {
                                                styles.color = isPastelColor(styleValue) ? '#2b2b2b' : '#fff';
                                            }
                                            styles[style] = styleValue;
                                        }
                                    });
                                    return <div key={index} className={[...structureObj.classNames]} style={styles}>{textValue}</div>
                                });
                                return JSX;
                            }
                        }
                    } else textValuesArr = structureObj.nestedProperties.reduce((acc, key) => acc[key], currentIterationObj[structureObj.propertyName])
                    if(!structureObj.isReact) return textValuesArr.join(structureObj.joinBy || ' ');
                    else return '';
                } else if(structureObj.formatting === 'formatDate') {
                    return formatDate(currentIterationObj[structureObj.propertyName], structureObj.formattingProps);
                } else return currentIterationObj[structureObj.propertyName];
            })
        })
        return rows;
    }

    function isPastelColor(hexColor) {
        // Convert hex to RGB
        let r = parseInt(hexColor.substring(1, 3), 16) / 255;
        let g = parseInt(hexColor.substring(3, 5), 16) / 255;
        let b = parseInt(hexColor.substring(5, 7), 16) / 255;
    
        // Convert to HSL
        let cmax = Math.max(r, g, b);
        let cmin = Math.min(r, g, b);
        let delta = cmax - cmin;
    
        // let h, s, l;
        let h, l;
    
        if (delta === 0) {
            h = 0;
        } else if (cmax === r) {
            h = ((g - b) / delta) % 6;
        } else if (cmax === g) {
            h = (b - r) / delta + 2;
        } else {
            h = (r - g) / delta + 4;
        }
    
        h = Math.round(h * 60);
    
        if (h < 0) {
            h += 360;
        }
    
        l = (cmax + cmin) / 2;
        // s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

        // Define pastel criteria
        let pastelLightnessThreshold = 0.5;
        // let pastelSaturationThreshold = 0.5;
    
        // Check if it's pastel
        return (l > pastelLightnessThreshold);
    }

    // const generateObject = (valuesArr) => {
    //     const propertyNames = tableStructure.map(obj => obj.propertyName);
    //     const newObject = propertyNames.reduce((acc, propertyName, index) => {
    //         acc[propertyName] = valuesArr[index];
    //         return acc;
    //     },{})

    //     return newObject
    // }

    const selectObj = index => data[index];

    useEffect(() => {
        if(!tableStructure) return;
        const headers = generateHeaders(tableStructure);
        SetTableHeaders(headers);
        if(!data || !data.length) return SetTableRows([])
        const rows = generateRows(tableStructure);
        SetTableRows(rows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableStructure, data]);

    return (
        <div className="table-component">
            <table className={`table ${theme === 'light' ? '' : 'table-dark'} table-hover`}>
                <thead>
                    <tr>
                        {tableHeaders.map((tableHeader, index) => (
                            <th scope="col" key={index}>{tableHeader}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {tableRows.map((row, index) => (
                        // <tr key={`${index}-tr`} className={`${onRowClick ? 'cursor--pointer' : ''}`} data-row-id={dataRowId ? data?.[index]?.[dataRowId] : ''} onClick={e => onRowClick ? onRowClick(e, generateObject(row)) : null}>
                        <tr key={`${index}-tr`} className={`${onRowClick ? 'cursor--pointer' : ''}`} data-row-id={dataRowId ? data?.[index]?.[dataRowId] : ''} onClick={e => onRowClick ? onRowClick(e, selectObj(index)) : null}>
                            {row.map((value, index) => {
                                if(!value && value !== 0) value = '';
                                return <td key={`${index}-td`}>{value}</td>
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
                {
                    loading ?
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : tableRows.length === 0 ?
                        <div className="d-flex justify-content-center">
                            No results
                        </div>
                    : ''
                }               
        </div>
    )
}

export default Table;

