import { useState } from "react";

import { useGlobals } from "context/globals-context";
import RequesterRequests from "api/RequesterRequests";


const NewRequesterModal = ({newRequesterModal_ref, newRequesterModalClass_ref, setRequesterList, clientList, selectedClientId, setSelectedClientID, inputsValues, setInputsValues}) => {

    const { showToast } = useGlobals();
    const { addRequester } = RequesterRequests();

    const [ loading, SetLoading ] = useState(false);
    const [ errorMessage, SetErrorMessage ] = useState('');

    const handleInputChange = (e) => {
        const key = e.currentTarget.dataset.identifier;
        const value = e.currentTarget.value;
        if(key === 'stateID') {
            inputsValues[key] = value;
        } else inputsValues[key] = value;
        setInputsValues({...inputsValues});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const MANDATORY_FIELDS = [
            'firstName',
            'lastName',
            'email',
        ];

        const allMandatoryFieldsFilledOut = MANDATORY_FIELDS.every( fieldName => inputsValues[fieldName]) && selectedClientId;

        if(!allMandatoryFieldsFilledOut) return SetErrorMessage('Please fill out all mandatory fields (*)');
        try {

            SetLoading(true);
            inputsValues.clientID = selectedClientId;
            const response = await addRequester(inputsValues);
            setRequesterList(current => [response, ...current]);
            showToast({
                type: 'success',
                text: 'New requester added successfully successfully'
            });
            newRequesterModalClass_ref.current.hide();
            SetErrorMessage('');
            setInputsValues({});
        } catch (err) {
            SetErrorMessage('Error');
            SetLoading(false);
            showToast({
                type: 'danger',
                text: 'There was an error while trying to invite the new requester'
            });
            console.error(err)
        }
    };

    return (
        <div className="new-user-modal">
            <div className="modal fade" id="newUserModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={newRequesterModal_ref}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Invite new requester</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <br />
                        <div className="modal-body">
                            <div className='row g-3 mb-4'>
                                <div className="col-lg-4">
                                    <label className="form-label">Client<span className="mandatory">*</span></label>
                                    <select className="form-control" value={selectedClientId} onChange={e => setSelectedClientID(e.currentTarget.value)} required>
                                        {
                                            clientList.map((client, index) => (
                                                <option key={index} value={client.clientID}>{client.clientName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label">First name<span className="mandatory">*</span></label>
                                    <input type="text" className="form-control" data-identifier="firstName" onChange={e => handleInputChange(e)} value={inputsValues.firstName || ''} required />
                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label">Last name<span className="mandatory">*</span></label>
                                    <input type="text" className="form-control" data-identifier="lastName" onChange={e => handleInputChange(e)} value={inputsValues.lastName || ''} required />
                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label">Email<span className="mandatory">*</span></label>
                                    <input type="email" className="form-control" data-identifier="email" onChange={e => handleInputChange(e)} value={inputsValues.email || ''} required />
                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label">Phone number</label>
                                    <input data-identifier="phone" type="tel" className="form-control" onChange={e => handleInputChange(e)} value={inputsValues.phone || ''} />
                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label">Phone extension</label>
                                    <input type="number" className="form-control" data-identifier="phoneExt" onChange={e => handleInputChange(e)} value={inputsValues.phoneExt || ''} />
                                </div>
                            </div>
                                    
                            <div className="modal-footer">
                                {errorMessage ? <p className="link-danger"><strong>Error</strong></p> : ''}
                                {
                                    loading && (
                                        <div className="spinner--container">
                                            <div className="spinner-border text-light" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    )
                                }
                                <button className="btn btn-secondary" data-bs-target="#newUserModalToggle" data-bs-toggle="modal" onClick={e => e.preventDefault()}>Cancel</button>
                                <button className="btn btn-success" type="submit" disabled={loading}>Accept</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewRequesterModal;