import { useState, useEffect, useRef } from "react";

import { useGlobals } from "context/globals-context";
import useFormattingFunctions from "components/useFormattingFunctions/useFormattingFunctions";

import CaseRequests from "api/CaseRequests";
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import Table from "components/table/Table";
import VideoModal from "components/videoModal/VideoModal";

import './Media.scss';

const Media = ({caseMasterID, selectedTab}) => {

    const TABLE_STRUCTURE = [
        {
            header: 'File name',
            propertyName: 'caseMediaName',
        },
        {
            header: 'Date',
            propertyName: 'createdDateTime',
            formatting: 'formatDate',
            formattingProps: 'include-time-written-out'
        },
        {
            header: 'Link',
            propertyName: 'caseReportVideoLink',
        },
    ]

    const { sortByDate } = useFormattingFunctions();
    const { getCaseMediaList, uploadCaseMedia, downloadCaseMedia } = CaseRequests();
    const { showToast } = useGlobals();

    const [ loading, SetLoading ] = useState(true);
    const [ caseMediaList, SetCaseMediaList ] = useState([]);
    const [ videoData, SetVideoData ] = useState({});
    const [ loadedFile, SetLoadedFile ] = useState();
    const [ uploading, SetUploading ] = useState(false);
    const [ isDraggingOver, SetIsDraggingOver ] = useState(false);

    const modalRef = useRef();
    const modaClassRef = useRef();
    const fileInput_ref = useRef();

    const handleClickedRow = async (e, data) => {
        e.preventDefault();
        if(data.caseMediaName.split('.')[data.caseMediaName.split('.').length - 1]?.toLowerCase() === 'mp4' || data.caseReportVideoLink) {
            SetVideoData(data);
            modaClassRef.current.show();
        } else {
            try {
                showToast({
                    type: 'info',
                    text: 'Your file is being downloaded in the background'
                });
                const response = await downloadCaseMedia(data.caseMediaID);
                
                showToast({
                    type: 'success',
                    text: 'File downloaded successfully'
                });

                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');

                a.href = url;
                a.download = data.caseMediaName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'There was an error while trying to download the file'
                });
                console.error(err)
            }
        }
    }

    const handleFileLoad = (e) => {
        e?.preventDefault();
        const file = e?.dataTransfer?.files[0] || fileInput_ref.current.files[0];
        SetLoadedFile(file);
        SetIsDraggingOver(false);
    }

    const handleUpload = async (e) => {
        e?.preventDefault();

        try {
            const formData = new FormData();
            formData.append('file', loadedFile);
        
            // Handle response as needed
            showToast({
                type: 'info',
                text: 'Your file is being uploaded in the background'
            });
            SetUploading(true);
            const response = await uploadCaseMedia(caseMasterID, formData);
            SetUploading(false);
            
            SetCaseMediaList(current => {
                console.log([response, ...current])
                return [response, ...current];
            });
            
            fileInput_ref.current.value = '';
            showToast({
                type: 'success',
                text: 'File was uploaded successfully'
            });
            unloadFile(e);
        } catch (err) {
            SetUploading(false);
            fileInput_ref.current.value = '';
            showToast({
                type: 'danger',
                text: 'There was an error while uploading the file'
            });
            console.error('Error uploading file:', err);
        }
    };

    const unloadFile = e => {
        e?.preventDefault();
        fileInput_ref.current.value = '';
        SetLoadedFile(null);
    }

    const handleOnDragOver = e => {
        e.preventDefault();
        SetIsDraggingOver(true);
    }

    useEffect(() => {
        if(selectedTab !== 'media') return;

        const getData = async () => {
            const response = await getCaseMediaList(caseMasterID);
            
            const sortedList = sortByDate(response, 'createdDateTime');
            
            SetLoading(false);
            SetCaseMediaList(sortedList);
        };

        modaClassRef.current = new bootstrapBundleMin.Modal(modalRef.current, {
            keyboard: false
        });
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, caseMasterID])

    return (
        <div className="media overflow-y-scroll h-500">
            <input
                type="file"
                ref={fileInput_ref}
                style={{ display: 'none' }}
                onChange={handleFileLoad}
            />
            <div className={`add-files ${isDraggingOver ? 'is-dragging-over' : ''}`}
                onClick={e => {
                    e.preventDefault();
                    if(uploading) return;
                    fileInput_ref.current.click()
                }}
                onDrop={handleFileLoad}
                onDragOver={handleOnDragOver}
                onDragLeave ={e => SetIsDraggingOver(false)}
            >{
                isDraggingOver ? (
                    <div style={{display: 'flex', columnGap: '10px', alignItems: 'center'}} className="pointer-events--none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                        </svg>
                        <span>Drop your file</span>
                    </div>
                ) : uploading ? (
                    <div className="spinner--container">
                        <div className="spinner-border text-light" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                ) : !loadedFile ? "Click here or drag your file here" : (
                <div onClick={e => e.stopPropagation()} style={{display: 'flex', columnGap: "15px", alignItems: 'center'}}>
                    <span style={{display: "inline-block", marginRight: '20px'}}>{loadedFile.name}</span>
                    <button type="button" className="btn btn-primary" onClick={handleUpload}>Upload</button>
                    <button type="button" className="btn btn-secondary" onClick={unloadFile}>Cancel</button>
                </div>
            )}</div>
            <Table tableStructure={TABLE_STRUCTURE} data={caseMediaList} loading={loading} dataRowId='caseReportVideoLink' onRowClick={handleClickedRow} />
            <VideoModal modalRef={modalRef} videoData={videoData}/>
        </div>
    )
}

export default Media;