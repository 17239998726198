import { useEffect, useState } from "react";
import { useGlobals } from "context/globals-context";
import ServicesRequests from "api/ServicesRequests";

const ServiceFilterModal = ({modal, serviceFilterSelections, setServiceFilterArray, handleInputChange, clearServiceFilter}) => {

    const { showToast } = useGlobals();

    const { getServicesList } = ServicesRequests();

    const [ serviceList, SetServicesList ] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {

            // const response = await addUser(inputsValues);
            // console.log(response)
        } catch (err) {
            showToast({
                type: 'danger',
                text: 'There was an error while loading the service list'
            });
            console.error(err)
        }
    }

    useEffect(() => {
        const loadServicesList = async () => {
            try {
                const response = await getServicesList();
                SetServicesList(response);
            } catch (err) {
                console.error(err);
            }
        };

        loadServicesList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="new-user-modal">
            <div className="modal fade" id="filterByServiceModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modal}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Filter by services</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex no-shrink mb-3">
                                <div className="list-group wrap-elements">
                                    {
                                        serviceList.map(({serviceID, serviceName}, index) => (
                                            <label key={index} className="list-group-item d-flex gap-2 border small-border-radius">
                                                <input className="form-check-input flex-shrink-0" onChange={e => handleInputChange(serviceID, e.currentTarget.checked)}  type="checkbox" value={serviceID} checked={serviceFilterSelections.find(obj => obj.serviceID === serviceID) || false} />
                                                <span>{serviceName}</span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                                    
                            <div className="modal-footer">
                                <button className="btn btn-secondary" data-bs-target="#filterByServiceModal" data-bs-toggle="modal" onClick={e => clearServiceFilter()}>Deselect all</button>
                                <button className="btn btn-success" data-bs-target="#filterByServiceModal" type="submit" data-bs-toggle="modal" onClick={e => setServiceFilterArray(serviceFilterSelections)}>Accept</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ServiceFilterModal;