import { useEffect, useState } from "react";

import { useGlobals } from "context/globals-context";
import useValidationFunctions from "helpers/useValidationFunctions";

import ClientRequests from "api/ClientRequests";
import ClientInfo from "./ClientInfo";
import Notes from "./Notes";
import Addresses from "./Addresses";

const NewClientModal = ({
    newClientModal_ref,
    newClientModalClass_ref,
    setClientsList,
    handleInputChange,
    adminNotes,
    setAdminNotes,
    clientNotes,
    setClientNotes,
    inputsValues,
    setInputsValues,
    addressesList,
    setAddressesList,
    setSelectedClientId,
    setClientQuery,
    clientHasBeenSelected_ref
}) => {

    const { showToast } = useGlobals();
    const { addClient } = ClientRequests();
    const { isValidJSON } = useValidationFunctions();

    const [ loading, SetLoading ] = useState(false);
    const [ errorMessage, SetErrorMessage ] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            SetLoading(true);
            const filteredAddresses = addressesList.filter(address => address.address1);
            const atLeastOneAddressIsActive = filteredAddresses.some(address => address.active);
            if(!atLeastOneAddressIsActive) {
                showToast({
                    type: 'danger',
                    text: 'You need at least one active address'
                });
                SetLoading(false);
                return;
            };
            const response = await addClient({...inputsValues, clientNotes, clientAdminNotes: adminNotes, clientAddressList: filteredAddresses});
            setClientsList(current => [response, ...current]);
            if(setSelectedClientId && setClientQuery) {
                setSelectedClientId(response.clientID);
                setClientQuery(response.clientName);
                clientHasBeenSelected_ref.current = true;
            }
            newClientModalClass_ref.current.hide();
            SetLoading(false);
            resetFields();
            showToast({
                type: 'success',
                text: 'New client added successfully successfully'
            });
        } catch (err) {
            SetLoading(false);
            if(isValidJSON(err.message)) {
                const errorMessage = JSON.parse(err.message).message;
                if(errorMessage.includes('ClientName') && errorMessage.includes('is already taken'))
                SetErrorMessage(errorMessage);
            }
            showToast({
                type: 'danger',
                text: 'There was an error while trying add the new client'
            });
            console.error(err)
        }
    }

    const resetFields = e => {
        e?.preventDefault();

        const clientInfoTab = document.querySelector('#client-info');

        clientInfoTab.click();
        setInputsValues({});
        setAddressesList([
            {
                addressName: '',
                address1: '',
                address2: '',
                city: '',
                stateID: '',
                postalCode: '',
                phone: '',
                phoneExt: '',
                primary: true,
                active: true,
            }
        ]);
        setAdminNotes('');
        setClientNotes('');
    };

    useEffect(() => SetErrorMessage(''), [inputsValues]);

    return (
        <div className="new-client-modal">
            <div className="modal fade" id="newClientModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={newClientModal_ref}>
                <form className="modal-dialog modal-dialog-centered modal-lg" onSubmit={e => handleSubmit(e)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Invite new client</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{minHeight: '588px'}}>
                            <ul className="nav nav-tabs mb-3">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id='client-info' data-bs-toggle="tab" data-bs-target="#client-info-tab-pane" type="button" role="tab" aria-controls="client-info-tab-pane" aria-selected="true">Client Info<span className="mandatory">*</span></button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='notes' data-bs-toggle="tab" data-bs-target="#notes-tab-pane" type="button" role="tab" aria-controls="notes-tab-pane" aria-selected="true">Notes</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='new-client-addresses' data-bs-toggle="tab" data-bs-target="#new-client-addresses-tab-pane" type="button" role="tab" aria-controls="new-client-addresses-tab-pane" aria-selected="true">Addresses<span className="mandatory">*</span></button>
                                </li>
                            </ul>
                            <div className="tab-content mb-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="client-info-tab-pane" role="tabpanel" aria-labelledby="client-info-tab" tabIndex="0">
                                    <ClientInfo handleInputChange={handleInputChange} inputsValues={inputsValues} />
                                </div>
                                <div className="tab-pane fade show" id="notes-tab-pane" role="tabpanel" aria-labelledby="notes-tab" tabIndex="0">
                                    <Notes adminNotes={adminNotes} setAdminNotes={setAdminNotes} clientNotes={clientNotes} setClientNotes={setClientNotes} />
                                </div>
                                <div className="tab-pane btn-group fade show" id="new-client-addresses-tab-pane" role="tabpanel" aria-labelledby="new-client-addresses" tabIndex="0">
                                    <Addresses addressesList={addressesList} setAddressesList={setAddressesList} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                loading && (
                                    <div className="spinner--container">
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )
                            }
                            {errorMessage ? <p className="link-danger"><strong>{errorMessage}</strong></p> : ''}
                            <button className="btn btn-secondary" type="button" data-bs-target="#newClientModalToggle" data-bs-toggle="modal" disabled={loading} onClick={resetFields}>Cancel</button>
                            <button className="btn btn-success" type="submit" disabled={loading}>Accept</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewClientModal;