import useDashboardLogic from 'helpers/useDashBoardLogic';

import Pagination from 'components/pagination/Pagination';
import Table from 'components/table/Table';

const DashboardQC = () => {

    const {
        caseList,
        tableLoading,
        currentPage,
        SetCurrentPage,
        resultsPerPage,
        SetResultsPerPage,
        RESULTS_PER_PAGE_OPTIONS,
        totalRowCount,
    } = useDashboardLogic({});

    const tableStructure = [
        {
            header: 'Case No.',
            propertyName: 'caseNumber',
        },
        {
            header: 'Case Status',
            propertyName: 'caseStatusName',
        },
        {
            header: 'Case manager',
            propertyNames: ['caseManagerFirstName', 'caseManagerLastName'],
        },
        {
            header: 'Age',
            propertyName: 'caseAge',
        },
        {
            header: 'Subject Name',
            propertyNames: ['subjectFirstName', 'subjectLastName'],
        },
        {
            header: 'Client',
            propertyName: 'clientName',
        },
        {
            header: 'Services',
            propertyName: 'caseServiceList',
            nestedProperties: ['serviceName'],
            isArray: true,
            joinBy: ', '
        },
        {
            header: 'Flags',
            propertyName: 'caseFlagList',
            nestedProperties: ['flagName'],
            isArray: true,
            isReact: true,
            classNames: ['flag'],
            style: {backgroundColor: ['flagColor']}
        },
        {
            header: 'Due Date',
            propertyName: 'dueDate',
            formatting: 'formatDate',
            formattingProps: 'date-written-out'
        },
    ]

    return (
            <div className="container-fluid">
                
                <div className='row g-3'>
                    <div className="col-lg-12">
                        <Table tableStructure={tableStructure} data={caseList} loading={tableLoading} dataRowId='caseMasterID' />
                    </div>
                </div>

                <Pagination currentPage={currentPage} SetCurrentPage={SetCurrentPage} resultsPerPage={resultsPerPage} setResultsPerPage={SetResultsPerPage} RESULTS_PER_PAGE_OPTIONS={RESULTS_PER_PAGE_OPTIONS} totalRowCount={totalRowCount} />
            </div>
    )
}

export default DashboardQC;