import { useGlobals } from "context/globals-context";

const GeneralRequests = () => {

    const { userEndpoints, caseListEndpoints, jwt, fetchData } = useGlobals();

    const loginAPI = async (username, password) => {
        try {
            const data = await fetchData(userEndpoints.login, {
                method: "POST",
                headers: {'Content-Type': 'application/json'}, 
                body: JSON.stringify({username, password})
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }


    const newCase = async (data) => {
        try {
            const response = await fetchData(caseListEndpoints.newCase, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(data)
            });

            return response;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    return {
        loginAPI,
        newCase,

    }
}

export default GeneralRequests;