import { useGlobals } from "context/globals-context";

const CaseRequests = () => {

    const { caseListEndpoints, jwt, fetchData } = useGlobals();

    const getCaseList = async (caseMasterID) => {
        try {
            const data = await fetchData(`${caseListEndpoints.getCase}/${caseMasterID}`, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getCaseAddressTypeList = async () => {
        try {
            const data = await fetchData(caseListEndpoints.getCaseAddressTypeList, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getCaseListPost = async (options) => {
        try {
            const data = await fetchData(caseListEndpoints.getCaseList, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(options)
            });
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getCaseResourceList = async (caseMasterID) => {
        try {
            const data = await fetchData(`${caseListEndpoints.getCaseResourceList}?caseMasterID=${encodeURIComponent(caseMasterID)}`, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getCaseStatusList = async () => {
        try {
            const data = await fetchData(caseListEndpoints.getCaseStatusList, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const updateCaseStatus = async (caseMasterID, caseStatusID) => {
        try {
            const response = await fetchData(caseListEndpoints.updateCaseStatus, {
                method: "POST",
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    caseMasterID,
                    caseStatusID
                })
            })
            return response;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getCaseMediaList = async (caseMasterID) => {
        try {
            const data = await fetchData(`${caseListEndpoints.getMediaList}?caseMasterID=${encodeURIComponent(caseMasterID)}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    const getCaseActivityList = async (caseMasterID) => {
        try {
            const data = await fetchData(`${caseListEndpoints.getCaseActivityList}?caseMasterID=${encodeURIComponent(caseMasterID)}`, {
                method: "GET",
                headers: {
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    const addCaseResource = async (data) => {
        try {
            const response = await fetchData(caseListEndpoints.addCaseResource, {
                method: "POST",
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            return response;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const uploadCaseMedia = async (caseMasterID, formData) => {
        try {
            const response = await fetchData(`${caseListEndpoints.uploadCaseMedia}?caseMasterID=${caseMasterID}`, {
                method: "POST",
                headers: {
                    'Authorization': jwt,
                },
                body: formData
            })
            return response;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const downloadCaseMedia = async (fileName) => {
        try {
            const data = await fetchData(`${caseListEndpoints.downloadCaseMedia}/${encodeURIComponent(fileName)}`, {
                method: "GET",
                headers: {
                    'Authorization': jwt
                }
            }, 'blob');
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    return {
        getCaseList,
        getCaseStatusList,
        updateCaseStatus,
        getCaseMediaList,
        getCaseActivityList,
        addCaseResource,
        getCaseResourceList,
        getCaseListPost,
        getCaseAddressTypeList,
        uploadCaseMedia,
        downloadCaseMedia,

    }
}


export default CaseRequests;