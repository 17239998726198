import { useGlobals } from "context/globals-context";

const ClientRequests = () => {

    const { clientEndpoints, jwt, fetchData, showToast } = useGlobals();

    const addClient = async (data) => {
        try {
            const response = await fetchData(clientEndpoints.addClient, {
                method: "POST",
                headers: {
                    'Authorization': jwt,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            return response;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const getClientList = async (clientQuery) => {
        try {
            const data = await fetchData(clientEndpoints.getClientList, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "clientName": clientQuery,
                    "orderby": 'CreatedDateTime',
                    "onlyActive": true,
                    "page": 1,
                    "pageSize": 100
                })
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const updateClient = async (data, successMessage = 'Changes stored successfully', errorMessage = 'There was an error processing your request') => {
        try {
            const response = await fetchData(clientEndpoints.updateClient, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            showToast({
                type: 'success',
                text: successMessage
            });
            return response;
        } catch (err) {
            showToast({
                type: 'danger',
                text: errorMessage
            });
            throw new Error(err.message)
        }
    }
    
    return {
        addClient,
        getClientList,
        updateClient,

    }
}

export default ClientRequests;