import useFormattingFunctions from "components/useFormattingFunctions/useFormattingFunctions";

const CaseInfo = ({selectedCase}) => {

    const { formatDate } = useFormattingFunctions();

    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            <div className="row">
                <div className="col-lg-4">
                    {/* <label className="form-label">Claim number</label>
                    <input type="number" className="form-control no-spin-button" /> */}
                    <small>Claim number</small>
                    <p className="lead">{selectedCase.claimNumber || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Client</label>
                    <input type="text" className="form-control"/> */}
                    <small>Client</small>
                    <p className="lead">{selectedCase.clientName || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Requester</label>
                    <input type="text" className="form-control"/> */}
                    <small>Requester</small>
                    <p className="lead">{selectedCase.requesterFirstName && selectedCase.requesterLastName ? `${selectedCase.requesterFirstName} ${selectedCase.requesterLastName}` : ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Injury date</label>
                    <input type="date" className="form-control" /> */}
                    <small>Injury date</small>
                    <p className="lead">{selectedCase.injuryDate ? formatDate(selectedCase.injuryDate, 'date-written-out') : ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Insured</label>
                    <input type="text" className="form-control"/> */}
                    <small>Insured</small>
                    <p className="lead">{selectedCase.insured || ''}</p>
                </div>
                <div className="col-lg-4">
                    {/* <label className="form-label">Case Manager</label>
                    <input type="text" className="form-control"/> */}
                    <small>Case manager</small>
                    <p className="lead">{selectedCase.caseManagerFirstName && selectedCase.caseManagerLastName ? `${selectedCase.caseManagerFirstName} ${selectedCase.caseManagerLastName}` : ''}</p>
                </div>
                {/* <div className="col-lg-4">
                    <label className="form-label">Carrier</label>
                    <input type="text" className="form-control"/>
                </div> */}
                <div className="col-lg-4">
                    {/* <label className="form-label">Services</label>
                    <input type="text" className="form-control"/> */}
                    <small>Services</small>
                    <p className="lead">
                    {
                        selectedCase?.caseServiceList ? selectedCase?.caseServiceList?.map(({serviceName}, index) => {
                            const isLast = index === selectedCase.caseServiceList.length - 1;
                            if(isLast) return serviceName;
                            else return `${serviceName}, `;
                        }) : ''
                    }
                    </p>
                </div>
                {/* <div className="col-lg-4">
                    <label className="form-label">Bill to</label>
                    <input type="text" className="form-control"/>
                </div> */}
                <div className="col-lg-4">
                    {/* <label className="form-label">Office</label>
                    <select className="form-select" id="office">
                        <option value="">Home Office</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                        <option value="">Next option</option>
                    </select> */}
                    <small>Office</small>
                    <p className="lead">{selectedCase.office || ''}</p>
                </div>
                {/* <div className="col-lg-4">
                    <label className="form-label">Adjuster</label>
                    <input type="text" className="form-control"/>
                    <small>Adjuster</small>
                    <p className="lead">{selectedCase.adjuster || ''}</p>
                </div> */}
                <div className="col-lg-4">
                    {/* <label className="form-label">Case Instructions</label>
                    <textarea className="form-control" rows="2"></textarea> */}
                    <small>Insured</small>
                    <p className="lead">{selectedCase.caseInstructions || ''}</p>
                </div>
            </div>
        </div>
    )
}

export default CaseInfo;