const Instructions = ({selectedCase}) => {

    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            <div className="row">
                <div className="col-lg-8">
                    <small>Instructions</small>
                    <div className="lead" dangerouslySetInnerHTML={{ __html: selectedCase.caseInstructions || "" }} />
                </div>
            </div>
        </div>
    )
}

export default Instructions;