
const ClientInfo = ({handleInputChange, inputsValues, stage = 'editing'}) => {

    return (
        <div className='row g-3 mb-4'>
            <div className="col-lg-4">
                <label className="form-label">Client name<span className="mandatory">*</span></label>
                {
                    stage === 'editing' ? (
                        <input type="text" className="form-control" data-identifier="clientName" onChange={e => handleInputChange(e)} value={inputsValues.clientName || ''} required />
                    ) : <p className="lead">{inputsValues.clientName || ''}</p>
                }
            </div>
            <div className="col-lg-4">
                <label className="form-label">Contact first name<span className="mandatory">*</span></label>
                {
                    stage === 'editing' ? (
                        <input type="text" className="form-control" data-identifier="contactFirstName" onChange={e => handleInputChange(e)} value={inputsValues.contactFirstName || ''} required />
                    ) : <p className="lead">{inputsValues.contactFirstName || ''}</p>
                }
            </div>
            <div className="col-lg-4">
                <label className="form-label">Contact last name<span className="mandatory">*</span></label>
                {
                    stage === 'editing' ? (
                        <input type="text" className="form-control" data-identifier="contactLastName" onChange={e => handleInputChange(e)} value={inputsValues.contactLastName || ''} required />
                    ) : <p className="lead">{inputsValues.contactLastName || ''}</p>
                }
            </div>
            <div className="col-lg-4">
                <label className="form-label">Phone number</label>
                {
                    stage === 'editing' ? (
                        <input data-identifier="phone" type="number" className="form-control" onChange={e => handleInputChange(e)} value={inputsValues.phone || ''} />
                    ) : <p className="lead">{inputsValues.phone || ''}</p>
                }
            </div>
            <div className="col-lg-4">
                <label className="form-label">Phone extension</label>
                {
                    stage === 'editing' ? (
                        <input type="number" className="form-control" data-identifier="phoneExt" onChange={e => handleInputChange(e)} value={inputsValues.phoneExt || ''} />
                    ) : <p className="lead">{inputsValues.phoneExt || ''}</p>
                }
                
            </div>
            <div className="col-lg-4">
                <label className="form-label">Is Active</label>
                {
                    stage === 'editing' ? (
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-identifier="active" data-type="switch" checked={inputsValues.active || false} onChange={handleInputChange}/>
                        </div>
                    ) : <div className="form-check form-switch">
                            <input style={{opacity: 1}} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-identifier="active" data-type="switch" checked={inputsValues.active || false} onChange={handleInputChange} disabled/>
                        </div>
                }
                
            </div>
        </div>
    )
};

export default ClientInfo;
