const useFormattingFunctions = () => {

    const formatHours = date => {
        if(typeof date !== 'object') date = new Date(date);

        const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        const amPm = date.getHours() < 12 ? 'am' : 'pm';

        return `${hours}:${minutes}${amPm}`;
    }
    
    const formatDate = (date, format) => {
        if(!date) return '';
        const MONTH = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const ORDINAL_INDICATORS = {
            1: 'st',
            2: 'nd',
            3: 'rd',
        };

        if(typeof date !== 'object') date = new Date(date);
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()
        if(format === 'date-written-out') {
            return `${MONTH[month]} ${day + (ORDINAL_INDICATORS[day] || 'th')}, ${year}`           
        } if(format === 'include-time') {
            return `${month + 1}/${day}/${year} ${formatHours(date)}`
        } if(format === 'include-time-written-out') {
            return `${MONTH[month]} ${day + (ORDINAL_INDICATORS[day] || 'th')}, ${year} ${formatHours(date)}`
        }
        else {
            return `${day}/${month + 1}/${year}`
        }
    }

    function sortByDate(array, key) {
        // Use the sort() method to sort the array by the specified key
        array.sort((a, b) => {
            // Convert the values of the specified key to Date objects
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            
            // Compare the dates and return the result
            return dateA - dateB;
        });
        
        // Return the sorted array
        return array;
    }

    return {
        formatDate,
        sortByDate
    }
}

export default useFormattingFunctions;
