import './RequesterModal.scss';

import { useEffect, useState } from 'react';
import { useGlobals } from 'context/globals-context';
import UserRequests from 'api/UserRequests';

const UserModal = ({modal_ref, userData = {}, onUpdatedUser, modalClass_ref, selectedRequester}) => {

    const STATES = {
        'AL':'Alabama',
        'AK':'Alaska',
        'AZ':'Arizona',
        'AR':'Arkansas',
        'CA':'California',
        'CO':'Colorado',
        'CT':'Connecticut',
        'DE':'Delaware',
        'DC':'District of Columbia',
        'FL':'Florida',
        'GA':'Georgia',
        'HI':'Hawaii',
        'ID':'Idaho',
        'IL':'Illinois',
        'IN':'Indiana',
        'IA':'Iowa',
        'KS':'Kansas',
        'KY':'Kentucky',
        'LA':'Louisiana',
        'ME':'Maine',
        'MD':'Maryland',
        'MA':'Massachusetts',
        'MI':'Michigan',
        'MN':'Minnesota',
        'MS':'Mississippi',
        'MO':'Missouri',
        'MT':'Montana',
        'NE':'Nebraska',
        'NV':'Nevada',
        'NH':'New Hampshire',
        'NJ':'New Jersey',
        'NM':'New Mexico',
        'NY':'New York',
        'NC':'North Carolina',
        'ND':'North Dakota',
        'OH':'Ohio',
        'OK':'Oklahoma',
        'OR':'Oregon',
        'PA':'Pennsylvania',
        'RI':'Rhode Island',
        'SC':'South Carolina',
        'SD':'South Dakota',
        'TN':'Tennessee',
        'TX':'Texas',
        'UT':'Utah',
        'VT':'Vermont',
        'VA':'Virginia',
        'WA':'Washington',
        'WV':'West Virginia',
        'WI':'Wisconsin',
        'WY':'Wyoming',
    }

    const { active, city, email, firstName, lastName, phone, state, stateID, title, userID, username, postalCode} = userData;
    const [ inputsValues, SetInputsValues] = useState({});
    const { updateUser } = UserRequests();
    const { showToast } = useGlobals();

    const keys = Object.keys(STATES);

    const stateOptionsJSX = keys.map((key, index) => {
        return (
            <option key={index} value={key}>{STATES[key]}</option>
        )
    })


    const handleInputChange = (e) => {
        const key = e.currentTarget.id;
        const value = e.currentTarget.value;
        if(key === 'stateID') {
            inputsValues['state'] = STATES[value];
        }
        inputsValues[key] = value
        SetInputsValues({...inputsValues});
    }

    const handleUpdate = async () => {
        // inputsValues.password = 'test';
        inputsValues.userRoleList = [
            {
              "userRoleID": 1
            }
        ]
        try {
            await updateUser(inputsValues)
            onUpdatedUser(inputsValues)
        } catch (err) {
            return showToast({
                type: 'danger',
                text: 'Error: User not updated'
            });
        }
    }

    useEffect(() => {
        if(!userData || !Object.keys(userData).length) return
        SetInputsValues({ active, city, email, firstName, lastName, phone, state, stateID, title, userID, username, postalCode })
    }, [userData, active, city, email, firstName, lastName, phone, state, stateID, title, userID, username, postalCode])

    return (
        <div className="requester-modal">
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" ref={modal_ref}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Requester</h1>
                        <button type="button" className="btn-close" aria-label="Close" onClick={e => modalClass_ref.current.hide()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="close-distance"><small>Client name</small></p>
                                <p className="lead">{selectedRequester?.clientName}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="close-distance"><small>First name</small></p>
                                <p className="lead">{inputsValues.firstName}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="close-distance"><small>Last name</small></p>
                                <p className="lead">{inputsValues.lastName}</p>
                            </div>
                            {/* <div className="col-lg-6">
                                <p className="close-distance"><small>Title</small></p>
                                <p className="lead">{inputsValues.title}</p>
                            </div> */}
                            <div className="col-lg-6">
                                <p className="close-distance"><small>E-mail</small></p>
                                <p className="lead">{inputsValues.email}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="close-distance"><small>Phone</small></p>
                                <p className="lead">{inputsValues.phone}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="close-distance"><small>City</small></p>
                                <p className="lead">{inputsValues.city}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="close-distance"><small>State</small></p>
                                <p className="lead">{STATES[inputsValues.stateID]}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="close-distance"><small>Postal code</small></p>
                                <p className="lead">{inputsValues.postalCode}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/* <button className="btn btn-warning" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Edit</button> */}
                    </div>
                    </div>
                </div>
            </div>
            {/* <div className="modal fade" id="exampleModalToggle2" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">Edit User</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <div className='row g-3'>
                        <div className="col-lg-6">
                            <div className="d-flex space-between align-items-center" style={{paddingTop: 0, paddingBottom: '1rem'}}>
                                <label htmlFor="inputEmail4" className="no-shrink mr-1">First name</label>
                                <input type="text" className="form-control w-3" id="firstName" onChange={e => handleInputChange(e)} value={inputsValues.firstName || ''}/>
                            </div>
                            <div className="py-3 d-flex space-between align-items-center">
                                <label htmlFor="inputEmail4" className="no-shrink mr-1">Last name</label>
                                <input type="text" className="form-control w-3" id="lastName" onChange={e => handleInputChange(e)} value={inputsValues.lastName || ''} />
                            </div>
                            <div className="py-3 d-flex space-between align-items-center">
                                <label htmlFor="inputEmail4" className="no-shrink mr-1">Title</label>
                                <input type="text" className="form-control w-3" id="title" onChange={e => handleInputChange(e)} value={inputsValues.title || ''} />
                            </div>
                            <div className="py-3 d-flex space-between align-items-center">
                                <label htmlFor="inputEmail4" className="no-shrink mr-1">email</label>
                                <input type="email" className="form-control w-3" id="email" onChange={e => handleInputChange(e)} value={inputsValues.email || ''} />
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex no-shrink">
                            <div className="list-group w-100">
                                <label className="list-group-item d-flex gap-2">
                                    <input className="form-check-input flex-shrink-0" type="checkbox" value="" />
                                    <span>Admin</span>
                                </label>
                                <label className="list-group-item d-flex gap-2">
                                    <input className="form-check-input flex-shrink-0" type="checkbox" value="" />
                                    <span>Case Manager</span>
                                </label>
                                <label className="list-group-item d-flex gap-2">
                                    <input className="form-check-input flex-shrink-0" type="checkbox" value="" />
                                    <span>Investigator</span>
                                </label>
                                <label className="list-group-item d-flex gap-2">
                                    <input className="form-check-input flex-shrink-0" type="checkbox" value="" />
                                    <span>Requester</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            
                        </div>
                        <hr />
                        <div className="col-lg-6">
                            <label htmlFor="inputEmail4" className="form-label">User name</label>
                            <input type="email" className="form-control" id="username" onChange={e => handleInputChange(e)} value={inputsValues.username || ''} />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="inputEmail4" className="form-label">Make sure it's unique</label><br />
                            <button type="button" className="btn btn-primary">Validate</button>
                        </div>
                        <hr />
                        <div className="col-lg-6">
                            <label className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" id="phone" onChange={e => handleInputChange(e)} value={inputsValues.phone || ''}/>
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label">Address 1</label>
                            <input type="text" className="form-control" id="address1" onChange={e => handleInputChange(e)} value={inputsValues.address1 || ''} />
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label">Address 2</label>
                            <input type="text" className="form-control" id="address2" onChange={e => handleInputChange(e)} value={inputsValues.address2 || ''} />
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label">City</label>
                            <input type="text" className="form-control" id="city" onChange={e => handleInputChange(e)} value={inputsValues.city || ''} />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="inputEmail4" className="form-label">State</label>
                            <select className="form-select" aria-label="Default select example" id="stateID" onChange={e => handleInputChange(e)} value={inputsValues.stateID || ''}>
                                {stateOptionsJSX}
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label">ZIP</label>
                            <input type="tel" className="form-control" id="zip" onChange={e => handleInputChange(e)} value={inputsValues.zip || ''}/>
                        </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Cancel</button>
                        <button className="btn btn-success" data-bs-target="#exampleModalToggle2" data-bs-dismiss="modal" onClick={ e => handleUpdate()}>Accept</button>
                    </div>
                </div>
                </div>
            </div> */}
        </div>
    )
}

export default UserModal;