import { useGlobals } from "context/globals-context";

const TemplateRequests = () => {

    const { fetchData, templateEndpoints, jwt, showToast } = useGlobals();

    const getTemplateList = async () => {
        try {
            const data = await fetchData(templateEndpoints.getTemplateList, {
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            return err
        }
    }
    
    const getTemplateTypeList = async () => {
        try {
            const data = await fetchData(templateEndpoints.getTemplateTypeList, {
                headers: {
                    'accept': 'text/plain',
                    'Authorization': jwt
                }
            })
            return data;
        } catch (err) {
            return err
        }
    }

    const addTemplate = async (payload, successMessage = 'Changes stored successfully', errorMessage = 'There was an error processing your request') => {
        try {
            const data = await fetchData(templateEndpoints.addTemplate, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(payload)
            });
            showToast({
                type: 'success',
                text: successMessage
            });
            return data;
        } catch (err) {
            showToast({
                type: 'danger',
                text: errorMessage
            });
            throw new Error(err.message);
        }
    }

    const updateTemplate = async (payload, successMessage = 'Changes stored successfully', errorMessage = 'There was an error processing your request') => {
        try {
            const data = await fetchData(templateEndpoints.updateTemplate, {
                method: "POST",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    'Authorization': jwt
                },
                body: JSON.stringify(payload)
            });
            showToast({
                type: 'success',
                text: successMessage
            });
            return data;
        } catch (err) {
            showToast({
                type: 'danger',
                text: errorMessage
            });
            throw new Error(err.message);
        }
    }
    
    return {
        getTemplateList,
        getTemplateTypeList,
        addTemplate,
        updateTemplate
    }
};

export default TemplateRequests;
