import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useGlobals } from 'context/globals-context';

import "./Navbar.scss";

const Navbar = () => {
    
    const NO_NAVBAR_URLS = [
        'new-password',
        'reset-password',
    ]

    const VIEW_ROUTES = [
        'clients-list',
        'users-list',
        'services-list',
        'casestatus-list',
        'requester-list',
        'template-list',
        'vendor-list',
        'servicerequest-list',
        'flags',
        'states',
        
    ];

    const { deleteToken, myData, navigateToDefaultDashboard, toggleTheme, theme } = useGlobals();
    const navigate = useNavigate();
    const location = useLocation();

    const [ searchQuery, SetSearchQuery ] = useState('');

    const isSelected = (path) => {
        return location.pathname === path
    };

    const handleNavbarBrandClick = e => {
        const clickedOnNavbarBrand = e.target.classList.contains('dropdown');
        if(clickedOnNavbarBrand) navigateToDefaultDashboard(true);
    };

    return (
        <>
            {
                location.pathname === '/' || NO_NAVBAR_URLS.some(string => location.pathname.includes(string)) ? (
                    <nav id="contego-navbar" className="navbar" onClick={e => navigate('/')}>
                        <div className="container">
                            <span className="navbar-brand cursor--pointer">
                                <img src="images/contego--black.svg" alt="Bootstrap" height="30" />
                            </span>
                        </div>
                    </nav>
                ) : (
                    <nav className="navbar navbar-expand-lg navbar-light bg-light mb-2">
                        <div className="container-fluid">
                            {
                                myData?.userDashboardList.length === 1 ? (
                                    <span className={`nav-link px-2 cursor--pointer link-body-emphasis navbar-brand ${location.pathname.includes('/dashboard-') ? 'selected' : ''}`}>
                                        <img src={location.pathname.includes('/dashboard-') ? 'images/contego--yellow.svg' : 'images/contego--black.svg'} alt="Bootstrap" height="30" onClick={e => navigate(`/dashboard-${myData?.userDashboardList[0].dashboard.dashboardName}`)} />
                                    </span >
                                ) : (
                                    <div className="dropdown" onClick={e => handleNavbarBrandClick(e)}>
                                        <span className={`nav-link px-2 cursor--pointer link-body-emphasis dropdown-toggle navbar-brand ${location.pathname.includes('/dashboard-') ? 'selected' : ''}`}>
                                            <img src={location.pathname.includes('/dashboard-') ? 'images/contego--yellow.svg' : 'images/contego--black.svg'} alt="Bootstrap" height="30" />
                                        </span >
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {
                                                myData?.userDashboardList.map((dashBoardData, index) => {
                                                    const { dashboard } = dashBoardData;
                                                    return <li key={index}><span className={`dropdown-item cursor--pointer ${isSelected(`/dashboard-${dashboard.dashboardName}`) ? 'selected' : ''}`} onClick={e => navigate(`/dashboard-${dashboard.dashboardName}`)}>{dashboard.dashboardName}</span></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                )
                            }
                            
                        
                            {   !location.pathname.includes('new-user')
                                ? <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                                    {/* <li><span onClick={e => navigate('/dashboard')} className="nav-link px-2 link-secondary cursor--pointer">Dashboard</span></li> */}
                                    <div className="dropdown">
                                        <span className={`nav-link cursor--pointer px-2 link-body-emphasis dropdown-toggle ${VIEW_ROUTES.includes(location.pathname.substring(1)) ? 'selected' : ''}`}>View</span>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/clients-list') ? 'selected' : ''}`} onClick={e => navigate('/clients-list')}>Clients</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/users-list') ? 'selected' : ''}`} onClick={e => navigate('/users-list')}>User</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/services-list') ? 'selected' : ''}`} onClick={e => navigate('/services-list')}>Service</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/casestatus-list') ? 'selected' : ''}`} onClick={e => navigate('/casestatus-list')}>Case Status</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/requester-list') ? 'selected' : ''}`} onClick={e => navigate('/requester-list')}>Requester</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/template-list') ? 'selected' : ''}`} onClick={e => navigate('/template-list')}>Templates</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/vendor-list') ? 'selected' : ''}`} onClick={e => navigate('/vendor-list')}>Vendor</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/servicerequest-list') ? 'selected' : ''}`} onClick={e => navigate('/servicerequest-list')}>Service Request</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/flags') ? 'selected' : ''}`} onClick={e => navigate('/flags')}>Flags</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/states') ? 'selected' : ''}`} onClick={e => navigate('/states')}>States</span></li>
                                            <li><span className={`dropdown-item cursor--pointer ${isSelected('/task-list') ? 'selected' : ''}`} onClick={e => navigate('/task-list')}>Tasks</span></li>
                                        </ul>
                                    </div>
                                    <li><span onClick={e => navigate('/new-case')} className={`nav-link px-2 link-body-emphasis cursor--pointer ${isSelected('/new-case') ? 'selected' : ''}`}>New Case</span></li>
                                    <li><span onClick={e => navigate('/calendar')} className={`nav-link px-2 link-body-emphasis cursor--pointer ${isSelected('/calendar') ? 'selected' : ''}`}>Calendar</span></li>
                                    <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3 d-flex" style={{columnGap: '10px'}} role="search">
                                        <input type="search" value={searchQuery} className="form-control" placeholder="Search..." aria-label="Search" onChange={e => SetSearchQuery(e.currentTarget.value)} onKeyDown={e => {
                                            if(e.key === 'Enter') {
                                                navigate(`/search-page?query=${encodeURIComponent(searchQuery)}`);
                                                SetSearchQuery('');
                                            };
                                        }}/>
                                        <button type="button" className="btn btn-outline-secondary" onClick={e => {
                                            navigate(`/search-page?query=${encodeURIComponent(searchQuery)}`)
                                            SetSearchQuery('');
                                        }}>Go</button>
                                    </form>
                                </ul>
                                : ''
                            }

                            <div className={`theme-toggle ${theme}`} onClick={toggleTheme}>
                                <div className="button"></div>
                                <i id="sun" className="bi bi-brightness-high-fill"></i>
                                <i id="moon" className="bi bi-moon"></i>
                            </div>
        
                            <button id="bell" type="button" className="btn position-relative" style={{marginRight: '15px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
                                </svg>
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    99+
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                            </button>
        
                            <div className="dropdown">
                                <span className={`nav-link px-2 link-body-emphasis dropdown-toggle`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-circle" style={{cursor: 'pointer'}} viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                                    </svg>
                                </span >
                                <ul className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                                        <li className='border-bottom'><span className='dropdown-item cursor--pointer' onClick={e => null}>My profile</span></li>
                                        <li className='border-bottom'>
                                            <a target="_blank"
                                                rel="noreferrer"
                                                href="https://drive.google.com/file/d/1b3A5xuJpyscbHCWyMuQcPp2c6p0pJuGP/view"
                                                className='dropdown-item cursor--pointer'
                                                onClick={e => null}>Download app</a>
                                        </li>                                        
                                    {/*<li className='border-bottom'><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1gt-QGozT3SaZNfgzKlZayVnPJoPBl-Z3/view?usp=sharing" className='dropdown-item cursor--pointer' onClick={e => null}>Download app</a></li>*/}
                                    <li className='border-bottom'><span className='dropdown-item cursor--pointer'  onClick={e => deleteToken()}>Logout</span></li>
                                </ul>
                            </div>
                            
                            <div className="right--container">
                                
                                
                            </div>
                        </div>
                    </nav>
                )
            }
        </>
    )
}

export default Navbar;