import { useSearchParams } from "react-router-dom";
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import { useGlobals } from "context/globals-context";
import Instructions from "./components/instructions/Instructions";
import CaseInfo from "./components/caseInfo/CaseInfo";
import SubjectInfo from "./components/subjectInfo/SubjectInfo";
import SubjectDescription from "./components/subjectDescription/SubjectDescription";
import Address from "./components/address/Address";
import PhoneNumbers from "./components/phoneNumbers/PhoneNumbers";
import ContactInfo from "./components/contactInfo/ContactInfo";
import Media from "./components/media/Media";
import CaseActivity from "./components/caseActivity/CaseActivity";
import NewResourceModal from "./components/newResourceModal/NewResourceModal";
import CaseRequests from "api/CaseRequests";
import Resources from "./components/resources/Resources";
import Contacts from "./components/contacts/Contacts";

import './Case.scss';
import { useEffect, useRef, useState } from "react";

const Case = () => {

    const BOTTOM_TABS_WITH_NEW_BUTTON = [
        'case-activity',
        'media',
        'resources',
    ]

    const [ searchParams ] = useSearchParams();
    const { getCaseList, getCaseStatusList, updateCaseStatus } = CaseRequests();
    const { showToast, SetRedirectURL, myData, navigateToDefaultDashboard } = useGlobals();

    const [ caseStatusList, SetCaseStatusList ] = useState([]);
    const [ loadingCaseStatus, SetLoadingCaseStatus ] = useState(true);
    
    const [ selectedBottomTab, SetSelectedBottomTab ] = useState('case-activity');
    const [ selectedCase, SetSelectedCase ] = useState();

    const [ resourcesList, SetResourcesList ] = useState([]);

    const modal_ref = useRef();
    const bottomTabsBootstrapClasses_ref = useRef({});
    
    const handleCaseStatusSelection = async selectedCaseStatusID => {
        try {
            selectedCaseStatusID = Number(selectedCaseStatusID);
            SetLoadingCaseStatus(true);
            await updateCaseStatus(caseMasterID, selectedCaseStatusID);
            SetLoadingCaseStatus(false);
            SetSelectedCase(current => {
                return {...current, caseStatusID: selectedCaseStatusID}
            });
            showToast({
                type: 'success',
                text: 'Case status updated successfully'
            });
        } catch (err) {
            SetLoadingCaseStatus(false);
            showToast({
                type: 'danger',
                text: 'There was an error while updating the case status'
            });
            console.error(err);
        }
    }

    const caseMasterID = searchParams.get("id");

    useEffect(() => {
        
        const loadCase = async () => {
            try {
                const response = await getCaseList(caseMasterID);
                if(!response.caseMasterID) throw new Error("No case MasterID found");
                SetSelectedCase(response);
            } catch (err) {
                console.log(err)
                const error = JSON.parse(err.message);
                if(error.message === 'Unauthorized') showToast({
                    type: 'warning',
                    text: 'You must be logged in first'
                });
                 else showToast({
                    type: 'danger',
                    text: 'There was an error while loading the selected case'
                });
                if(caseMasterID) SetRedirectURL(`case?id=${caseMasterID}`);

                navigateToDefaultDashboard();
            }
        };

        const loadCaseStatusList = async () => {
            try {
                const response = await getCaseStatusList();
                if(!response.length) throw new Error('No status list retrieved from server');
                SetCaseStatusList(response);
                SetLoadingCaseStatus(false);
            } catch (err) {
                const error = JSON.parse(err.message);
                if(error.message !== 'Unauthorized') return showToast({
                    type: 'danger',
                    text: 'There was an error while loading the status list'
                });
            }
        }

        loadCase();
        loadCaseStatusList();

        bottomTabsBootstrapClasses_ref.current['resources'] = new bootstrapBundleMin.Modal(modal_ref.current, {
            // keyboard: false
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myData])

    return (
        <div className="case container-fluid">
            <div className="p-3 bg-body-tertiary rounded-3">
                {
                    !selectedCase ? (
                        <div className="spinner--container">
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="head">
                                <h3 className="pb-2">Case number: {selectedCase.caseNumber || 'Not found'}</h3>
                                <div className="options">
                                    <button type="button" style={{flexBasis: '150px', alignSelf: 'flex-start'}} className="btn btn-warning" onClick={e => showToast({
                                        type: 'info',
                                        text: 'This functionality is currently not available in this demo'
                                    })}>Edit</button>
                                    
                                    <div className="dropdown">
                                        <span className="nav-link px-2 link-body-emphasis dropdown-toggle" style={{marginTop: '6px'}}>Case Menu</span >
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li><span className='dropdown-item cursor--pointer' onClick={e => null}>Create related case</span></li>
                                            <li><span className='dropdown-item cursor--pointer' onClick={e => null}>E-mail</span></li>
                                            <li><span className='dropdown-item cursor--pointer' onClick={e => null}>Surveillance report</span></li>
                                            <li><span className='dropdown-item cursor--pointer' onClick={e => null}>Print</span></li>
                                        </ul>
                                    </div>
                                    <div className="case-status-select--container">
                                        <select className="form-select" id="sales-rep" value={selectedCase.caseStatusID} onChange={e => handleCaseStatusSelection(e.currentTarget.value)}>
                                            {
                                                loadingCaseStatus ? <option key="loading">Loading</option>
                                                : caseStatusList.map((caseStatus, index) => (
                                                    <option key={index} value={caseStatus.caseStatusID}>{caseStatus.caseStatusName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                    
                            <ul className="nav nav-tabs">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id='case-info' data-bs-toggle="tab" data-bs-target="#case-info-tab-pane" type="button" role="tab" aria-controls="case-info-tab-pane" aria-selected="true">Case Info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='subject-info' data-bs-toggle="tab" data-bs-target="#subject-info-tab-pane" type="button" role="tab" aria-controls="subject-info-tab-pane" aria-selected="true">Subject Info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='subject-description' data-bs-toggle="tab" data-bs-target="#subject-description-tab-pane" type="button" role="tab" aria-controls="subject-description-tab-pane" aria-selected="true">Subject Description</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='address' data-bs-toggle="tab" data-bs-target="#address-tab-pane" type="button" role="tab" aria-controls="address-tab-pane" aria-selected="true">Addresses</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='phone-numbers' data-bs-toggle="tab" data-bs-target="#phone-numbers-tab-pane" type="button" role="tab" aria-controls="phone-numbers-tab-pane" aria-selected="true">Phone Numbers</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='contact-info' data-bs-toggle="tab" data-bs-target="#contact-info-tab-pane" type="button" role="tab" aria-controls="contact-info-tab-pane" aria-selected="false">Contact info</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='contacts' data-bs-toggle="tab" data-bs-target="#contacts-tab-pane" type="button" role="tab" aria-controls="contacts-tab-pane" aria-selected="false">Contacts</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id='instructions' data-bs-toggle="tab" data-bs-target="#instructions-tab-pane" type="button" role="tab" aria-controls="instructions-tab-pane" aria-selected="false">Instructions</button>
                                </li>
                            </ul>
                            <div className="tab-content mb-3" id="myTabContent">
                                <div className="tab-pane fade show active" id="case-info-tab-pane" role="tabpanel" aria-labelledby="case-info-tab" tabIndex="0">
                                    <CaseInfo selectedCase={selectedCase}/>
                                </div>
                                <div className="tab-pane fade" id="subject-info-tab-pane" role="tabpanel" aria-labelledby="subject-info-tab" tabIndex="0">
                                    <SubjectInfo selectedCase={selectedCase}/>
                                </div>
                                <div className="tab-pane fade" id="subject-description-tab-pane" role="tabpanel" aria-labelledby="subject-description-tab" tabIndex="0">
                                    <SubjectDescription selectedCase={selectedCase}/>
                                </div>
                                <div className="tab-pane fade" id="address-tab-pane" role="tabpanel" aria-labelledby="address-tab" tabIndex="0">
                                    <Address selectedCase={selectedCase}/>
                                </div>
                                <div className="tab-pane fade" id="phone-numbers-tab-pane" role="tabpanel" aria-labelledby="phone-numbers-tab" tabIndex="0">
                                    <PhoneNumbers selectedCase={selectedCase}/>
                                </div>
                                <div className="tab-pane fade" id="contact-info-tab-pane" role="tabpanel" aria-labelledby="contact-info-tab" tabIndex="0">
                                    <ContactInfo selectedCase={selectedCase}/>
                                </div>
                                <div className="tab-pane fade" id="contacts-tab-pane" role="tabpanel" aria-labelledby="contacts-tab-pane" tabIndex="0">
                                    <Contacts />
                                </div>
                                <div className="tab-pane fade" id="instructions-tab-pane" role="tabpanel" aria-labelledby="instructions-tab-pane" tabIndex="0">
                                    <Instructions selectedCase={selectedCase}/>
                                </div>
                            </div>

                            <ul className="nav nav-tabs">
                                <li className="nav-item" role="presentation">
                                    <button onClick={e => SetSelectedBottomTab('case-activity')} className="nav-link active" id='case-activity' data-bs-toggle="tab" data-bs-target="#case-activity-tab-pane" type="button" role="tab" aria-controls="case-activity-tab-pane" aria-selected="true">Case Activity</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={e => SetSelectedBottomTab('media')} className="nav-link" id='media' data-bs-toggle="tab" data-bs-target="#media-tab-pane" type="button" role="tab" aria-controls="media-tab-pane" aria-selected="false">Media</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={e => SetSelectedBottomTab('resources')} className="nav-link" id='resources' data-bs-toggle="tab" data-bs-target="#resources-tab-pane" type="button" role="tab" aria-controls="resources-tab-pane" aria-selected="false">Resources</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={e => SetSelectedBottomTab('expenses')} className="nav-link" id='expenses' data-bs-toggle="tab" data-bs-target="#expenses-tab-pane" type="button" role="tab" aria-controls="expenses-tab-pane" aria-selected="false">Expenses</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button onClick={e => SetSelectedBottomTab('billing')} className="nav-link" id='billing' data-bs-toggle="tab" data-bs-target="#billing-tab-pane" type="button" role="tab" aria-controls="billing-tab-pane" aria-selected="false">Billing</button>
                                </li>

                                {
                                    BOTTOM_TABS_WITH_NEW_BUTTON.includes(selectedBottomTab) ? <button id="new-button" type="button" className="btn btn-secondary" onClick={e => bottomTabsBootstrapClasses_ref.current[selectedBottomTab]?.show()}>New</button> : ''
                                }
                            </ul>
                            <div className="tab-content min-h-500" id="myTabContent-case-data">
                                <div className="tab-pane fade show active" id="case-activity-tab-pane" role="tabpanel" aria-labelledby="case-activity-tab" tabIndex="0">
                                    <CaseActivity caseMasterID={caseMasterID} selectedTab={selectedBottomTab} />
                                </div>
                                <div className="tab-pane fade" id="media-tab-pane" role="tabpanel" aria-labelledby="media-tab" tabIndex="0">
                                    <Media caseMasterID={caseMasterID} selectedTab={selectedBottomTab} />
                                </div>
                                <div className="tab-pane fade" id="resources-tab-pane" role="tabpanel" aria-labelledby="resources-tab" tabIndex="0">
                                    <Resources caseMasterID={caseMasterID} selectedTab={selectedBottomTab} resourcesList={resourcesList} setResourcesList={SetResourcesList} />
                                </div>
                                <div className="tab-pane fade" id="expenses-tab-pane" role="tabpanel" aria-labelledby="expenses-tab" tabIndex="0">
                                    
                                </div>
                                <div className="tab-pane fade" id="billing-tab-pane" role="tabpanel" aria-labelledby="billing-tab" tabIndex="0">
                                    
                                </div>
                            </div>
                        </>
                    )
                }
                
                </div>

            <NewResourceModal modal_ref={modal_ref} caseMasterID={caseMasterID} bottomTabsBootstrapClasses_ref={bottomTabsBootstrapClasses_ref} setResourcesList={SetResourcesList}/>
        </div>
    )
}

export default Case;