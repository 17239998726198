
const Addresses = ({addressesList, setAddressesList, stage = 'editing'}) => {

    const STATES = {
        '':'',
        'AL':'Alabama',
        'AK':'Alaska',
        'AZ':'Arizona',
        'AR':'Arkansas',
        'CA':'California',
        'CO':'Colorado',
        'CT':'Connecticut',
        'DE':'Delaware',
        'DC':'District of Columbia',
        'FL':'Florida',
        'GA':'Georgia',
        'HI':'Hawaii',
        'ID':'Idaho',
        'IL':'Illinois',
        'IN':'Indiana',
        'IA':'Iowa',
        'KS':'Kansas',
        'KY':'Kentucky',
        'LA':'Louisiana',
        'ME':'Maine',
        'MD':'Maryland',
        'MA':'Massachusetts',
        'MI':'Michigan',
        'MN':'Minnesota',
        'MS':'Mississippi',
        'MO':'Missouri',
        'MT':'Montana',
        'NE':'Nebraska',
        'NV':'Nevada',
        'NH':'New Hampshire',
        'NJ':'New Jersey',
        'NM':'New Mexico',
        'NY':'New York',
        'NC':'North Carolina',
        'ND':'North Dakota',
        'OH':'Ohio',
        'OK':'Oklahoma',
        'OR':'Oregon',
        'PA':'Pennsylvania',
        'RI':'Rhode Island',
        'SC':'South Carolina',
        'SD':'South Dakota',
        'TN':'Tennessee',
        'TX':'Texas',
        'UT':'Utah',
        'VT':'Vermont',
        'VA':'Virginia',
        'WA':'Washington',
        'WV':'West Virginia',
        'WI':'Wisconsin',
        'WY':'Wyoming',
    };

    const keys = Object.keys(STATES);

    const stateOptionsJSX = keys.map((key, index) => {
        return (
            <option key={index} value={key}>{STATES[key]}</option>
        )
    });

    const handleAddNewAddressBlock = () => setAddressesList(current => {
        const thereIsAlreadyAPrimaryAddress = current.some(addressBlock => addressBlock.primary);
        return [
            ...current,
            {
                addressName: '',
                address1: '',
                address2: '',
                city: '',
                stateID: '',
                postalCode: '',
                phone: '',
                phoneExt: '',
                primary: thereIsAlreadyAPrimaryAddress ? false : true,
                active: true,
            }
        ]
    });

    const handleDefaultAddressSelection = (index) => {
        setAddressesList(current => {
            current.forEach((addressBlock, _index) => {
                if(_index !== index) addressBlock.primary = false;
                else addressBlock.primary = true
            });
            return [...current];
        });
    };

    const handleAddressListInputChange = (e, index) => {
        const type  = e.currentTarget.dataset.type;
        const value = type === 'switch' ? e.currentTarget.checked : e.currentTarget.value;
        const key   = e.currentTarget.dataset.identifier;

        addressesList[index][key] = value;

        setAddressesList([...addressesList]);
    };

    const handleRemoveAddressBlock = (index) => {
        addressesList.splice(index, 1);
        if(addressesList.length === 1) {
            addressesList[0].primary = true;
            addressesList[0].active = true;
        };
        setAddressesList([...addressesList]);
    };

    return (
        <>
            {
                addressesList.map((_, index) => (
                    <div key={index} className='row g-3' data-index={index} style={{position: 'relative'}}>
                        <div className="col-lg-8">
                            <label className="form-label">Address 1<span className="mandatory">*</span></label>
                            {
                                stage === 'editing' ? (
                                    <input type="text" className="form-control" data-identifier="address1" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].address1 || ''} />
                                ) : <p className="lead">{addressesList[index].address1 || ''}</p>
                            }
                        </div>
                        <div className="col-lg-4 d-grid">
                                {
                                    stage === 'editing' ? (
                                        <>
                                            <input type="radio" className="btn-check" name="radio-button" id={`address-${index}`} onChange={e => handleDefaultAddressSelection(index)} autoComplete="off" checked={addressesList[index].primary || false} />
                                            <label className="btn btn-outline-primary" htmlFor={`address-${index}`} style={{alignSelf: 'end'}}>Make primary address</label>
                                        </>
                                    ) : addressesList[index].primary ? (
                                        <div className="alert alert-secondary" role="alert" style={{alignSelf: 'end', padding: '10px'}}>
                                            Primary address
                                        </div>
                                    ) : ''
                                }
                            
                        </div>
                        <div className="col-lg-8">
                            <label className="form-label">Address 2</label>
                            {
                                stage === 'editing' ? (
                                    <input type="text" className="form-control" data-identifier="address2" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].address2 || ''} />
                                ) : <p className="lead">{addressesList[index].address2 || ''}</p>
                            }
                        </div>
                        <div className="col-lg-2 mb-3">
                            <label className="form-label">Active</label>
                            {
                                stage === 'editing' ? (
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-identifier="active" data-type="switch" checked={addressesList[index].active || false} onChange={e => handleAddressListInputChange(e, index)}/>
                                    </div>
                                ) : <div className="form-check form-switch">
                                        <input style={{opacity: 1}} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-identifier="active" data-type="switch" checked={addressesList[index].active || false} onChange={e => null} disabled/>
                                    </div>
                            }
                            
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label">City</label>
                            {
                                stage === 'editing' ? (
                                    <input type="text" className="form-control" data-identifier="city" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].city || ''} />
                                ) : <p className="lead">{addressesList[index].city || ''}</p>
                            }
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="inputEmail4" className="form-label">State</label>
                            {
                                stage === 'editing' ? (
                                    <select className="form-select" aria-label="Default select example" data-identifier="stateID" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].stateID || ''}>
                                        {stateOptionsJSX}
                                    </select>
                                ) : <p className="lead">{STATES[addressesList[index].stateID] || ''}</p>
                            }
                            
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label">Postal Code</label>
                            {
                                stage === 'editing' ? (
                                    <input type="text" className="form-control" data-identifier="postalCode" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].postalCode || ''} />
                                ) : <p className="lead">{addressesList[index].postalCode || ''}</p>
                            }
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label">Phone number</label>
                            {
                                stage === 'editing' ? (
                                    <input data-identifier="phone" type="tel" className="form-control" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].phone || ''} />
                                ) : <p className="lead">{addressesList[index].phone || ''}</p>
                            }
                        </div>
                        <div className="col-lg-4">
                            <label className="form-label">Phone extension</label>
                            {
                                stage === 'editing' ? (
                                    <input type="number" className="form-control" data-identifier="phoneExt" onChange={e => handleAddressListInputChange(e, index)} value={addressesList[index].phoneExt || ''} />
                                ) : <p className="lead">{addressesList[index].phoneExt || ''}</p>
                            }                         
                        </div>
                        {
                            !addressesList[index].clientAddressID && addressesList.length !== 1 && stage === 'editing' && (
                                <div style={{position: 'absolute', top: '-8px', right: 5, display: 'inline-block', width: 'fit-content'}}>
                                    <svg style={{display: 'inline-block'}} onClick={e => handleRemoveAddressBlock(index)} className="cursor--pointer bi bi-x-circle" mlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#db291d" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                    </svg>
                                </div>
                            )
                        }
                        <hr />
                    </div>
                ))
            }
            {
                stage === 'editing' && (
                    <div className="row d-grid px-4">
                        <button className="btn btn-primary" type="button" onClick={handleAddNewAddressBlock}>Add new address</button>
                    </div>
                )
            }
        </>
    )
};

export default Addresses;
