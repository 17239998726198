import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({value, setValue, readOnly}) => {

    const MODULES = {
        toolbar: {
          container: [
            [{ 'font': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // ['link', 'image', 'video'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            ['clean']                                         // remove formatting button
          ],
        },
    };

    const FORMATS = [
        'font',
        'size',
        'bold', 'italic', 'underline', 'strike', 'color', 'background',
        'list', 'bullet',
        'indent', 'align',
        // 'link', 'image', 'video'
    ];
    
    return (
        <div className={readOnly ? 'cursor--not-allowed' : ''}>
            <div className={readOnly ? 'pointer-events--none' : ''}>
                <ReactQuill theme="snow"
                    value={value}
                    modules={MODULES}
                    formats={FORMATS}
                    onChange={setValue}
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
};

export default TextEditor;
