import React from "react";

const Address = ({selectedCase}) => {
console.log(selectedCase)
    const STATES = {
        '':'',
        'AL':'Alabama',
        'AK':'Alaska',
        'AZ':'Arizona',
        'AR':'Arkansas',
        'CA':'California',
        'CO':'Colorado',
        'CT':'Connecticut',
        'DE':'Delaware',
        'DC':'District of Columbia',
        'FL':'Florida',
        'GA':'Georgia',
        'HI':'Hawaii',
        'ID':'Idaho',
        'IL':'Illinois',
        'IN':'Indiana',
        'IA':'Iowa',
        'KS':'Kansas',
        'KY':'Kentucky',
        'LA':'Louisiana',
        'ME':'Maine',
        'MD':'Maryland',
        'MA':'Massachusetts',
        'MI':'Michigan',
        'MN':'Minnesota',
        'MS':'Mississippi',
        'MO':'Missouri',
        'MT':'Montana',
        'NE':'Nebraska',
        'NV':'Nevada',
        'NH':'New Hampshire',
        'NJ':'New Jersey',
        'NM':'New Mexico',
        'NY':'New York',
        'NC':'North Carolina',
        'ND':'North Dakota',
        'OH':'Ohio',
        'OK':'Oklahoma',
        'OR':'Oregon',
        'PA':'Pennsylvania',
        'RI':'Rhode Island',
        'SC':'South Carolina',
        'SD':'South Dakota',
        'TN':'Tennessee',
        'TX':'Texas',
        'UT':'Utah',
        'VT':'Vermont',
        'VA':'Virginia',
        'WA':'Washington',
        'WV':'West Virginia',
        'WI':'Wisconsin',
        'WY':'Wyoming',
    };

    return (
        <div className="bg-body border-left border-right border-bottom p-3">
            {
                selectedCase?.caseAddressList?.map((data, index) => (
                    <React.Fragment key={index}>
                        <div className="row">
                            <div className="col-lg-4">
                                <small>Address name</small>
                                <p className="lead">{`${data.addressName}` || ''}</p>
                            </div>
                            <div className="col-lg-4">
                                {/* <label className="form-label">Address</label>
                                <input type="text" className="form-control" id="primary-address"/> */}
                                <small>Address 1</small>
                                <p className="lead">{`${data.address1}` || ''}</p>
                            </div>
                            <div className="col-lg-4">
                                {/* <label className="form-label">Address</label>
                                <input type="text" className="form-control" id="primary-address"/> */}
                                <small>Address 2</small>
                                <p className="lead">{`${data.address2}` || ''}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                {/* <label className="form-label">City</label>
                                <input type="text" className="form-control" id="primary-city"/> */}
                                <small>City</small>
                                <p className="lead">{`${data.city}` || ''}</p>
                            </div>
                            <div className="col-lg-4">
                                {/* <label className="form-label">State</label>
                                <input type="text" className="form-control" id="primary-state"/> */}
                                <small>State</small>
                                <p className="lead">{`${STATES[data.stateID]}` || ''}</p>
                            </div>
                            <div className="col-lg-4">
                                {/* <label className="form-label">Postal Code</label>
                                <input type="text" className="form-control" id="primary-PostalCode"/> */}
                                <small>Postal code</small>
                                <p className="lead">{`${data.postalCode}` || ''}</p>
                            </div>
                        </div>
                        <hr className="row" />
                        <hr className="row" />
                    </React.Fragment>
                ))
            }
        </div>
    )
}

export default Address;