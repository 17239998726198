const SubjectDescription = ({updateValue}) => {
    return (
        <>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Gender</label>
                    <select className="form-select" id="gender" onChange={e => updateValue(e)} data-identifier="gender">
                        <option value="">Select one</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Ethnicity</label>
                    <select className="form-select" id="ethnicity" onChange={e => updateValue(e)} data-identifier="ethnicity">
                        <option value="">Select one</option>
                        <option value="caucasian">Caucasian</option>
                        <option value="african">African American</option>
                        <option value="hispanic">Hispanic or latino</option>
                        <option value="asian">Asian</option>
                        <option value="indian">Indian</option>
                    </select>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Eye color</label>
                    <select className="form-select" id="eye-color" onChange={e => updateValue(e)} data-identifier="eyeColor">
                        <option value="">Select one</option>
                        <option value="brown">Brown</option>
                        <option value="blue">Blue</option>
                        <option value="hazel">Hazel</option>
                        <option value="green">Green</option>
                    </select>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Hair color</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="hairColor" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Hair length</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="hairLength" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Facial hair</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="facialHair" />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Height</label>        
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control no-spin-button" onChange={e => updateValue(e)} data-identifier="heightFeet"/>
                        <label htmlFor="feet">Feet</label>
                    </div>
                </div>
                <div className="col-lg-4 mb-3" style={{alignSelf: 'self-end'}}>
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control no-spin-button" onChange={e => updateValue(e)} data-identifier="heightInches"/>
                        <label htmlFor="inches">Inches</label>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Weight</label>        
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control no-spin-button" id="pounds" onChange={e => updateValue(e)} data-identifier="weight"/>
                        <label htmlFor="pounds">Lbs.</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Driver's license</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="driversLicense" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Budget</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="budgetMoney" />
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Budget hours</label>
                    <input type="text" className="form-control" onChange={e => updateValue(e)} data-identifier="budgetMoney" />
                </div>
            </div>
        </>
    )
};

export default SubjectDescription;
