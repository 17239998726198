import { useGlobals } from "context/globals-context";

const DashboardRequests = () => {

    const { dashboardEndpoints, jwt, fetchData } = useGlobals();

    const getDashboardList = async () => {

        try {
            const data = await fetchData(dashboardEndpoints.getDashboardList, {
                headers: {
                    'Authorization': jwt,
                },
            })
            return data;
        } catch (err) {
            throw new Error(err.message)
        }
    }
    
    return {
        getDashboardList,

    }
}

export default DashboardRequests;