import { useState, useEffect, useRef } from 'react';
import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import { useGlobals } from 'context/globals-context';
import UserRequests from 'api/UserRequests';
import DashboardRequests from 'api/DashboardRequests';

import Table from 'components/table/Table';
import UserModal from './components/userModal/UserModal';
import NewUserModal from './components/newUserModal/NewUserModal';
import Pagination from 'components/pagination/Pagination';

const User = () => {

    const { getUserList, getRolesList, getPermissionList } = UserRequests();
    const { getDashboardList } = DashboardRequests();

    const { jwt, redirect, showToast } = useGlobals();
    const [ usersList, SetUsersList ] = useState([]);
    const [ roleList, SetRoleList ] = useState([]);
    const [ selectedRoleId, SetSelectedRoleId] = useState('all');
    const [ loading, SetLoading ] = useState(true);
    const [ selectedUser, SetSelectedUser ] = useState();
    const [ dashboardList, SetDashboardList ] = useState([]);
    const [ permissionList, SetPermissionList ] = useState([]);
    //Navigation states

    const RESULTS_PER_PAGE_OPTIONS = [ 10, 20, 40];
    const [ resultsPerPage, SetResultsPerPage ] = useState(RESULTS_PER_PAGE_OPTIONS[0]);
    const [ currentPage, SetCurrentPage ] = useState(1);
    const [ totalRowCount, SetTotalRowCount ] = useState(RESULTS_PER_PAGE_OPTIONS[0]);

    const modalRef = useRef();
    const modalClassRef = useRef();
    const newUserModalRef = useRef();
    const newUserModalClassRef = useRef();
    
    const TABLE_STRUCTURE = [
        {
            header: 'Name',
            propertyNames: ['firstName', 'lastName'],
        },
        {
            header: 'e-mail',
            propertyName: 'email',
        },
        {
            header: 'Address',
            propertyName: 'address1',
        },
        {
            header: 'Phone',
            propertyName: 'phone',
        },
    ];

    const handleUserClick = (e) => {
        const selectedUserId = e.currentTarget.dataset.rowId;
        SetSelectedUser(usersList.find(currentUser => currentUser.userID ===  selectedUserId));
        modalClassRef.current.show()
    };

    const handleUpdatedUser = (updatedUserData) => {
        const userId = updatedUserData.userID;
        SetUsersList(currentUsersList => {
            const index = currentUsersList.findIndex(current => current.userID === userId);
            currentUsersList[index] = updatedUserData;
            return [...currentUsersList]
        });
        SetSelectedUser(null);
        modalClassRef.current.hide();
    }

    useEffect(() => {
        (async () => {
            try {
                const response = await getUserList(selectedRoleId === 'all' ? [
                    {
                        "roleID": 1
                    },
                    {
                        "roleID": 2
                    },
                    {
                        "roleID": 3
                    },
                    {
                        "roleID": 4
                    },
                    {
                        "roleID": 5
                    },
                    {
                        "roleID": 6
                    }
                ] : [
                    {
                        "roleID": selectedRoleId
                    }
                ], currentPage, resultsPerPage);

                SetUsersList(response);

                // const totalRowCount = response[0].totalRowCount || 0;

                SetTotalRowCount(response[0].totalRowCount || 1)

                SetLoading(false);
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'There was an error loading the user list'
                })
                SetLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRoleId, resultsPerPage, currentPage])

    useEffect(() => {
        if(!jwt) return redirect();

        const loadUserRoles = async () => {
            try {
                const response = await getRolesList();
                SetRoleList(response);
            } catch (err) {
                console.error(err);
                showToast({
                    type: 'danger',
                    text: 'Error while loading the roles list'
                });
            }
        };

        const loadDashboardList = async () => {
            try {
                const response = await getDashboardList();
                SetDashboardList(response);
            } catch (err) {
                console.error(err);
                showToast({
                    type: 'danger',
                    text: 'Error while loading the dashboard list'
                });
            }
        };

        const loadPermissionList = async () => {
            try {
                const response = await getPermissionList();

                SetPermissionList(response);

            } catch (err) {
                console.error(err);
                showToast({
                    type: 'danger',
                    text: 'Error while loading permissions list'
                });
            }
        };

        loadDashboardList();
        loadPermissionList();
        
        loadUserRoles();

        modalClassRef.current = new bootstrapBundleMin.Modal(modalRef.current, {
            keyboard: false
        });

        newUserModalClassRef.current = new bootstrapBundleMin.Modal(newUserModalRef.current, {
            keyboard: false
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="users-list">
            <div className="container">
                <div className='row g-3'>
                    {/* <div className="col-lg-3">
                        <label htmlFor="inputEmail4" className="form-label">Name</label>
                        <input type="text" className="form-control" id="inputEmail4" />
                    </div> */}
                    <div className="col-lg-3">
                        <label htmlFor="inputEmail4" className="form-label">Role</label>
                        <select value={selectedRoleId} onChange={e => SetSelectedRoleId(e.currentTarget.value)} className="form-select" aria-label="Default select example">
                            <option value='all'>ALL</option>
                            {
                                roleList.map(({roleID, roleName}, index) => (
                                    <option key={index} value={roleID}>{roleName}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-lg-3 f-align-end">                        
                        <button className="btn btn-warning" data-bs-target="#newUserModalToggle" data-bs-toggle="modal">New User</button>
                    </div>
                </div>
                
                <div className='row g-3'>
                    <div className="col-lg-12">
                        <Table loading={loading} tableStructure={TABLE_STRUCTURE} data={usersList} dataRowId={'userID'} onRowClick={handleUserClick} />
                    </div>
                </div>
            </div>

            <Pagination currentPage={currentPage} SetCurrentPage={SetCurrentPage} resultsPerPage={resultsPerPage} setResultsPerPage={SetResultsPerPage} RESULTS_PER_PAGE_OPTIONS={RESULTS_PER_PAGE_OPTIONS} totalRowCount={totalRowCount} />

            <UserModal modalRef={modalRef} selectedUser={selectedUser} modalClassRef={modalClassRef} handleUpdatedUser={handleUpdatedUser} roleList={roleList} dashboardList={dashboardList} permissionList={permissionList} />
            <NewUserModal newUserModalRef={newUserModalRef} setUsersList={SetUsersList} newUserModalClassRef={newUserModalClassRef} roleList={roleList} dashboardList={dashboardList} permissionList={permissionList} />
        </section>
    )
}

export default User;