const useNetworkInterceptor = () => {
    const handleResponse = async (response, type) => {
        const contentType = response.headers.get('Content-Type');

        if (!response.ok) {
            const _response = await response.json();
            throw new Error(_response.message);
        } else if(type === 'blob') {
            return await response.blob();
        } else if(contentType.includes('text/plain')) {
            return await response.text();
        } else {
            const _response = await response.json()
            // console.log(_response)
            return _response;
        };
    };
    
    const networkInterceptor = async (url, options, type) => {
        try {
            const response = await fetch(url, options);

            if(response.status === 405) {
                throw new Error(JSON.stringify({message: 'Method not allowed'}));
            } else if(response.status !== 200) {
                const errorResponse = await response.json();
                console.log(errorResponse)
                const errorObj = {
                    title: errorResponse.title,
                    errors: errorResponse.errors,
                    message: errorResponse.message,
                    status: response.status,
                };
                throw new Error(JSON.stringify(errorObj));
            };
            return handleResponse(response, type);
        } catch (err) {
            throw new Error(err.message);
        }
    };

    return networkInterceptor
}


export default useNetworkInterceptor;