import { useSearchParams } from "react-router-dom";

const SearchPage = ({props}) => {

    const [ searchParams ] = useSearchParams();

    const query = searchParams.get("query");

    return (
        <div className="search-page">
            <h1>This is the search page</h1>
            <h3>Your query was: {query}</h3>
        </div>
    )
};

export default SearchPage;
