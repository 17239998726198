import TextEditor from "components/textEditor/TextEditor";

const Notes = ({ adminNotes, setAdminNotes, clientNotes, setClientNotes, stage = 'editing'}) => {
    return (
        <>
            <div className='row g-3 mb-4'>
                <div className="col-lg-12">
                    <label className="form-label">Admin notes</label>
                    {
                        stage === 'editing' ? (
                            <TextEditor value={adminNotes} setValue={setAdminNotes} />
                        ) : <div dangerouslySetInnerHTML={{__html: adminNotes}}/>
                    }
                    
                </div>
            </div>
            <div className='row g-3 mb-4'>
                <div className="col-lg-12">
                    <label className="form-label">Client notes</label>
                    {
                        stage === 'editing' ? (
                            <TextEditor value={clientNotes} setValue={setClientNotes} />
                        ) : <div dangerouslySetInnerHTML={{__html: clientNotes}}/>
                    }
                    
                </div>
            </div>
        </>
    )
};

export default Notes;
