import React, { useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import { RedirectProvider } from "./context/globals-context";
import { useGlobals } from "./context/globals-context";

import Login from "./components/login/Login";
import DashboardAdmin from "components/dashboardAdmin/DashboardAdmin";
import DashboardCaseManager from "components/dashboardCaseManager/DashboardCaseManager";
import DashboardInvestigator from "components/dashboardInvestigator/DashboardInvestigator";
import DashboardQC from "components/dashboardQC/DashboardQC";
import DashboardSales from "components/dashboardSales/DashboardSales";
import DashboardClient from "components/dashboardClient/DashboardClient";
import Navbar from "components/navbar/Navbar";
import UsersList from "components/usersList/UsersList";
import ClientsList from "components/clientsList/ClientsList";
import RequesterList from "components/requesterList/RequesterList";
import Modal from "components/modal/Modal";
import NewCase from "components/newCase/NewCase";
import Case from "components/case/Case";
import NewUserPage from "components/newUserPage/NewUserPage";
import SearchPage from "components/searchPage/SearchPage";
import ResetPassword from "components/resetPassword/ResetPassword";
import TemplateList from "components/templateList/TemplateList";

import Toast from "components/toast/Toast";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => <RedirectProvider>
    <App></App>
</RedirectProvider>;

const App = () => {

    const { resetGlobalModal, globalModalRef, globalToastOptions, globalToastRef, theme } = useGlobals();

    useEffect(() => {
        const html = document.querySelector('html');
        if(theme === 'light') {
            html.dataset.bsTheme = 'light';
        } else {
            html.dataset.bsTheme = 'dark';
        }
    }, [theme]);

    return (
        <React.Fragment>
            <Modal onClose={resetGlobalModal} title="Authentication error" text="Please login again" close="Go to login" modalRef={globalModalRef} noX={true}/>
            <Navbar />
            <div className="main-content">
                <Routes>
                    <Route path="/" exact element={<Login />} />
                    <Route path="/dashboard-Admin" exact element={<DashboardAdmin />} />
                    <Route path="/dashboard-CaseManager" exact element={<DashboardCaseManager />} />
                    <Route path="/dashboard-Investigator" exact element={<DashboardInvestigator />} />
                    <Route path="/dashboard-QC" exact element={<DashboardQC />} />
                    <Route path="/dashboard-Sales" exact element={<DashboardSales />} />
                    <Route path="/dashboard-Client" exact element={<DashboardClient />} />
                    <Route path="/users-list" exact element={<UsersList />} />
                    <Route path="/clients-list" exact element={<ClientsList />} />
                    <Route path="/requester-list" exact element={<RequesterList />} />
                    <Route path="/new-case" exact element={<NewCase />} />
                    <Route path="/case" element={<Case />} />
                    <Route path="/new-user" element={<NewUserPage />} />
                    <Route path="/search-page" element={<SearchPage />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/template-list" element={<TemplateList />} />
                </Routes>
            </div>
            <Toast options={globalToastOptions} toastRef={globalToastRef} />
        </React.Fragment>
    )
}