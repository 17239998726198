import bootstrapBundleMin from 'bootstrap/dist/js/bootstrap.bundle.min';

import { useGlobals } from 'context/globals-context';
import { useState, useEffect, useRef } from 'react';
import ClientRequests from 'api/ClientRequests';

import Table from 'components/table/Table';
import NewClientModal from './components/NewClientModal';
import ViewAndEditClientModal from './components/ViewAndEditClientModal';

const ClientsList = () => {

    const TABLE_STRUCTURE = [
        {
            header: 'Client name',
            propertyName: 'clientName',
        },
        {
            header: 'Phone',
            propertyName: 'phone',
        },
        {
            header: 'Contact\'s first name',
            propertyName: 'contactFirstName',
        },
        {
            header: 'Contact\'s last name',
            propertyName: 'contactFirstName',
        },
    ];

    const { showToast } = useGlobals();
    const { getClientList } = ClientRequests();
    const [ clientsList, SetClientsList ] = useState([]);
    // const [ selectedRoleId, SetSelectedRoleId] = useState(1);
    const [ loading, SetLoading ] = useState(true);

    const [ inputsValues, SetInputsValues] = useState({active: true});
    const [ adminNotes, SetAdminNotes ] = useState('');
    const [ clientNotes, SetClientNotes ] = useState('');
    const [ stage, SetStage ] = useState('viewing');
    const [ addressesList, SetAddressesList ] = useState([
        {
            addressName: '',
            address1: '',
            address2: '',
            city: '',
            stateID: '',
            postalCode: '',
            phone: '',
            phoneExt: '',
            primary: true,
            active: true,
        }
    ]);
    
    const viewModal_ref = useRef();
    const editModal_ref = useRef();
    const viewModalClass_ref = useRef();
    const editModalClass_ref = useRef();
    const newClientModal_ref = useRef();
    const newClientModalClass_ref = useRef();

    const handleUserClick = (e) => {
        SetStage('viewing');
        const selectedUserId = e.currentTarget.dataset.rowId;
        const selectedUser = clientsList.find(
            currentUser => currentUser.clientID === selectedUserId
        );
        const _selectedUser = JSON.parse(JSON.stringify(selectedUser));
        console.log(_selectedUser)
        SetInputsValues(_selectedUser);
        SetAddressesList(_selectedUser.clientAddressList || [
            {
                addressName: '',
                address1: '',
                address2: '',
                city: '',
                stateID: '',
                postalCode: '',
                phone: '',
                phoneExt: '',
                primary: true,
                active: true,
            }
        ]);
        SetAdminNotes(selectedUser.clientAdminNotes || '');
        SetClientNotes(selectedUser.clientNotes || '')
        viewModalClass_ref.current.show();
    };

    const handleInputChange = (e) => {
        const key = e.currentTarget.dataset.identifier;
        const value = e.currentTarget.checked || e.currentTarget.value;
        const type = e.currentTarget.dataset.type;

        if(type === 'switch') {
            if(value === 'on') inputsValues[key] = false;
            else inputsValues[key] = true;
        } else if(key === 'stateID') {
            inputsValues[key] = value;
        } else inputsValues[key] = value;
        SetInputsValues({...inputsValues});
    }

    const clearValues = (e) => {
        e?.preventDefault();

        SetInputsValues({active: true});
        SetAddressesList([
            {
                addressName: '',
                address1: '',
                address2: '',
                city: '',
                stateID: '',
                postalCode: '',
                phone: '',
                phoneExt: '',
                primary: true,
                active: true
            }
        ]);
        SetAdminNotes('');
        SetClientNotes('');
        SetStage('viewing');
    }

    useEffect(() => {
        const loadClients = async () => {
            try {
                const response = await getClientList();
                console.log(response)
                SetClientsList(response);
                SetLoading(false);
            } catch (err) {
                showToast({
                    type: 'danger',
                    text: 'There was an error loading the user list'
                })
                SetLoading(false);
            }
        };

        loadClients();

        newClientModalClass_ref.current = new bootstrapBundleMin.Modal(newClientModal_ref.current, {
            keyboard: false
        });
        viewModalClass_ref.current = new bootstrapBundleMin.Modal(viewModal_ref.current, {
            keyboard: false
        });
        editModalClass_ref.current = new bootstrapBundleMin.Modal(editModal_ref.current, {
            keyboard: false
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className="requester-list">
            <div className="container">
                <div className='row g-3' style={{justifyContent: 'flex-end'}}>
                    <div className="col-lg-3" style={{justifyContent: 'flex-end', display: 'flex'}}>                        
                        <button className="btn btn-warning" data-bs-target="#newClientModalToggle" data-bs-toggle="modal" onClick={clearValues}>New client</button>
                    </div>
                </div>
                
                <div className='row g-3'>
                    <div className="col-lg-12">
                        <Table loading={loading} tableStructure={TABLE_STRUCTURE} data={clientsList} dataRowId={'clientID'} onRowClick={handleUserClick} />
                    </div>
                </div>
            </div>

            <NewClientModal
                newClientModal_ref={newClientModal_ref}
                newClientModalClass_ref={newClientModalClass_ref}
                setClientsList={SetClientsList}
                handleInputChange={handleInputChange}
                inputsValues={inputsValues}
                setInputsValues={SetInputsValues}
                adminNotes={adminNotes}
                setAdminNotes={SetAdminNotes}
                clientNotes={clientNotes}
                setClientNotes={SetClientNotes}
                addressesList={addressesList}
                setAddressesList={SetAddressesList}
            />
            <ViewAndEditClientModal 
                modalRef={viewModal_ref}
                secondModal_ref={editModal_ref}
                handleInputChange={handleInputChange}
                inputsValues={inputsValues}
                adminNotes={adminNotes}
                setAdminNotes={SetAdminNotes}
                clientNotes={clientNotes}
                setClientNotes={SetClientNotes}
                addressesList={addressesList}
                setAddressesList={SetAddressesList}
                stage={stage}
                setStage={SetStage}
                setClientsList={SetClientsList}
                editModalClass_ref={editModalClass_ref}
            />
        </section>
    )
}

export default ClientsList;